import { NotificationType } from '~/app/enums/NotificationId';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';

export const notifyNoVolumeProvided = (store, tj: TherapyJobExtended) => {
  const noVolume = tj.totalVolume === null;
  const prescribed = tj.status === TherapyJobStatus.PRESCRIBED;
  const running = tj.status === TherapyJobStatus.RUNNING;
  const notified = !!store.entityMap()?.[`${NotificationType.NO_VOLUME_PROVIDED}-${tj.id}`];

  return (prescribed || running) && noVolume && !notified;
};

export const unNotifyNoVolumeProvided = (store, tj: TherapyJobExtended) => {
  const withVolume = tj.totalVolume !== null;
  const notified = !!store.entityMap()?.[`${NotificationType.NO_VOLUME_PROVIDED}-${tj.id}`];

  return withVolume && notified;
};
