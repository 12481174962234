import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CwButton, CwIcon, CwTooltip } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { iconsMap } from '~/app/constants/notificationIconsMap';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { PersonNamePipe } from '~/app/pipes/PersonName';

@Component({
  selector: 'gplus-alarm-card',
  standalone: true,
  imports: [CwIcon, CommonModule, CwButton, TranslateModule, PersonNamePipe, DatePipe, CwTooltip],
  templateUrl: './alarm-card.component.html',
  styleUrl: './alarm-card.component.scss'
})
export class AlarmCardComponent {
  @Input() notification: TherapyJobNotification;
  @HostBinding('class') get hostClass(): string {
    return this.notification?.level;
  }

  @Output() hide: EventEmitter<void> = new EventEmitter<void>();

  public iconsMap: { [key: string]: string } = iconsMap;
}
