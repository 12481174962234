import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'progress'
})
export class ProgressPipe implements PipeTransform {
  transform(injected: number, total: number): number | null {
    if (injected === null || injected === undefined || isNaN(injected)) {
      return null;
    }

    return Math.round((injected / total) * 100);
  }
}
