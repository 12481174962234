import { CommonModule, NgComponentOutlet } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { CwSidebar } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { Sidebar, SidebarService } from '~/app/services/sidebar.service';

@Component({
  selector: 'gplus-sidebar-container',
  standalone: true,
  imports: [CwSidebar, TranslateModule, NgComponentOutlet, CommonModule, CustomShadowCssDirective],
  templateUrl: './sidebar-container.component.html',
  styleUrl: './sidebar-container.component.scss'
})
export class SidebarContainerComponent implements AfterViewInit {
  @Input() sidebar: Sidebar;

  constructor(private readonly sidebarService: SidebarService) {}

  ngAfterViewInit(): void {
    const Sidebar = document.querySelector('cw-sidebar');
    if (!Sidebar) {
      console.warn('cw-sidebar has not been found');
      return;
    }

    fromEvent(Sidebar, 'sidebarClose').subscribe(() => {
      this.sidebarService.close();
    });
  }
}
