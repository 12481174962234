import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map, take } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class SessionGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate() {
    return this.userService.getContext().pipe(
      take(1),
      map((context) => {
        return !!(context.unitId && context.siteId);
      })
    );
  }
}
