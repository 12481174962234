@if(submitLoader){

<div class="container-loader">
  <span class="loader absolute-centered"></span>
</div>
}

<form class="page" *ngIf="form && data" [formGroup]="form">
  <gplus-detail-page-body
    class="page-body"
    [pageStatus]="pageStatus"
    [data]="data"
    [form]="form"
    (submitChange)="submitChange()"
    (resetChange)="resetChange()"
  ></gplus-detail-page-body>
  <gplus-detail-page-sidebar
    class="page-sidebar"
    [pageStatus]="pageStatus"
    [data]="data"
    [form]="form"
  ></gplus-detail-page-sidebar>
</form>
