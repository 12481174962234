import { NotificationType } from '~/app/enums/NotificationId';
import { TherapyJobExtended } from '~/app/models/TherapyJob';

export const notifyDetachedDripChamber = (store, tj: TherapyJobExtended) => {
  const detached = tj.dripChamberDetached;
  const notified = !!store.entityMap()?.[`${NotificationType.DRIP_CHAMBER_DETACHED}-${tj.id}`];

  return detached && !notified;
};

export const unNotifyDetachedDripChamber = (store, tj: TherapyJobExtended) => {
  const detached = tj.dripChamberDetached;
  const notified = !!store.entityMap()?.[`${NotificationType.DRIP_CHAMBER_DETACHED}-${tj.id}`];

  return !detached && notified;
};
