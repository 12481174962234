import { Pipe, PipeTransform } from '@angular/core';
import { EntityMap } from '@ngrx/signals/entities';
import { TherapyJobNotification } from '../models/TherapyJobNotification';

@Pipe({
  standalone: true,
  name: 'filterNotification'
})
export class FilterNotificationPipe implements PipeTransform {
  transform(entityMap: EntityMap<TherapyJobNotification>, id: string) {
    if (!entityMap) {
      return {};
    }
    return Object.keys(entityMap)
      .filter((key) => key.endsWith(`-${id}`))
      .reduce((map, key) => {
        map[key] = entityMap[key];
        return map;
      }, {});
  }
}
