import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { TherapyJobActions } from '~/app/classes/TherapyJobActions';
import { TherapyJobFormClass } from '~/app/classes/TherapyJobFormClass';
import { DateFormat } from '~/app/enums/DateFormat';
import { Patient } from '~/app/models/generated/patient';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { LocalizedDatePipe } from '~/app/pipes/LocalizedDate';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { DialogService } from '~/app/services/dialog.service';
import { MonitorDeviceService } from '~/app/services/monitor-device.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { NotificationsStore } from '~/app/store/notification.store';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { TherapyJobMenuComponent } from '../../therapy-job-menu/therapy-job-menu.component';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';

const GENDER_ICONS = ['agender', 'female', 'male'];

@Component({
  selector: 'gplus-detail-page-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PersonNamePipe,
    LocalizedDatePipe,
    AutocompleteComponent,
    CwIcon,
    TherapyJobMenuComponent
  ],
  providers: [PersonNamePipe],
  templateUrl: './detail-page-header.component.html',
  styleUrl: './detail-page-header.component.scss'
})
export class DetailPageHeaderComponent implements OnInit {
  public patient: Patient;
  public genderIcon: string;
  public dateFormat = DateFormat;
  public therapyJobStatus = TherapyJobStatus;

  public jobsStore = inject(TherapyJobStore);
  public notificationStore = inject(NotificationsStore);
  private destroyRef = inject(DestroyRef);

  @Input() therapyJob: TherapyJobExtended;
  @Input() formInstance: TherapyJobFormClass;
  @Input() creationMode: boolean;
  @Input() data: SessionData;
  @Output() processing: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private readonly dialogService: DialogService,
    private readonly therapyJobService: TherapyJobService,
    private readonly router: Router,
    private readonly monitorDeviceService: MonitorDeviceService
  ) {}

  ngOnInit(): void {
    if (this.formInstance?.form) {
      this.formInstance.form
        .get('patient')
        .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((patient) => {
          this.patient = patient;
          this.genderIcon = patient?.sex !== undefined ? GENDER_ICONS[patient.sex] : null;
        });
    }
  }

  public async onDelete(therapyJobId: string) {
    this.processing.emit(true);
    await TherapyJobActions.delete(therapyJobId, {
      dialogService: this.dialogService,
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      therapyJobService: this.therapyJobService,
      router: this.router
    });
    this.processing.emit(false);
  }

  public async onUnpair(therapyJobId: string) {
    this.processing.emit(true);
    await TherapyJobActions.unpair(therapyJobId, {
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      monitorDeviceService: this.monitorDeviceService
    });
    this.processing.emit(false);
  }

  public async onAbort(therapyJobId: string) {
    this.processing.emit(true);
    await TherapyJobActions.abort(therapyJobId, {
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      therapyJobService: this.therapyJobService
    });
    this.processing.emit(false);
  }

  public async onFinalize() {
    this.processing.emit(true);
    await TherapyJobActions.finalize(this.therapyJob, {
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      therapyJobService: this.therapyJobService,
      dialogService: this.dialogService
    });

    this.processing.emit(false);
    this.router.navigate(['/home']);
  }
}
