import { CommonModule } from '@angular/common';
import { Component, computed, inject, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CwChip } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { TherapyJobActions } from '~/app/classes/TherapyJobActions';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { DialogService } from '~/app/services/dialog.service';
import { HighlightService } from '~/app/services/highlight.service';
import { MonitorDeviceService } from '~/app/services/monitor-device.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { NotificationsStore } from '~/app/store/notification.store';
import { EventManager } from '~/app/utils/EventManager';
import { getColumns } from '~/app/utils/getColumns';
import { Column } from '../../models/ui/Column';
import { TherapyJobService } from '../../services/therapy-job.service';
import { BoardColumnComponent } from '../board-column/board-column.component';

const ONE_MINUTE = 60 * 1000;

@Component({
  selector: 'gplus-board',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    TranslateModule,
    CwChip,
    // Components
    BoardColumnComponent,
    CustomShadowCssDirective
  ],
  templateUrl: './board.component.html',
  styleUrl: './board.component.scss'
})
export class BoardComponent {
  public jobsStore = inject(TherapyJobStore);
  public notificationStore = inject(NotificationsStore);

  public columns: Signal<Array<Column>>;
  public ticker: number;
  public highlightTerm: Signal<string>;
  public processing: boolean;

  // Only for responsive
  public currentColumn: string = TherapyJobStatus.PRESCRIBED;

  constructor(
    public readonly highlightService: HighlightService,
    private readonly therapyJobService: TherapyJobService,
    public readonly eventManager: EventManager,
    private readonly dialogService: DialogService,
    private readonly monitorDeviceService: MonitorDeviceService
  ) {
    interval(ONE_MINUTE)
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        this.ticker = res;
        this.checkForConfirmationUpdate(this.jobsStore.entities());
      });

    this.columns = computed(() => getColumns(this.jobsStore.filtered(), this.notificationStore));
    this.highlightTerm = computed(() => this.jobsStore.filter().queries.find((q) => q.on === 'search')?.bindValue);
  }

  public selectChip(columnId: string) {
    this.currentColumn = columnId;
  }

  public onTherapyJobChange(change: { value: Partial<TherapyJobExtended>; id: string }) {
    this.jobsStore.patchJob(change.value, change.id);

    this.therapyJobService.update(change.value, change.id).subscribe((res) => {
      if ((res as { error: string })?.error) {
        // something went wrong we reload
        this.jobsStore.loadAll().then(() => this.notificationStore.setAll());
      } else {
        console.info('Therapy Job successfully edited');
      }
    });
  }

  public async onDelete(therapyJobId: string) {
    this.processing = true;
    await TherapyJobActions.delete(therapyJobId, {
      dialogService: this.dialogService,
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      therapyJobService: this.therapyJobService
    });

    this.processing = false;
  }

  public async onUnpair(therapyJobId: string) {
    await TherapyJobActions.unpair(therapyJobId, {
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      monitorDeviceService: this.monitorDeviceService
    });
  }

  public async onFinalize(therapyJob: TherapyJobExtended) {
    this.processing = true;
    await TherapyJobActions.finalize(therapyJob, {
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      therapyJobService: this.therapyJobService,
      dialogService: this.dialogService
    });

    this.processing = false;
  }

  public async onAbort(therapyJobId: string) {
    this.processing = true;
    await TherapyJobActions.abort(therapyJobId, {
      jobsStore: this.jobsStore,
      notificationStore: this.notificationStore,
      therapyJobService: this.therapyJobService
    });

    this.processing = false;
  }

  private checkForConfirmationUpdate(therapyJobs: Array<TherapyJobExtended>) {
    const completed = therapyJobs.filter((tj) => tj.status === TherapyJobStatus.COMPLETED);
    if (!completed.length) {
      return;
    }

    completed.forEach((tj) => {
      this.notificationStore.modifyPendingConfirmation(tj);
    });
  }
}
