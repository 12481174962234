import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of } from 'rxjs';
import { CarrierSolution } from '../models/generated/carrierSolution';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  private carriers: Array<CarrierSolution>;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly translate: TranslateService
  ) {}

  public getCarriers(wardId: string): Observable<Array<CarrierSolution>> {
    let params = new HttpParams().set('ward', wardId);

    return this.httpClient
      .get<Array<CarrierSolution>>(`${this.env.getBaseRestUrl()}/carriersolution`, { params: params })
      .pipe(
        catchError((err) => {
          console.warn('Error requesting carriers', err);
          return of([]);
        }),
        map((carriers) => {
          this.carriers = [
            {
              description: this.translate.instant('FormTJCreationReadyToAdminister')
            },
            ...carriers
          ];

          return this.carriers;
        })
      );
  }
  public getCarrier(id: string, wardId: string, description?: string): Observable<CarrierSolution> {
    if (!this.carriers) {
      return this.getCarriers(wardId).pipe(
        map((carrier) => carrier.find((e) => e.id === id || e.description === description))
      );
    }

    return of(this.carriers?.find((e) => e.id === id || e.description === description));
  }
}
