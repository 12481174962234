import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { CarrierSolution } from '../models/generated/carrierSolution';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  private carriersObs: Observable<Array<CarrierSolution>>;

  private carriers: Array<CarrierSolution>;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly translate: TranslateService
  ) {}

  public getCarriers(): Observable<Array<CarrierSolution>> {
    if (!this.carriersObs) {
      this.carriersObs = this.httpClient
        .get<Array<CarrierSolution>>(`${this.env.getBaseRestUrl()}/carriersolution`)
        .pipe(
          catchError((err) => {
            console.warn('Error requesting carriers', err);
            return of([]);
          }),
          map((carriers) => {
            this.carriers = [
              {
                description: this.translate.instant('FormTJCreationReadyToAdminister')
              },
              ...carriers.sort((a, b) => a.description.localeCompare(b.description))
            ];

            return this.carriers;
          }),
          shareReplay()
        );
    }

    return this.carriersObs;
  }
}
