<h3 class="page-sidebar-title full-group">
  {{ 'FormTJCreationSidebarTitle' | translate }}
</h3>

@if(therapyJob?.status === therapyJobStatus.PRESCRIBED){
<section *ngIf="configService.configuration()?.content?.general.allowPreparationMarking">
  <gplus-toggle
    data-test="prepared-toggle"
    class="prepared-toggle"
    [control]="formInstance?.form.controls?.['prepared']"
    [label]="'DetailPagePrepared' | translate"
  ></gplus-toggle>
</section>
}

<section>
  <div class="detail-section-title">
    {{ 'DetailPageDeviceSectionTitle' | translate }}
  </div>

  @if (therapyJob && (therapyJob?.status === therapyJobStatus.RUNNING || therapyJob?.status ===
  therapyJobStatus.PRESCRIBED) && therapyJob.pairingState !== pairingStatus.PAIRED) {
  <gplus-manage-device
    class="connect-selector"
    [event]="event"
    [therapyJob]="therapyJob"
    (click)="$event.stopPropagation()"
  ></gplus-manage-device>
  }

  <div class="gplus-list">
    <div data-test="device-id" class="item" *ngIf="therapyJob?.pairingState === pairingStatus.PAIRED">
      <div class="label">{{ 'DetailPageDeviceSectionDeviceId' | translate }}</div>
      <div class="value">{{ therapyJob?.monitorDeviceId }}</div>
    </div>
    <div data-test="device-status" class="item">
      <div class="label">{{ 'DetailPageDeviceSectionStatus' | translate }}</div>
      <div class="value">
        @switch (therapyJob?.pairingState ) {
        <!--  -->
        @case (pairingStatus.UN_PAIRED) {
        {{ 'PairingStatusUN_PAIRED' | translate }}
        }
        <!--  -->
        @case (pairingStatus.IN_PAIRING) {
        {{ 'PairingStatusIN_PAIRING' | translate }}
        }
        <!--  -->
        @case (pairingStatus.PAIRED) {
        {{ 'PairingStatusPAIRED' | translate }}
        }
        <!--  -->
        @default {

        <!-- Default and on null as well-->
        {{ 'PairingStatusUN_PAIRED' | translate }}
        } }
      </div>
    </div>
  </div>
</section>

<section>
  <div class="detail-section-title">
    {{ 'DetailPageDetailSectionTitle' | translate }}
  </div>
  <div class="gplus-list">
    <div data-test="planned-time" class="item">
      <div class="label">{{ 'DetailPagePlannedTimeLabel' | translate }}</div>
      @if(therapyJob?.startTime?.planned){
      <div class="value time">
        {{
          therapyJob?.startTime?.planned
            | localizedDate : ((therapyJob?.startTime?.planned | isToday) ? dateFormat.SHORT_TIME : dateFormat.MEDIUM)
        }}
        <span *ngIf="timeError" class="error-value" [attr.data-vef]="'detailPage-delayedTime-label'">
          <cw-icon icon="warning" [filled]="false" size="sm"></cw-icon>
          <span data-test="delayed-time">
            {{ timeError }}
          </span>
        </span>
      </div>
      } @else{
      <div class="value">-</div>
      }
    </div>

    <div data-test="start-time" class="item">
      <div class="label">{{ 'DetailPageStartedTimeLabel' | translate }}</div>
      @if(therapyJob?.startTime?.real){
      <div class="value time">
        {{
          therapyJob?.startTime?.real
            | localizedDate : ((therapyJob?.startTime?.real | isToday) ? dateFormat.SHORT_TIME : dateFormat.MEDIUM)
        }}
      </div>
      } @else{
      <div class="value">-</div>
      }
    </div>

    <div data-test="therapy-origin" class="item">
      <div class="label">{{ 'DetailPageTherapyOriginLabel' | translate }}</div>
      @if(therapyJob?.createdBy){
      <div class="value">{{ 'DetailPageOriginCustom' | translate }}</div>
      } @else{
      <div class="value">{{ 'DetailPageOriginPDMS' | translate }}</div>
      }
    </div>

    <div data-test="therapy-status" class="item">
      <div class="label">{{ 'DetailPageTherapyStatusLabel' | translate }}</div>
      @if( therapyJob?.status){

      <div class="value">
        @if(therapyJob?.status === therapyJobStatus.ABORTED){
        <cw-badge-status status="error" type="secondary">
          {{ 'Columns' + therapyJob?.status | translate }}
        </cw-badge-status>

        } @else if (therapyJob?.status === therapyJobStatus.COMPLETE_CONFIRMED ) {

        <cw-badge-status status="success" type="secondary">
          {{ 'Columns' + therapyJob?.status | translate }}
        </cw-badge-status>

        } @else {
        {{ 'Columns' + therapyJob?.status | translate }}
        }
      </div>
      } @else{
      <div class="value">-</div>
      }
    </div>
  </div>
</section>
