import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CxButton, CxIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gplus-annotations',
  standalone: true,
  imports: [CxIcon, CxButton, FormsModule, MatFormFieldModule, TranslateModule, CommonModule],
  templateUrl: './annotations.component.html',
  styleUrl: './annotations.component.scss'
})
export class AnnotationsComponent implements OnInit {
  public model: string;
  public showIcon: boolean;

  @Input() value: string;

  @HostBinding('class.open')
  public _showAnnotations: boolean;
  @Input() set showAnnotations(value: boolean) {
    this._showAnnotations = value;
    setTimeout(() => {
      if (this._showAnnotations && this.textArea) {
        this.textArea.nativeElement.focus();
      }
    }, 100);
  }

  @Output() onSubmit: EventEmitter<string> = new EventEmitter();
  @Output() toggle: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('textArea') textArea: ElementRef;

  public isDirty: boolean;

  ngOnInit(): void {
    this.model = this.value;
    this.showIcon = !!this.value;
  }

  public save(event: MouseEvent) {
    event.stopPropagation();

    this.onSubmit.emit(this.model);

    this.isDirty = false;
    this._showAnnotations = false;
    this.showIcon = !!this.model;
    this.toggle.emit(false);
  }

  public change() {
    this.isDirty = this.model !== this.value;
  }

  public cancel(event: MouseEvent) {
    event.stopPropagation();

    this.model = this.value;
    this.isDirty = false;
    this._showAnnotations = false;
    this.toggle.emit(false);
  }

  public toggleAnnotations(event: MouseEvent) {
    event.stopPropagation();

    this._showAnnotations = !this._showAnnotations;
    this.toggle.emit(this._showAnnotations);

    setTimeout(() => {
      if (this._showAnnotations && this.textArea) {
        this.textArea.nativeElement.focus();
      }
    }, 100);
  }
}
