<ng-container *ngFor="let obj of data | keyvalue | sortBy : 'order'">
  <h3 *ngIf="level === 0" [ngClass]="obj.key === onlyShowWithKey ? 'show' : 'hide'">
    {{ obj.key | translate }}
  </h3>
  <ng-container *ngTemplateOutlet="control; context: { control: obj.value, key: obj.key }"></ng-container>

  <ng-container *ngIf="!obj.value.id">
    <gplus-configuration-controls
      [controlsCustomization]="controlsCustomization"
      [ngClass]="obj.key === onlyShowWithKey ? 'show' : 'hide'"
      [form]="form"
      [data]="obj.value"
      [level]="level + 1"
    ></gplus-configuration-controls>
  </ng-container>
</ng-container>

<ng-template #control let-control="control" let-key="key">
  <div class="control">
    @switch (control.type) { @case (configUiControl.TOGGLE){

    <gplus-toggle
      [disabled]="
        controlsCustomization[control.path]?.disabled ||
        (controlsCustomization[control.path]?.disableWith | getFormControl : form)?.value === false
      "
      [control]="control.path | getFormControl : form"
      [label]="key | translate"
    ></gplus-toggle>

    } @case (configUiControl.NUMBER){

    <gplus-input
      class="input"
      type="number"
      [disabled]="
        controlsCustomization[control.path]?.disabled ||
        (controlsCustomization[control.path]?.disableWith | getFormControl : form)?.value === false
      "
      [control]="control.path | getFormControl : form"
      [label]="key | translate"
      [validations]="{
        min: controlsCustomization?.[control.path]?.validationMsgs?.min | translate,
        max: controlsCustomization?.[control.path]?.validationMsgs?.max | translate,
        required: 'ConfigPageInputRequired' | translate
      }"
    ></gplus-input>

    } @case (configUiControl.SELECT) {

    <gplus-dropdown
      [disabled]="
        controlsCustomization[control.path]?.disabled ||
        (controlsCustomization[control.path]?.disableWith | getFormControl : form)?.value === false
      "
      class="selector"
      [control]="control.path | getFormControl : form"
      [label]="key | translate"
      [data]="control.options"
      [validations]="{
        required: 'ConfigPageInputRequired' | translate
      }"
    ></gplus-dropdown>

    } @case (configUiControl.MULTI_SELECT) {

    <gplus-dropdown
      [disabled]="
        controlsCustomization[control.path]?.disabled ||
        (controlsCustomization[control.path]?.disableWith | getFormControl : form)?.value === false
      "
      class="selector"
      [multiple]="true"
      [control]="control.path | getFormControl : form"
      [label]="key | translate"
      [data]="control.options"
      [validations]="{
        arrayLength: 'ConfigPageCardNotAllowedMoreThan4' | translate,
        required: 'ConfigPageInputRequired' | translate
      }"
    ></gplus-dropdown>

    } @case (configUiControl.DRAG_DROP_LIST) {

    <h5 class="mb-3">{{ key | translate }}</h5>
    <gplus-drag-drop-list
      [headers]="controlsCustomization[control.path]?.dragDropHeaders"
      [control]="control.path | getFormControl : form"
      [items]="control.options"
    ></gplus-drag-drop-list>

    } }
  </div>
</ng-template>
