import { EntityMap } from '@ngrx/signals/entities';
import { NotificationLevel } from '../enums/NotificationLevel';
import { TherapyJobNotification } from '../models/TherapyJobNotification';

// If one of the notification is an error we keep all teh notification marked as error
export const getCriticalType = (tjNotificationMap: EntityMap<TherapyJobNotification>) => {
  return Object.keys(tjNotificationMap).reduce<NotificationLevel>((acc, key) => {
    const notification = tjNotificationMap[key];
    if (acc === NotificationLevel.ERROR) {
      return acc;
    }

    return notification.level;
  }, null);
};
