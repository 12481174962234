import { Injectable, WritableSignal, signal } from '@angular/core';

export interface TJHighLightMap {
  [therapyJobId: string]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HighlightService {
  private readonly highlightMapSignal: WritableSignal<TJHighLightMap> = signal({});
  public highlightMap = this.highlightMapSignal.asReadonly();

  public highlightTJ(id: string) {
    this.highlightMapSignal.update((value) => {
      const update = {
        ...value,
        [id]: true
      };

      return JSON.parse(JSON.stringify(update));
    });
  }

  public cleanHighlightTJ(id: string) {
    this.highlightMapSignal.update((value) => {
      delete value[id];

      return JSON.parse(JSON.stringify(value));
    });
  }
}
