import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Observable, ReplaySubject, Subject, filter, of } from 'rxjs';
import { environment } from '~/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLogged: Subject<boolean> = new ReplaySubject(1);
  private destroyRef = inject(DestroyRef);

  constructor(private msalBroadcastService: MsalBroadcastService, private authService: MsalService) {}

  public configureAuth(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        this.setLoginDisplay();
      });
  }

  public getApiAuthToken(): Observable<AuthenticationResult | null> {
    const account = this.authService.instance.getAllAccounts()[0];

    if (!account) {
      console.warn('No account found');
      return of(null);
    }

    return this.authService.acquireTokenSilent({
      scopes: environment.gravityApiConfig.scopes,
      account: account
    });
  }

  public logout(): void {
    this.authService.logout({
      idTokenHint: this.authService.instance.getAllAccounts()[0].idToken
    });
  }

  private setLoginDisplay(): void {
    const isLogged = this.authService.instance.getAllAccounts().length > 0;
    this.isLogged.next(isLogged);
  }
}
