import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CwDialog } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { Dialog } from '~/app/services/dialog.service';

@Component({
  selector: 'gplus-modal-container',
  standalone: true,
  imports: [CwDialog, NgComponentOutlet, TranslateModule, CommonModule],
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss'
})
export class ModalContainerComponent {
  @Input() dialog: Dialog;
}
