@if(form){

<div class="full-group page-body-header">
  <gplus-detail-page-header [therapyJob]="therapyJob" [form]="form"></gplus-detail-page-header>

  <div class="actions">
    @if(!form.valid || form.pristine){
    <cx-button
      [attr.data-vef]="'form-create-manual-cancel'"
      button-color="secondary"
      variant="outlined"
      [disabled]="true"
    >
      {{ 'FormTJCreationResetChanges' | translate }}
    </cx-button>

    <cx-tooltip
      [content]="
        (!form.valid ? 'FormTJCreationMissingRequiredFields' : form.pristine ? 'FormTJCreationNothingToSave' : '')
          | translate
      "
      placement="bottom"
    >
      <cx-button
        [attr.data-vef]="'form-create-manual-submit'"
        button-color="accent"
        variant="filled"
        [disabled]="true"
        type="submit"
      >
        {{ 'FormTJSaveChanges' | translate }}
      </cx-button>
    </cx-tooltip>

    } @else{

    <cx-button
      [attr.data-vef]="'form-create-manual-cancel'"
      button-color="secondary"
      variant="outlined"
      (click)="resetChange.emit()"
    >
      {{ 'FormTJCreationResetChanges' | translate }}
    </cx-button>

    <cx-button
      [attr.data-vef]="'form-create-manual-submit'"
      button-color="accent"
      variant="filled"
      (click)="submitChange.emit()"
      [disabled]="submitLoader"
      type="submit"
    >
      {{ 'FormTJSaveChanges' | translate }}
      <span *ngIf="submitLoader" class="loader inline white"></span>
    </cx-button>

    }
  </div>
</div>

@if(tjNotificationMap){
<div class="full-group" *ngFor="let item of tjNotificationMap | keyvalue">
  <cx-banner
    customShadowCss
    styles=".banner-title{font-size: 1rem!important}"
    [type]="item.value.level"
    [label]="item.value.translationKey | translate"
    [description]="item.value.hintKey | translate"
    [enableClose]="false"
  ></cx-banner>
</div>
}

<section
  class="full-group page-body-section"
  *ngIf="pageStatus === detailPageStatus.CREATION_MANUAL"
  [formGroup]="form"
>
  <mat-form-field
    [attr.data-vef]="'form-create-manual-patient'"
    class="first-group"
    [class.cx-readonly]="form.controls?.['patient']?.status === 'DISABLED'"
  >
    <mat-label>{{ 'FormTJCreationPatient' | translate }}</mat-label>

    <input
      type="text"
      matInput
      #basicAuto
      formControlName="patient"
      [matAutocomplete]="autoPatient"
      required
      [placeholder]="'FormTJCreationPatientPlaceholder' | translate"
    />

    <mat-autocomplete #autoPatient="matAutocomplete" [displayWith]="displayPersonName">
      @for(patient of filteredPatient | async; track patient.id){
      <mat-option [value]="patient">{{ patient | personName }}</mat-option>

      }
    </mat-autocomplete>
    <mat-error>
      @if(form.controls?.['patient']?.errors?.['patientNotExist']){
      {{ 'FormTJCreationPleaseSelectOneOfTheList' | translate }}
      } @if(form.controls?.['patient']?.errors?.['required']){
      {{ 'FormTJCreationPatientIsRequired' | translate }}

      }
    </mat-error>
  </mat-form-field>
</section>

<section class="full-group page-body-section" [formGroup]="form">
  <h5 class="full-group page-body-section-title">
    {{ 'FormTJCreationDrugSection' | translate }}
  </h5>

  <ng-container [formGroup]="form.controls['medication']">
    <ng-container formArrayName="medicines">
      @for (medicineObj of medicines?.controls; track medicineObj; let i = $index; let last = $last; let first = $first)
      { @if(filteredAllMedicines){

      <ng-container [formGroup]="medicineObj">
        <mat-form-field
          [attr.data-vef]="'form-create-manual-medicine'"
          class="first-group"
          [class.cx-readonly]="medicineObj.controls['medicine']?.status === 'DISABLED'"
        >
          <mat-label>{{ 'FormTJCreationMedicine' | translate }}</mat-label>
          <input
            formControlName="medicine"
            type="text"
            matInput
            #basicAuto
            [matAutocomplete]="autoMedicine"
            required
            [placeholder]="'FormTJCreationMedicinePlaceholder' | translate"
          />

          <mat-autocomplete #autoMedicine="matAutocomplete" [displayWith]="medicineName">
            @for(med of filteredAllMedicines[i] | async; track med.id){
            <mat-option [value]="med">{{ med.display }}</mat-option>

            }
          </mat-autocomplete>

          <mat-error>
            @if(medicineObj?.errors?.['unauthorizedMedicine']){
            {{ 'FormTJCreationPleaseSelectOneOfTheList' | translate }}
            } @if(medicineObj?.controls?.['medicine'].errors?.['required']){
            {{ 'FormTJCreationMedicineIsRequired' | translate }}
            } @if(medicineObj?.controls?.['medicine'].errors?.['unauthorizedMedicine']){
            {{ 'FormTJCreationUnauthorizedMedicine' | translate }}
            }
          </mat-error>
        </mat-form-field>

        <mat-form-field
          [attr.data-vef]="'form-create-manual-dose'"
          class="second-group"
          [class.cx-readonly]="medicineObj.controls['customAmount']?.status === 'DISABLED'"
        >
          <mat-label>{{ 'FormTJCreationDose' | translate }}</mat-label>

          <input
            formControlName="customAmount"
            min="0"
            matInput
            required
            type="number"
            [placeholder]="'FormTJCreationDosePlaceholder' | translate"
          />

          <mat-error>
            @if(medicineObj?.controls['customAmount'].errors?.['max']){
            {{ 'FormTJCreationMaxDosageLimit' | translate: {amount: medicineObj?.controls?.['medicine']?.value?.amount} }}
            } @if(medicineObj?.controls['customAmount'].errors?.['min']){
            {{ 'FormTJCreationMinLimit' | translate }}
            } @if(medicineObj?.controls['customAmount'].errors?.['notNumber']){
            {{ 'FormTJCreationNotNumber' | translate }}
            }@if(medicineObj?.controls['customAmount'].errors?.['required']){
            {{ 'FormTJCreationDoseIsRequired' | translate }}
            }
          </mat-error>
        </mat-form-field>

        <mat-form-field
          [attr.data-vef]="'form-create-manual-unit'"
          class="third-group"
          [class.cx-readonly]="medicineObj.controls['unit']?.status === 'DISABLED'"
        >
          <mat-label>{{ 'FormTJCreationUnit' | translate }}</mat-label>

          <mat-select formControlName="unit" [placeholder]="'FormTJCreationUnitPlaceholder' | translate">
            @for(option of units; track option){
            <mat-option [value]="option">{{ option }}</mat-option>

            }
          </mat-select>

          <mat-error>
            @if(medicineObj?.controls['unit'].errors?.['required']){
            {{ 'FormTJCreationUnitIsRequired' | translate }}
            }
          </mat-error>
        </mat-form-field>
      </ng-container>

      <div *ngIf="form.controls['medication']?.status !== 'DISABLED'" class="fourth-group v-center">
        @if(first){
        <cx-button variant="outlined" button-color="secondary" (click)="addMedicine()">
          <cx-icon icon="add"></cx-icon>
        </cx-button>

        } @else {

        <cx-button variant="outlined" button-color="secondary" (click)="removeMedicine(i)">
          <cx-icon icon="delete"></cx-icon>
        </cx-button>
        }
      </div>

      } }
    </ng-container>

    <cx-divider class="full-group" axis="horizontal" type="default"></cx-divider>

    <mat-form-field
      [attr.data-vef]="'form-create-manual-carrier'"
      class="first-group"
      [class.cx-readonly]="form.controls['medication'].controls['carrier']?.status === 'DISABLED'"
    >
      <mat-label>{{ 'FormTJCreationCarrier' | translate }}</mat-label>

      <input
        formControlName="carrier"
        type="text"
        matInput
        #basicAuto
        [matAutocomplete]="autoCarrier"
        required
        [placeholder]="'FormTJCreationCarrierPlaceholder' | translate"
      />

      <mat-autocomplete #autoCarrier="matAutocomplete" [displayWith]="carrierName">
        @for(carrier of filteredCarrier | async; track carrier.id){
        <mat-option [value]="carrier">{{ carrier.description }}</mat-option>

        }
      </mat-autocomplete>

      <mat-error>
        @if(form.controls['medication'].controls?.['carrier']?.errors?.['required']){
        {{ 'FormTJCreationCarrierIsRequired' | translate }}
        }
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container>
    <mat-form-field
      [attr.data-vef]="'form-create-manual-flowrate'"
      class="second-group"
      [class.cx-readonly]="form.controls['flowRate']?.status === 'DISABLED'"
    >
      <mat-label>{{ 'FormTJCreationFlowRate' | translate }}</mat-label>

      <input
        formControlName="flowRate"
        min="0"
        matInput
        type="number"
        [placeholder]="'FormTJCreationFlowRatePlaceholder' | translate"
      />

      <mat-error>
        @if(form?.controls['flowRate'].errors?.['min']){
        {{ 'FormTJCreationMinLimit' | translate }}
        } @if(form?.controls['flowRate'].errors?.['notNumber']){
        {{ 'FormTJCreationNotNumber' | translate }}
        }
      </mat-error>
    </mat-form-field>
    <mat-form-field
      [attr.data-vef]="'form-create-manual-volume'"
      class="third-group"
      [class.cx-readonly]="form.controls['volume']?.status === 'DISABLED'"
    >
      <mat-label>{{ 'FormTJCreationVolume' | translate }}</mat-label>

      <input
        formControlName="volume"
        min="0"
        matInput
        type="number"
        [placeholder]="'FormTJCreationVolumePlaceholder' | translate"
      />

      <mat-error>
        @if(form?.controls['volume'].errors?.['min']){
        {{ 'FormTJCreationMinLimit' | translate }}
        } @if(form?.controls['volume'].errors?.['notNumber']){
        {{ 'FormTJCreationNotNumber' | translate }}
        }
      </mat-error>
    </mat-form-field>
  </ng-container>
</section>

<section class="full-group page-body-section" [formGroup]="form">
  <h5 class="full-group page-body-section-title h5">{{ 'FormTJCreationComment' | translate }}</h5>
  <mat-form-field [attr.data-vef]="'form-create-manual-annotations'" class="first-group annotations no-label">
    <textarea
      cdkTextareaAutosize
      cdkAutosizeMinRows="6"
      matInput
      #textarea
      formControlName="annotations"
      [placeholder]="'FormTJCreationNotesPlaceholder' | translate"
    ></textarea>
    <mat-error>
      @if(form?.controls['annotations'].errors?.['notString']){
      {{ 'FormTJCreationNotString' | translate }}
      }
    </mat-error>
  </mat-form-field>
</section>
}
