import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, map, of } from 'rxjs';
import { PatientService } from '../services/patient.service';

export class PatientValidator {
  static createValidator(patientService: PatientService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control?.value) {
        return of(null);
      }

      return patientService
        .patientExists(control.value)
        .pipe(map((result: boolean) => (result ? null : { patientNotExist: true })));
    };
  }
}
