import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, take, tap } from 'rxjs';
import { User } from '../models/User';
import { UserService } from '../services/user.service';

export const TEMP_SITE_ID = '8395aadc-a4b2-471e-bc52-58792c247bb9';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private me: User;
  constructor(private readonly userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.endsWith('me')) {
      return next.handle(req);
    }

    if (this.me) {
      return next.handle(this.getAuthReq(this.me, req));
    }

    return this.userService.getMe().pipe(
      take(1),
      tap((me) => (this.me = me)),
      switchMap((me: User) => next.handle(this.getAuthReq(me, req)))
    );
  }

  private getAuthReq(me: User, req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      // For now we return unitId and SiteID with the userID
      headers: req.headers.set('BB-Site-Id', TEMP_SITE_ID).set('BB-Unit-Id', me.id)
    });
  }
}
