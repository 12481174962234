import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigUiControl, ConfigurationControl } from '~/app/models/Configuration';
import { GetFormControlPipe } from '~/app/pipes/GetFormControl';
import { SortByPipe } from '~/app/pipes/SortBy';
import { DragDropListComponent } from '../../drag-drop-list/drag-drop-list.component';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { InputComponent } from '../../input/input.component';
import { ToggleComponent } from '../../toggle/toggle.component';
import { ControlsCustomization } from '../configurationCustomization';

@Component({
  selector: 'gplus-configuration-controls',
  standalone: true,
  imports: [
    CommonModule,
    ToggleComponent,
    TranslateModule,
    InputComponent,
    DropdownComponent,
    DragDropListComponent,
    GetFormControlPipe,
    SortByPipe
  ],
  providers: [GetFormControlPipe],
  templateUrl: './configuration-controls.component.html',
  styleUrl: './configuration-controls.component.scss'
})
export class ConfigurationControlsComponent {
  @Input() form: FormGroup;
  @Input() data: ConfigurationControl;
  @Input() level: number = 0;
  @Input() onlyShowWithKey: string;
  @Input() controlsCustomization: ControlsCustomization;

  public configUiControl = ConfigUiControl;
}
