import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '~/environments/environment';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private me: Observable<User>;

  constructor(private http: HttpClient) {}
  public getMe(): Observable<User> {
    if (!this.me) {
      this.me = this.http.get<User>(`${environment.gprApiConfig.uri}me`).pipe(shareReplay());
    }

    return this.me;
  }
}
