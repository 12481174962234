import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { CwButton, CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, filter, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { ActiveDevice } from '~/app/models/ActiveDevice';
import { MonitorDevice } from '~/app/models/MonitorDevice';
import { ResponseError, ResponseErrorExtended } from '~/app/models/ResponseError';
import { SseEventMessageKey, SsePairing } from '~/app/models/sse-event';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { MonitorDeviceService } from '~/app/services/monitor-device.service';
import { DropdownComponent } from '../dropdown/dropdown.component';

const AUTO_PAIR_ID = 'autoPair';
@Component({
  selector: 'gplus-manage-device',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, CwButton, CwIcon, DropdownComponent],
  templateUrl: './manage-device.component.html',
  styleUrl: './manage-device.component.scss',
  providers: [PersonNamePipe]
})
export class ManageDeviceComponent implements OnChanges, AfterViewInit {
  @Input() therapyJob: TherapyJobExtended;
  @Input()
  set event(value: SsePairing) {
    this.eventSub.next(value);
  }

  private eventSub: Subject<SsePairing> = new Subject();
  private unsubscribeEventSub: Subject<void> = new Subject();

  // Assistive text
  public message: {
    type: 'error' | 'warning' | 'info' | 'loading' | 'success';
    text: string;
  };

  public defaultOption: ActiveDevice;

  public data: Array<ActiveDevice>;
  public loadingActiveDevices: boolean;

  public showCancel: boolean;
  public processingCancel: boolean;
  public disableSelector: boolean;
  public deviceId: string;

  // public enum
  public pairingStatus = PairingStatus;
  public therapyJobStatus = TherapyJobStatus;

  private destroyRef = inject(DestroyRef);

  public form: FormGroup = new FormGroup({
    monitorDevice: new FormControl(null)
  });

  constructor(
    private readonly monitorDeviceService: MonitorDeviceService,
    private readonly translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.therapyJob) {
      this.setSelector();
    }
  }

  ngAfterViewInit(): void {
    this.defaultOption = {
      id: AUTO_PAIR_ID,
      label: this.translateService.instant('AutoPairMonitorDevice'),
      attrVef: 'device-selector-dropdown-autoPair-option'
    };

    this.form
      .get('monitorDevice')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: ActiveDevice) => {
        this.connectMonitorDevice(value);
      });
  }

  private setSelector() {
    switch (this.therapyJob?.pairingState) {
      case PairingStatus.UN_PAIRED:
        this.message = null;
        if (this.therapyJob.monitorDeviceId) {
          this.message = {
            type: 'info',
            text: this.translateService.instant('PairingRejectedFromMonitorDevice', {
              deviceId: this.therapyJob.monitorDeviceId
            })
          };

          // after some time we hide the message
          setTimeout(() => {
            this.message = null;
          }, 3000);
        }

        this.form.reset();

        this.showCancel = false;
        this.disableSelector = false;
        this.deviceId = null;

        break;
      case PairingStatus.IN_PAIRING:
        this.message = {
          type: 'info',
          text: this.translateService.instant('WaitingForPairingConfirmation', {
            deviceId: this.therapyJob.monitorDeviceId
          })
        };

        this.showCancel = true;
        this.disableSelector = true;
        this.deviceId = this.therapyJob.monitorDeviceId;

        break;
      case PairingStatus.PAIRED:
        this.message = {
          type: 'success',
          text: this.translateService.instant('PairingCompleted', { deviceId: this.therapyJob.monitorDeviceId })
        };

        setTimeout(() => {
          this.form.reset();
          this.showCancel = null;
          this.disableSelector = true;
          this.deviceId = this.therapyJob.monitorDeviceId;
        }, 1000);

        break;
      case null:
        this.message =
          this.therapyJob?.messageKey === SseEventMessageKey.PAIRING_FAILED
            ? {
                type: 'error',
                text: this.translateService.instant('DeviceNotFound')
              }
            : null;

        this.form.reset();
        this.showCancel = false;
        this.disableSelector = false;
        this.deviceId = null;

        break;
    }
  }

  public connectMonitorDevice(option: ActiveDevice) {
    if (!option) {
      return;
    }

    this.disableSelector = true;
    this.message = {
      type: 'loading',
      text: this.translateService.instant('SearchingMonitorDevice')
    };

    const pairWith = option.id !== AUTO_PAIR_ID ? option.id : null;
    this.message = {
      type: 'loading',
      text: this.translateService.instant(option.id ? 'PairingInProgress' : 'SearchingMonitorDevice')
    };

    this.monitorDeviceService
      .pair(this.therapyJob?.id, pairWith)
      .pipe(
        take(1),
        catchError((e) => {
          this.message = {
            type: 'error',
            text: this.translateService.instant('DeviceNotFound')
          };

          this.showCancel = false;
          this.disableSelector = false;
          this.deviceId = null;
          return of();
        })
      )
      .subscribe();
  }

  public cancelPairing() {
    this.processingCancel = true;
    this.message = {
      type: 'loading',
      text: this.translateService.instant('cancellingPairing')
    };

    if (!this.deviceId) {
      this.processingCancel = false;
      return;
    }
    this.monitorDeviceService
      .cancel(this.deviceId)
      .pipe(take(1))
      .subscribe(() => {
        this.processingCancel = false;

        this.form.get('monitorDevice').setValue(null);

        this.message = null;
        this.showCancel = false;
        this.disableSelector = false;
      });
  }

  private loadActiveDevices(cached: boolean) {
    this.unsubscribeEventSub.next();

    if (
      this.therapyJob?.pairingState === PairingStatus.PAIRED ||
      this.therapyJob?.status === this.therapyJobStatus.COMPLETED
    ) {
      // we do not request active devices on completed/paired status
      return;
    }

    this.loadingActiveDevices = true;

    const actives$ = this.monitorDeviceService.getActive(this.therapyJob.id, cached).pipe(take(1));
    const events$ = this.eventSub.pipe(
      takeUntil(this.unsubscribeEventSub),
      filter((ev) => ev.pairingState === PairingStatus.PAIRED || ev.therapyJobId !== this.therapyJob.id)
    );

    actives$
      .pipe(
        tap((devices: Array<MonitorDevice> | ResponseError) => {
          if ((devices as ResponseErrorExtended).messageKey) {
            this.data = [this.defaultOption];
          } else {
            this.data = [
              this.defaultOption,
              ...(devices as Array<MonitorDevice>).map<ActiveDevice>((d) => ({
                id: d.id,
                label: d.id,
                attrVef: 'device-selector-dropdown-activeDevice-option'
              }))
            ];
          }

          this.loadingActiveDevices = false;
        }),
        filter((devices: Array<MonitorDevice> | ResponseError) => !(devices as ResponseError).messageKey),
        switchMap(() =>
          events$.pipe(
            tap((event: SsePairing) => {
              // we filter out the devices that are paired
              this.data = this.data.filter((d) => d.id !== event.deviceId);
            })
          )
        )
      )
      .subscribe();
  }

  public toggleDropdown(value: boolean) {
    if (value) {
      this.message = null;
      this.loadActiveDevices(false);
    }
  }
}
