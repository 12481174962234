<div class="wrapper">
  <h4 class="title">{{ 'OnDemandSectionTitle' | translate }}</h4>

  @if(dataLoader === null){

  <div class="image-wrapper empty-state-image">
    <!-- The image is set in CSS to be able to change it when dark mode -->
    <div class="caption">{{ 'OnDemandSectionEmptyState' | translate }}</div>
  </div>
  } @if(dataLoader === true){
  <div class="loader-wrapper">
    <span class="loader"></span>
  </div>
  } @if (dataLoader === false && !prescriptions?.length){
  <div class="image-wrapper">
    <img class="image" src="assets/imgs/no-results-on-demand.svg" alt="" />
    <div class="caption">{{ 'OnDemandSectionNoResults' | translate }}</div>
  </div>

  } @if (dataLoader === false && prescriptions?.length){

  <!--  Manual creation option -->
  <cw-list *ngIf="configuration?.general?.allowManualJobs" class="cw-list" clickable="true">
    <cw-list-item
      data-test="manual-list-item"
      [itemTitle]="'OnDemandSectionManualCreationOption' | translate"
      [active]="(selectedValue | async) === 'manual'"
      (click)="selectedValue.next('manual')"
    >
      <div slot="left">
        <cw-radio-button class="cw-radio-button" [selected]="(selectedValue | async) === 'manual'"></cw-radio-button>
      </div>
    </cw-list-item>
  </cw-list>

  <!--  PRN prescriptions -->
  <cw-list [listTitle]="'OnDemandSectionSubTitle' | translate" class="cw-list" clickable="true">
    @for( prescription of prescriptions; track prescription.id){

    <cw-list-item
      [attr.data-vef]="'prnTable-row-create-button'"
      data-test="prescription-list-item"
      (click)="selectedValue.next(prescription)"
      [itemTitle]="prescription.medicinesFormatted"
      [subtitle]="prescription.dosesFormatted"
      [active]="(selectedValue | async)?.id === prescription.id"
    >
      <div slot="left">
        <cw-radio-button
          class="cw-radio-button"
          [selected]="(selectedValue | async)?.id === prescription.id"
        ></cw-radio-button>
      </div>

      <div class="count" *ngIf="prescription.medication?.order" slot="right">
        {{ prescription.medication?.count }} / {{ prescription.medication?.order }}
      </div>
    </cw-list-item>
    }
  </cw-list>

  }
</div>
