import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { addEntities, addEntity, removeEntity, updateEntity, withEntities } from '@ngrx/signals/entities';
import { NotificationType } from '../enums/NotificationId';
import { NotificationKey } from '../enums/NotificationKey';
import { NotificationLevel } from '../enums/NotificationLevel';
import { TherapyJobExtended } from '../models/TherapyJob';
import { TherapyJobNotification } from '../models/TherapyJobNotification';
import { FilterNotificationPipe } from '../pipes/FilterNotifications';
import { getCriticalIndex, getCriticalType } from '../utils/getCriticalNotificationType';
import { notifyAbortTriggered, unNotifyAbortTriggered } from '../utils/notifications/AbortTriggered';
import { notifyDetachedDripChamber, unNotifyDetachedDripChamber } from '../utils/notifications/DetachedDripChamber';
import { notifyNoVolumeProvided } from '../utils/notifications/NoVolumeProvided';
import { notifyPendingConfirmation, unNotifyPendingConfirmation } from '../utils/notifications/PendingConfirmation';
import { notifyUnpairOnRunning, unNotifyUnpairOnRunning } from '../utils/notifications/UnpairedOnRunning';
import { timePassedInMs } from '../utils/TimePassed';
import { TherapyJobStore } from './job.store';

export const THIRTY_MINUTES = 30 * 60 * 1000;

interface NotificationsState {
  loading: boolean;
}

const initialNotificationSate: NotificationsState = {
  loading: false
};

const filterNotificationPipe = new FilterNotificationPipe();

export const NotificationsStore = signalStore(
  { providedIn: 'root' },
  withEntities<TherapyJobNotification>(),
  withDevtools('notifications'),
  withState(initialNotificationSate),
  withMethods((store, jobStore = inject(TherapyJobStore)) => ({
    setAll() {
      const allNotifications = [];
      jobStore.entities().forEach((tj) => {
        if (notifyUnpairOnRunning(store, tj)) {
          allNotifications.push(
            getNotification(tj, NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD, NotificationLevel.INFO, false)
          );
        }

        if (notifyDetachedDripChamber(store, tj)) {
          allNotifications.push(getNotification(tj, NotificationType.DRIP_CHAMBER_DETACHED, NotificationLevel.ERROR));
        }

        if (notifyPendingConfirmation(store, tj)) {
          const timePassed = timePassedInMs(tj.endTime);
          allNotifications.push(
            getNotification(
              tj,
              NotificationType.PENDING_CONFIRMATION,
              timePassed > THIRTY_MINUTES ? NotificationLevel.WARN : NotificationLevel.INFO,
              false
            )
          );
        }

        if (notifyAbortTriggered(store, tj)) {
          allNotifications.push(
            getNotification(tj, NotificationType.THERAPY_JOB_ABORT_TRIGGERED, NotificationLevel.WARN)
          );
        }

        if (notifyNoVolumeProvided(store, tj)) {
          allNotifications.push(
            getNotification(tj, NotificationType.NO_VOLUME_PROVIDED, NotificationLevel.WARN, false)
          );
        }
      });

      patchState(store, addEntities(allNotifications));
    },
    setDripChamberNotification(id: string) {
      const tj = jobStore.entityMap()[id];

      if (notifyDetachedDripChamber(store, tj)) {
        patchState(
          store,
          addEntity(getNotification(tj, NotificationType.DRIP_CHAMBER_DETACHED, NotificationLevel.ERROR))
        );
      } else if (unNotifyDetachedDripChamber(store, tj)) {
        patchState(store, removeEntity(`${NotificationType.DRIP_CHAMBER_DETACHED}-${tj.id}`));
      }
    },
    setUnpairOnRunning(id: string) {
      const tj = jobStore.entityMap()[id];

      if (!tj) {
        return;
      }

      if (notifyUnpairOnRunning(store, tj)) {
        patchState(
          store,
          addEntity(getNotification(tj, NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD, NotificationLevel.INFO, false))
        );
      } else if (unNotifyUnpairOnRunning(store, tj)) {
        patchState(store, removeEntity(`${NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD}-${tj.id}`));
      }
    },
    setPendingConfirmation(id: string, level: NotificationLevel = NotificationLevel.INFO) {
      const tj = jobStore.entityMap()[id];

      if (!tj) {
        return;
      }

      if (notifyPendingConfirmation(store, tj)) {
        patchState(store, addEntity(getNotification(tj, NotificationType.PENDING_CONFIRMATION, level, false)));
      } else if (unNotifyPendingConfirmation(store, tj)) {
        patchState(store, removeEntity(`${NotificationType.PENDING_CONFIRMATION}-${tj.id}`));
      }
    },
    setAbortTriggered(id: string) {
      const tj = jobStore.entityMap()[id];

      if (!tj) {
        return;
      }

      if (notifyAbortTriggered(store, tj)) {
        patchState(
          store,
          addEntity(getNotification(tj, NotificationType.THERAPY_JOB_ABORT_TRIGGERED, NotificationLevel.WARN))
        );
      } else if (unNotifyAbortTriggered(store, tj)) {
        patchState(store, removeEntity(`${NotificationType.THERAPY_JOB_ABORT_TRIGGERED}-${tj.id}`));
      }
    },
    getNotificationLevelById(therapyJobId: string): number {
      // we use the index for sorting
      return getCriticalIndex(filterNotificationPipe.transform(store.entityMap(), therapyJobId));
    },
    modifyPendingConfirmation(therapyJob: TherapyJobExtended) {
      const notificationId: string = `${NotificationType.PENDING_CONFIRMATION}-${therapyJob.id}`;

      // if pending confirmation notification is already a warning, we don't want to change it
      if (store.entityMap()?.[notificationId].level === NotificationLevel.WARN) {
        return;
      }

      // if has not passed 30 minutes since the therapy job was completed, we don't want to change it
      if (timePassedInMs(therapyJob.endTime) <= THIRTY_MINUTES) {
        return;
      }

      patchState(
        store,
        updateEntity({
          id: notificationId,
          changes: () => ({ level: NotificationLevel.WARN })
        })
      );
    },
    update(id: string, partial: Partial<TherapyJobNotification>) {
      patchState(store, updateEntity({ id, changes: partial }));
    }
  })),
  withComputed((state) => ({
    count: computed(() => {
      return state.entities()?.filter((notification) => notification.showAlarmCenter)?.length;
    }),
    criticality: computed(() => {
      const reduced = state.entities().reduce((acc, notification) => {
        if (notification.showAlarmCenter) {
          acc[notification.id] = notification;
          return acc;
        }
        return acc;
      }, {});

      return getCriticalType(reduced);
    })
  }))
);

export type NotificationsStore = InstanceType<typeof NotificationsStore>;

const getNotification: (
  tj: TherapyJobExtended,
  type: NotificationType,
  level: NotificationLevel,
  showAlarmCenter?: boolean
) => TherapyJobNotification = (tj, type, level, showAlarmCenter = true) => {
  const id = `${type}-${tj.id}`;
  return {
    id,
    type,
    timestamp: tj?.event?.timestamp,
    therapyJobId: tj.id,
    translationKey: `Notification${type}` as NotificationKey,
    hintKey: `Notification${type}Hint` as NotificationKey,
    level,
    showAlarmCenter,
    therapyJob: tj
  };
};
