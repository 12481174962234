import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isPresent } from './common';

export class DataTypeValidator {
  static isString(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!isPresent(control.value)) {
        return null;
      }

      return typeof control.value !== 'string' ? { notString: true } : null;
    };
  }

  static isNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => (isNaN(control.value) ? { notNumber: true } : null);
  }
}
