import { CommonModule } from '@angular/common';
import { Component, ContentChild, ElementRef, Input, TemplateRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GplusCellDirective } from './directives/cellDirective.directive';

export interface GplusTableColumn {
  bindValue: string;
  translationKey: string;
  // Just for cards
  useAsHeader?: boolean;
}

@Component({
  selector: 'gplus-table',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent {
  @Input() data: Array<{ [key: string]: any }>;
  @Input() columns: Array<GplusTableColumn>;
  @Input() convertToList: boolean;
  @Input() noData: boolean;

  @ContentChild(GplusCellDirective, { read: TemplateRef }) cellTemplate: ElementRef;

  @ContentChild('noDataTemplate', { read: TemplateRef }) noDataTemplate: ElementRef;
}
