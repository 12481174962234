@if(jobsStore.loading() || processing){
<div class="loader"></div>
}
<div class="board-wrapper" [class.loading]="jobsStore.loading() || processing">
  <div class="tabs">
    @for (column of columns(); track column.id) {

    <cw-chip
      customShadowCss
      styles=".chip.radio{padding: 0 0.5rem!important;line-height:1!important}"
      (click)="selectChip(column.id)"
      [state]="column.id === currentColumn ? 'active' : null"
    >
      {{ 'Columns' + column.id | translate }} ({{ column?.therapyJobs.length }})</cw-chip
    >
    }
  </div>
  @for (column of columns(); track column.id) {

  <gplus-board-column
    class="board-column"
    [attr.data-vef]="'column-title-' + column?.id"
    [class.active]="column.id === currentColumn"
    [event]="eventManager.pairingSignal()"
    [id]="column.id"
    [column]="column"
    [tjHighlightMap]="highlightService.highlightMap()"
    [tJNotificationsMap]="notificationStore.entityMap()"
    [ticker]="ticker"
    [highlightTerm]="highlightTerm()"
    (onChange)="onTherapyJobChange($event)"
    (onDelete)="onDelete($event)"
    (onUnpair)="onUnpair($event)"
    (onFinalize)="onFinalize($event)"
    (onAbort)="onAbort($event)"
  ></gplus-board-column>
  }
</div>
