import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxCheckbox } from '@bbraun/cortex-angular';
import { Subject, fromEvent, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'cx-checkbox-extended',
  imports: [CxCheckbox, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CxCheckboxExtendedComponent),
      multi: true
    }
  ],
  template: `<cx-checkbox [disabled]="disabled" [checked]="val"><ng-content></ng-content></cx-checkbox>`
})
export class CxCheckboxExtendedComponent implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges {
  private destroy: Subject<void> = new Subject();
  private inputElm;

  @Input() disabled: boolean;
  @Input() checked: boolean;

  public val;
  set value(val: boolean) {
    if (this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  constructor(private ref: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputElm = this.ref.nativeElement.querySelector('cx-checkbox');

      if (!this.inputElm) {
        console.warn('checkbox not found');
        return;
      }

      fromEvent(this.inputElm, 'checkboxChange')
        .pipe(takeUntil(this.destroy))
        .subscribe((event: InputEvent) => {
          this.value = (event.target as any).checked;
        });
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.checked?.firstChange && changes?.checked?.currentValue === null) {
      setTimeout(() => {
        this.value = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
