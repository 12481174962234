import { PairingStatus } from '../enums/PairingStatus';

export enum SseEventType {
  JOB_FINISH = 'JOB_FINISH',
  JOB_START = 'JOB_START',
  JOB_UPDATE = 'JOB_UPDATE',
  PAIRING = 'PAIRING',
  JOB_STATE = 'JOB_STATE'
}

export enum SseEventMessageKey {
  PAIRING_FAILED = 'pairing_failed'
}

export interface SseInfusion {
  therapyJobId: string;
  plannedFlowRate: number;
  currentFlowRate: number;
  injectedVolume: number;
  remainingVolume: number;
  remainingTime: number;
  totalVolume: number;
  ts: number;
  type?: SseEventType;
}

export interface SsePairing {
  therapyJobId: string;
  pairingState: PairingStatus;
  deviceId: string;
  messageKey: string;
  ts: number;
  type?: SseEventType;
}

export interface SseState {
  therapyJobId: string;
  dripChamberDetached: boolean;
  dripChamberTilted: boolean;
  sensorNotPlausible: boolean;
  sensorSaturated: boolean;
  sensorDefective: boolean;
  ts: number;
  type?: SseEventType;
  timestamp?: number;
}
