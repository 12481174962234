import { Injectable, WritableSignal, signal } from '@angular/core';

export interface Overlay {
  id: string;
  open?: boolean;
  component: any;
  inputs: {
    [key: string]: any;
  };
}

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlaySignal: WritableSignal<Overlay> = signal(null);
  public overlay = this.overlaySignal.asReadonly();

  public toggle(overlay: Overlay): void {
    this.overlaySignal.update((prevOverlay) => {
      if (overlay.id === prevOverlay?.id) {
        // we toggle using the prev value
        return { ...prevOverlay, open: !prevOverlay.open };
      }
      return { ...overlay, open: true };
    });
  }

  public close(): void {
    this.overlaySignal.update(() => null);
  }
}
