import { CommonModule, DatePipe } from '@angular/common';
import { Component, computed, Input, OnChanges, Signal, SimpleChanges } from '@angular/core';
import { CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IconDeviceComponent } from '~/app/custom-icons/icon-device/icon-device.component';
import { CardFields } from '~/app/enums/CardFields';
import { DateFormat } from '~/app/enums/DateFormat';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { HighlightPipe } from '~/app/pipes/Hightlight';
import { IsTodayPipe } from '~/app/pipes/IsToday';
import { LocalizedDatePipe } from '~/app/pipes/LocalizedDate';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { ConfigService } from '~/app/services/config.service';

const FLOW_RATE_UNIT_KEY = 'UnitMlHr';

interface Field {
  disabled: boolean;
  id: string;
}

@Component({
  selector: 'gplus-therapy-job-body',
  standalone: true,
  imports: [CwIcon, IconDeviceComponent, HighlightPipe, CommonModule, TranslateModule, PersonNamePipe],
  providers: [DatePipe, LocalizedDatePipe, IsTodayPipe],
  templateUrl: './therapy-job-body.component.html',
  styleUrl: './therapy-job-body.component.scss'
})
export class TherapyJobBodyComponent implements OnChanges {
  @Input() therapyJob: TherapyJobExtended;
  @Input() highlightTerm: string;
  @Input() timeError: string;

  public medicine: { first: string; more: string };
  public flowRate: string;
  public time: string;

  public pairingStatus = PairingStatus;
  public cardFields = CardFields;
  public therapyJobStatus = TherapyJobStatus;

  public fields: Signal<Array<Field>>;

  constructor(
    private translateService: TranslateService,
    private readonly localizedDatePipe: LocalizedDatePipe,
    private readonly isTodayPipe: IsTodayPipe,
    public readonly configService: ConfigService
  ) {
    this.fields = computed(() => {
      return this.configService.configuration()?.content?.appearanceConfiguration?.cardDetails.map((field) => {
        return {
          id: field,
          disabled: false
        };
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.therapyJob) {
      this.setMedicine();
      this.setFlowRate(this.therapyJob?.status);
      this.setTime(this.therapyJob?.status);
    }
  }

  private setMedicine() {
    const medicines = this.therapyJob?.medication?.medicines;
    if (!medicines?.length) {
      this.medicine = {
        first: '-',
        more: null
      };
      return;
    }

    this.medicine = {
      first: medicines?.[0]?.display || '-',
      more: medicines.length > 1 ? `+ ${medicines.length - 1}` : null
    };
  }

  private setFlowRate(status: TherapyJobStatus) {
    switch (status) {
      case TherapyJobStatus.PRESCRIBED:
        const planned = this.therapyJob?.flowRate?.planned;
        this.flowRate = planned ? `${planned} ${this.translateService.instant(FLOW_RATE_UNIT_KEY)}` : null;

        break;

      case TherapyJobStatus.RUNNING:
        this.flowRate = `${this.therapyJob?.latestEvent?.currentFlowRate || 0} ${this.translateService.instant(
          FLOW_RATE_UNIT_KEY
        )}`;
        break;

      case TherapyJobStatus.COMPLETED:
        this.flowRate = `0 ${this.translateService.instant(FLOW_RATE_UNIT_KEY)}`;
        break;
    }
  }

  private setTime(status: TherapyJobStatus) {
    switch (status) {
      case TherapyJobStatus.PRESCRIBED:
        const planned = this.therapyJob?.startTime?.planned;
        this.time = planned ? `${this.getFormattedTime(planned)}` : null;
        break;

      case TherapyJobStatus.RUNNING:
        const real = this.therapyJob?.startTime?.real;
        this.time = real ? `${this.translateService.instant('LabelStartTime')} ${this.getFormattedTime(real)}` : null;
        break;

      case TherapyJobStatus.COMPLETED:
      case TherapyJobStatus.COMPLETE_CONFIRMED:
        const endTime = this.therapyJob?.endTime;
        this.time = endTime
          ? `${this.translateService.instant('LabelEndTime')} ${this.getFormattedTime(endTime)}`
          : null;
        break;

      case TherapyJobStatus.ABORTION_TRIGGERED:
      case TherapyJobStatus.ABORTED:
        const abortedTime = this.therapyJob?.endTime;
        this.time = abortedTime
          ? `${this.translateService.instant('LabelAbortedTime')} ${this.getFormattedTime(abortedTime)}`
          : null;
        break;
    }
  }

  private getFormattedTime(time: number): string {
    return this.localizedDatePipe.transform(
      time,
      this.isTodayPipe.transform(time) ? DateFormat.SHORT_TIME : DateFormat.MEDIUM
    );
  }
}
