export interface GeneralConfiguration {
  enableFlushReminder: boolean;
  allowPreparationMarking: boolean;
  allowManualJobs: boolean;
  defaultNumDropsPerMl: number;
  allowStatusChangeInJobs: boolean;
  enableAdHocMonitoring: boolean;
}

export interface MonitoringConfiguration {
  enableDripChamberDetachmentAlert: boolean;
  flowRateDeviation: {
    enableAlert: boolean;
    deviationPercentage: number;
  };
  volumeDeviation: {
    enableAlert: boolean;
    deviationPercentage: number;
  };
}

export interface AppearanceConfiguration {
  filterOptions: Array<string>;
  cardDetails: Array<string>;
}

export interface MonitorDeviceConfiguration {
  language: string;
}

export interface Configuration {
  general: GeneralConfiguration;
  monitoringSettings: MonitoringConfiguration;
  appearanceConfiguration: AppearanceConfiguration;
  monitorDeviceConfiguration: MonitorDeviceConfiguration;
}

/**
 * We generate the following types based on the Configuration interface and to be able to type control objects
 */
export enum ConfigUiControl {
  TOGGLE = 'toggle',
  NUMBER = 'number',
  SELECT = 'select',
  MULTI_SELECT = 'multiSelect',
  DRAG_DROP_LIST = 'dragDropList',
  TEXT = 'text'
}

type Overwrite<T, NewType> = {
  [P in keyof T]: NewType;
};

export interface ConfigControl {
  id: string;
  type: ConfigUiControl;
  path: string;
  options?: Array<{ id: string; label: string }>;
  order?: number;
}

export type GeneralConfigurationControl = Overwrite<GeneralConfiguration, ConfigUiControl>;
export type MonitoringConfigurationControl = Overwrite<MonitoringConfiguration, ConfigUiControl>;
export type AppearanceConfigurationControl = Overwrite<AppearanceConfiguration, ConfigUiControl>;
export type MonitorDeviceConfigurationControl = Overwrite<MonitorDeviceConfiguration, ConfigUiControl>;
export type ConfigurationControl = Overwrite<Configuration, ConfigUiControl>;
