<!-- Implement a custom banner, the cortex one does not have a custom icon -->
<div *ngIf="dialog?.data?.annotations" class="banner">
  <cw-icon icon="clinical_notes" [filled]="false"></cw-icon>
  {{ dialog?.data?.annotations }}
</div>

<div
  *ngIf="dialog?.data?.patient"
  [innerHTML]="'TherapyJobFinishText' | translate : { patientName: dialog.data.patient | personName }"
></div>

<form *ngIf="form" class="form" [formGroup]="form">
  <gplus-textarea
    class="full-group"
    [required]="true"
    [label]="'TherapyJobFinishAbortedLabelInput' | translate"
    [control]="form.controls['annotations']"
    [assistiveText]="'FormTJCreationAnnotationsMaxCharacters' | translate : { max: annotationMaxLength }"
    [validations]="{
      maxlength: 'FormTJCreationAnnotationsMaxCharacters' | translate : { max: annotationMaxLength },
      required: 'TherapyJobFinishInputRequired' | translate
    }"
  ></gplus-textarea>
</form>

<div class="actions-btns">
  <cw-button button-color="secondary" variant="outlined" (click)="closeDialog()">
    {{ 'TherapyJobFinishCancel' | translate }}
  </cw-button>

  @if(form.invalid){

  <!-- Disabled -->
  <cw-tooltip
    [content]="(form?.controls?.['annotations']?.hasError('maxlength') ? 'TherapyJobFinishDisabledSubmitErrorTooltip': 'TherapyJobFinishDisabledSubmitRequiredTooltip') | translate"
    placement="bottom"
  >
    <cw-button button-color="accent" variant="filled" [disabled]="true" type="submit">
      {{ 'TherapyJobFinishSubmit' | translate }}
    </cw-button>
  </cw-tooltip>

  } @else{
  <!-- Enabled -->
  <cw-button button-color="accent" variant="filled" (click)="submitChange()" type="submit">
    {{ 'TherapyJobFinishSubmit' | translate }}
  </cw-button>
  }
</div>
