@if(submitLoader){
<div class="container-loader">
  <span class="loader absolute-centered"></span>
</div>

}

<form class="page" *ngIf="form && data" [formGroup]="form">
  <gplus-detail-page-body
    class="page-body"
    [pageStatus]="pageStatus"
    [therapyJob]="therapyJob"
    [data]="data"
    [form]="form"
    [tjNotificationMap]="tJNotificationsMap | filterNotification : therapyJob.id"
    (submitChange)="submitChange()"
    (resetChange)="resetChange()"
  ></gplus-detail-page-body>
  <gplus-detail-page-sidebar
    class="page-sidebar"
    [pageStatus]="pageStatus"
    [therapyJob]="therapyJob"
    [data]="data"
    [form]="form"
    [tjNotificationMap]="tJNotificationsMap | filterNotification : therapyJob.id"
    [ticker]="ticker"
  ></gplus-detail-page-sidebar>
</form>
