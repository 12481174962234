import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, of } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { MonitorDevice } from '../models/MonitorDevice';
import { ResponseError, ResponseErrorExtended } from '../models/ResponseError';
import { ResponsePair } from '../models/ResponsePair';
import { EnvironmentService } from './environment.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class MonitorDeviceService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService
  ) {}

  public getActive(therapyJobId: string): Observable<Array<MonitorDevice> | ResponseErrorExtended> {
    return this.httpClient
      .get<Array<MonitorDevice> | ResponseError>(`${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}/device`)
      .pipe(
        catchError((e) => {
          console.error('Error requesting active devices', e);
          return of({ messageKey: e?.error?.messageKey || 'ErrorGeneric', status: e.status } as ResponseError);
        })
      );
  }

  public pairToDevice(therapyJobId: string, deviceId: string): Observable<void | ResponseErrorExtended> {
    return this.httpClient
      .put<void | ResponseError>(
        `${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}/device/${deviceId}/pair`,
        null
      )
      .pipe(
        catchError((e) => {
          console.error(`Error pairing to device: ${deviceId}`, e);
          return of({ messageKey: e?.error?.messageKey || 'ErrorPairingWith', status: e.status } as ResponseError);
        })
      );
  }

  public autoPair(therapyJobId: string): Observable<void | ResponseErrorExtended> {
    return this.httpClient
      .put<void | ResponseError>(`${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}/pair`, null)
      .pipe(
        catchError((e) => {
          if (e.status === 404) {
            return of({ messageKey: 'DeviceNotFound', status: e.status } as ResponseError);
          }
          console.error(`Error with auto-pairing request`, e);
          return of({ messageKey: e?.error?.messageKey || 'ErrorGeneric', status: e.status } as ResponseError);
        })
      );
  }

  public cancel(therapyJobId: string): Observable<ResponsePair | ResponseErrorExtended> {
    let params = new HttpParams().set('action', 'CANCEL_PAIR');
    return this.httpClient
      .delete<ResponsePair | ResponseError>(`${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}/pair`, { params })
      .pipe(
        catchError((e) => {
          console.error('Error canceling pairing', e);
          this.showErrorMessage(this.translateService.instant('ErrorGeneric'));
          return of({
            messageKey: e?.error?.messageKey || 'ErrorGeneric'
          } as ResponseErrorExtended);
        })
      );
  }

  public unpair(therapyJobId: string): Observable<void | ResponseErrorExtended> {
    let params = new HttpParams().set('action', 'UN_PAIR');
    return this.httpClient
      .delete<void | ResponseError>(`${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}/pair`, { params })
      .pipe(
        catchError((e) => {
          console.error('Error un-pairing', e);
          this.showErrorMessage(this.translateService.instant('ErrorGeneric'));
          return of({
            messageKey: e?.error?.messageKey || 'ErrorGeneric'
          } as ResponseErrorExtended);
        })
      );
  }

  private showErrorMessage(message: string) {
    this.toastService.show({
      message,
      type: NotificationLevel.ERROR,
      enableCloseButton: true,
      delay: 5_000
    });
  }
}
