import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormsModule } from '@angular/forms';
import { CwTextarea } from '@bbraun/cortex-angular';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { TextAreaValueAccessor } from '~/app/directives/TextAreaValueAccessor';

@Component({
  selector: 'gplus-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  imports: [CwTextarea, CustomShadowCssDirective, TextAreaValueAccessor, FormsModule],
  standalone: true
})
export class TextareaComponent implements OnInit {
  public customShadowCss: string;

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() attrVef: string;
  @Input() validations: Record<string, any> = {};

  public error: string;
  public inputTextValue: string;
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.customShadowCss = `
    .input-text { 
      width: 100% !important;
      box-sizing: border-box !important;
    }
    .textarea {
      margin: 0 !important;
    }
    ${
      !this.label
        ? `cw-input-label {
        display: none !important;
      }`
        : ''
    }
  `;

    if (this.control) {
      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
        this.inputTextValue = this.control.value;

        if (this.control.pristine) {
          this.error = null;
          return;
        }

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }

  public change(value: string) {
    if (!this.control) {
      return;
    }
    this.control.setValue(value);
    this.control.markAsDirty();
  }
}
