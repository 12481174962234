import { CommonModule } from '@angular/common';
import { Component, inject, signal, WritableSignal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CwBadgeNotification, CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { switchMap, take } from 'rxjs';
import { EmployeeType } from '~/app/enums/EmployeeType';
import { EmployeeService } from '~/app/services/employee.service';
import { HeaderService } from '~/app/services/header.service';
import { SidebarIds, SidebarService } from '~/app/services/sidebar.service';
import { UserService } from '~/app/services/user.service';
import { NotificationsStore } from '~/app/store/notification.store';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { UserComponent } from '../user/user.component';

@Component({
  selector: 'gplus-header',
  standalone: true,
  imports: [
    SearchBarComponent,
    UserComponent,
    TranslateModule,
    CwIcon,
    CommonModule,
    RouterModule,
    CwBadgeNotification
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  public showSearch: boolean = true;
  public animateBadge = 'animate-badge';
  public numNotification: number;
  public showConfiguration: WritableSignal<boolean> = signal(false);

  public store = inject(NotificationsStore);

  constructor(
    public readonly headerService: HeaderService,
    private readonly sidebarService: SidebarService,
    private readonly employeeService: EmployeeService,
    private readonly userService: UserService
  ) {
    this.getConfigurationPermissions();
  }

  public openMobileMenuClicked() {
    this.headerService.setMobileMenuBtn(!this.headerService.toggleMobileMenu());
  }

  public alarmClicked() {
    this.sidebarService.open(SidebarIds.ALARM_CENTER);
  }

  private getConfigurationPermissions() {
    this.userService
      .getMe()
      .pipe(
        take(1),
        switchMap((me) => this.employeeService.getEmployeeTypes(me.id))
      )
      .subscribe((employeeTypes) => this.showConfiguration.set(employeeTypes?.includes(EmployeeType.ADMIN)));
  }
}
