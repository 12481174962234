// Collection of basic JavaScript functions that can replace Lodash functions

function cloneObject(src) {
  // initialize new object or array
  const target = Array.isArray(src) ? [] : {};
  for (const prop in src) {
    if (src.hasOwnProperty(prop)) {
      if (src[prop] != null && typeof src[prop] === 'object') {
        target[prop] = cloneDeep(src[prop]);
      } else {
        target[prop] = src[prop];
      }
    }
  }
  return target;
}

/**
 * Creates a deep copy of any given JavaScript-primitive input object.
 * Dose NOT support Binary types, Symbols, etc. yet...
 * @param src: Input object
 */
export function cloneDeep(src): any {
  if (src === null) {
    return null;
  }
  if (src === undefined) {
    return undefined;
  }
  if (typeof src === 'string') {
    return ` ${src}`.slice(1);
  }
  if (typeof src === 'number') {
    return Number(`${src}`);
  }
  if (typeof src === 'boolean') {
    if (src) {
      return true;
    }
    return false;
  }
  if (typeof src === 'object') {
    return cloneObject(src);
  }
  throw new Error(
    'Only JavaScript Primitives (null, undefined, object, string, number, boolean) can be cloned with this function!'
  );
}

/**
 * Gets a property of an object by string path
 * @param obj: Object to get the property from
 * @param path: Path of the property, eg. outerAttribut.innerArray[1].arrayItemProperty
 * @param defaultValue: Value to return if the path can not be found
 */
export function get(obj, path, defaultValue?): any {
  const fullPath = path.replace(/\[/g, '.').replace(/]/g, '').split('.').filter(Boolean);

  return fullPath.every(everyFunc) ? obj : defaultValue;

  function everyFunc(step) {
    obj = obj[step];
    return !(step && obj === undefined);
  }
}

/**
 * Sets a property of an object by string path
 * @param obj: Object to set the property into
 * @param path: Path of the property, eg. outerAttribut.innerArray[1].arrayItemProperty
 * @param value: value to set the property to
 */
export function set(obj, path, value): void {
  function isObject(input) {
    return null !== input && typeof input === 'object' && Object.getPrototypeOf(input).isPrototypeOf(Object);
  }

  const pList = Array.isArray(path) ? path : path.replace(/\[/g, '.').replace(/]/g, '').split('.').filter(Boolean);
  const len = pList.length;
  // changes second last key to {}
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!obj[elem] || (!isObject(obj[elem]) && !Array.isArray(obj[elem]))) {
      obj[elem] = {};
    }
    obj = obj[elem];
  }
  // set value to second last key
  obj[pList[len - 1]] = value;
}

export function isPresent(obj) {
  return obj !== null && obj !== undefined;
}

export function isEmpty(obj) {
  return obj === null || obj === undefined || obj === '';
}

export function isEmptyArray(obj) {
  return obj === null || obj === undefined || (Array.isArray(obj) && obj?.length === 0);
}

export function isNonBlankString(obj) {
  if (isEmpty(obj)) {
    return false;
  }
  if (typeof obj !== 'string') {
    return false;
  } else {
    return !isEmpty(cloneDeep(obj).trim());
  }
}

export function isBlankString(obj) {
  if (!isPresent(obj)) {
    return false;
  }
  if (typeof obj !== 'string') {
    return false;
  } else {
    return isEmpty(cloneDeep(obj).trim());
  }
}

export function isIterable(value) {
  return Symbol.iterator in Object(value);
}

/**
 * Splices an array into an array of multiple arrays that all have a max chunk size
 *
 * @param array array to be chunked
 * @param chunkSize max size of each chunk
 */
export function createChunks(array: any[], chunkSize: number): any[] {
  return array.reduce((all, one, i) => {
    const chunkCount = Math.floor(i / chunkSize);
    all[chunkCount] = [].concat(all[chunkCount] || [], one);
    return all;
  }, []);
}

/**
 * Safely tries to convert any input into a number object, returns NaN otherwise
 * @param input: input (string-number or number)
 * @param defaultValue the default Value to return if the input is not a number
 */
export function safeToNumber(input: any, defaultValue = NaN): number {
  if (typeof input === 'number') {
    return input;
  }
  if (typeof input === 'boolean') {
    return defaultValue;
  }
  if (isNaN(input)) {
    return defaultValue;
  }
  return Number(input);
}

/**
 * A simple, promisified setTimeout, so it can be used with await in async functions
 * @param timeout milliseconds to resolve the Promise
 */
export function wait(timeout): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

/**
 * Generic type for resolve() within a Promise
 */
export type PromiseResolve<T> = (value: T | PromiseLike<T>) => void;

/**
 * Generic type for reject() within a Promise
 */
export type PromiseReject = (reason?: any) => void;
