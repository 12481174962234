import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CwButton, CwIcon } from '@bbraun/cortex-angular';
import { iconsMap } from '~/app/constants/notificationIconsMap';

@Component({
  selector: 'gplus-snackbar',
  standalone: true,
  imports: [CwIcon, CwButton],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss'
})
export class SnackbarComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() timestamp: string;
  @Input() type: string;
  @Input() icon: string;

  @Input() closeable: boolean;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  public iconsMap: { [key: string]: string } = iconsMap;
}
