@if(loading || jobsStore.loading()){
<span class="loader absolute-centered"></span>

} @else { @if(pageStatus === detailPageStatus.EDITION){

<gplus-detail-page-view
  *ngIf="form && therapyJob && sessionData"
  [event]="event"
  [data]="sessionData"
  [form]="form"
  [pageStatus]="pageStatus"
  [therapyJob]="therapyJob"
  [tJNotificationsMap]="notificationStore.entityMap()"
  [ticker]="ticker"
></gplus-detail-page-view>

} @else {
<gplus-detail-page-new
  *ngIf="form && sessionData"
  [data]="sessionData"
  [form]="form"
  [pageStatus]="pageStatus"
  [prescription]="prescription"
></gplus-detail-page-new>

} }
