import { Directive, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[gplus-cell-tmp]',
  standalone: true
})
export class GplusCellDirective {
  constructor(public template: TemplateRef<any>) {}
}
