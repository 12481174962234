<cx-menu>
  <div slot="trigger">
    @if(image){
    <img class="avatar" [src]="image" alt="profile-picture" />

    } @else {

    <cx-avatar *ngIf="profile" avatar-type="icon" size="md" state="online"></cx-avatar>

    }
  </div>

  <div slot="header" *ngIf="profile">
    <div class="user-details">
      <div class="text">
        <h5 class="name">{{ profile.firstName }} {{ profile.lastName }}</h5>
        <div class="email">{{ profile.email }}</div>
      </div>
    </div>
  </div>
  <cx-menu-item menu-type="result" (click)="logout()">
    <cx-icon filled="{true}" icon="logout"></cx-icon>
    <span>{{ 'LogOut' | translate }}</span>
  </cx-menu-item>
</cx-menu>
