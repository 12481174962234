<h2 class="header-title">
  <cw-icon
    class="icon mobile-drawer-toggle"
    [icon]="headerService.toggleMobileMenu() ? 'close' : 'menu'"
    size="md"
    (click)="openMobileMenuClicked()"
  ></cw-icon>

  <cw-icon
    *ngIf="headerService?.header()?.showBackBtn"
    class="icon"
    [attr.data-vef]="'header-back-button'"
    routerLink="/home"
    icon="arrow_back"
    size="md"
  ></cw-icon>
  {{ headerService?.header()?.titleKey || ('AppTitle' | translate) }}
</h2>
<gplus-search-bar
  [attr.data-vef]="'topBar-filters-search'"
  *ngIf="headerService?.header()?.showSearch"
  class="header-search"
></gplus-search-bar>

<div class="left-actions">
  <cw-icon
    *ngIf="showConfiguration()"
    class="header-icon"
    icon="settings"
    size="sm"
    routerLink="/configuration"
    routerLinkActive="active"
  >
  </cw-icon>

  <div [attr.data-vef]="'header-notifications-button'" class="alarm" (click)="alarmClicked()">
    <cw-icon class="header-icon" icon="notifications" size="sm"> </cw-icon>
    <cw-badge-notification
      *ngIf="store.count()"
      class="badge-notification"
      [ngClass]="[animateBadge, store.criticality()]"
      size="lg"
      >{{ store.count() }}</cw-badge-notification
    >
  </div>

  <gplus-user class="header-user"></gplus-user>
</div>
