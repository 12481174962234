import { DEFAULT_COLUMNS } from '../constants/defaultColumns';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { TherapyJobExtended } from '../models/TherapyJob';
import { Column } from '../models/ui/Column';
import { ProgressPipe } from '../pipes/Progress';
import { NotificationsStore } from '../store/notification.store';

const progressPipe = new ProgressPipe();

const compare = (a: TherapyJobExtended, b: TherapyJobExtended, getLevel: (id: string) => number) => {
  const aLevel = getLevel(a.id) || Number.MAX_SAFE_INTEGER;
  const bLevel = getLevel(b.id) || Number.MAX_SAFE_INTEGER;

  switch (a.status) {
    case TherapyJobStatus.PRESCRIBED:
      if (a.startTime.planned !== b.startTime.planned) {
        return a.startTime.planned - b.startTime.planned;
      } else {
        return a.patient.lastName.localeCompare(b.patient.lastName);
      }
    case TherapyJobStatus.RUNNING:
      if (aLevel !== bLevel) {
        return aLevel - bLevel;
      } else if (a?.latestEvent?.injectedVolume !== b?.latestEvent?.injectedVolume) {
        return (
          progressPipe.transform(b?.latestEvent?.injectedVolume, b?.totalVolume) -
          progressPipe.transform(a?.latestEvent?.injectedVolume, a?.totalVolume)
        );
      } else {
        return a.patient.lastName.localeCompare(b.patient.lastName);
      }

    case TherapyJobStatus.COMPLETED:
    case TherapyJobStatus.COMPLETE_CONFIRMED:
    case TherapyJobStatus.ABORTION_TRIGGERED:
    case TherapyJobStatus.ABORTED:
      if (aLevel !== bLevel) {
        return aLevel - bLevel;
      }

      if (a.endTime !== b.endTime) {
        return b.endTime - a.endTime;
      }

      return a.patient.lastName.localeCompare(b.patient.lastName);

    default:
      return a.patient.lastName.localeCompare(b.patient.lastName);
  }
};

export const getColumns: (
  therapyJobs: Array<TherapyJobExtended>,
  notificationStore: NotificationsStore
) => Array<Column> = (therapyJobs, notificationStore) => {
  return DEFAULT_COLUMNS.map((statuses: Array<TherapyJobStatus>) => ({
    id: statuses[0],
    therapyJobs:
      therapyJobs
        ?.filter((tj) => statuses.indexOf(tj.status) !== -1)
        .toSorted((a, b) => {
          return compare(a, b, notificationStore.getNotificationLevelById);
        }) || []
  }));
};
