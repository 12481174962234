<div class="amplifier">
  <!-- Intentionally empty -->
</div>

<cx-icon class="cx-icon" [icon]="icon" [filled]="true" size="sm"></cx-icon>
<div class="wrapper">
  <div class="snack-title">{{ title }}</div>
  <div class="snack-badge">
    <ng-content select="[badge]"></ng-content>
  </div>
  <div class="snack-text">
    {{ text }}
  </div>
  <ng-content select="[info]"></ng-content>
  <div class="snack-timestamp">
    {{ timestamp }}
  </div>
</div>
