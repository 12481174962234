import { CommonModule } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CwButton, CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, debounceTime, map, of, take } from 'rxjs';
import { EmployeeType } from '~/app/enums/EmployeeType';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { CarrierService } from '~/app/services/carrier.service';
import { EmployeeService } from '~/app/services/employee.service';
import { MedicineService } from '~/app/services/medicine.service';
import { PatientService } from '~/app/services/patient.service';
import { SessionService } from '~/app/services/session.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { CheckboxExtendedComponent } from '~/app/utils/CheckboxExtended';
import { groupGenerator } from '~/app/utils/FormGroupGenerator';
import { countFiltersApplied } from '~/app/utils/filtersApplied';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { FilterConfig, FilterControl, filtersConfig } from './filters-config';

@Component({
  selector: 'gplus-filters',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckboxExtendedComponent,
    CwButton,
    CwIcon,
    TranslateModule,
    SearchBarComponent,
    DropdownComponent,
    DatepickerComponent
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
  providers: [PersonNamePipe]
})
export class FiltersComponent {
  public filterControl = FilterControl;
  public filters: Array<FilterConfig> = filtersConfig.filter((f) => f.show);
  public toggleFilter: boolean = false;

  public dataLoader: boolean = true;
  public form: FormGroup;
  private isFilterActive: boolean;
  public jobsStore = inject(TherapyJobStore);
  public dataMap: Record<string, unknown>;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const topParent = target.parentElement;

    // if the parent is not form, then we close the filters panel
    if (this.toggleFilter && !topParent?.classList.contains('form')) {
      this.toggleFilter = false;
    }
  }

  constructor(
    private readonly sessionService: SessionService,
    private readonly patientService: PatientService,
    public readonly carrierService: CarrierService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService
  ) {
    this.form = groupGenerator(this.filters);

    // We subscribe to the changes in the filters
    this.form.valueChanges.pipe(takeUntilDestroyed(), debounceTime(300)).subscribe((change) => {
      this.isFilterActive = !!countFiltersApplied(change);

      let newQueries = [];

      if (this.isFilterActive) {
        newQueries = Object.keys(change).reduce((acc, key) => {
          const filterConfig = this.filters.find((f) => f.id === key);

          if (change[key] === null) {
            return acc;
          }

          const bindValueKey = filterConfig.bindValue;

          if (!bindValueKey || typeof change[key] === 'string' || typeof change[key] === 'number') {
            return acc.concat([
              {
                on: key,
                bindValue: change[key],
                value: change[key]
              }
            ]);
          }

          let bindValue;
          if (Array.isArray(change[key])) {
            bindValue = change[key]?.map((d) => d[filterConfig.bindValue]);
          } else {
            bindValue = change[key][filterConfig.bindValue];
          }

          return acc.concat([
            {
              on: key,
              bindValue,
              value: change[key]
            }
          ]);
        }, []);
      }

      const searchQuery = this.jobsStore.filter().queries.find((q) => q.on === 'search');
      this.jobsStore.updateFilter({
        queries: searchQuery ? newQueries.concat(searchQuery) : newQueries
      });
    });

    combineLatest([
      this.employeeService.getEmployees(EmployeeType.PHYSICIAN),
      this.patientService.getPatients(this.sessionService.currentWard),
      this.medicineService.getMedicines(this.sessionService.currentWard),
      this.carrierService.getCarriers(this.sessionService.currentWard)
    ])
      .pipe(
        take(1),
        map(([physician, patient, medicine, carrier]) => {
          return {
            physician,
            patient,
            medicine,
            carrier,
            room: Array.from(new Set(patient.map((p) => p.careUnit.room))).sort((a, b) => a.localeCompare(b))
          };
        })
      )
      .subscribe((dataMap) => {
        this.dataMap = dataMap;

        this.dataLoader = false;
        const filterValuesMap = this.jobsStore.filter().queries.reduce((map, f) => {
          map[f.on] = f.value;
          return map;
        }, {});

        // Patch values if there were any filters active
        const valuesPatched: { [filterId: string]: any } = this.filters.reduce((map, filter) => {
          if (filterValuesMap[filter.id] !== undefined) {
            map[filter.id] = filterValuesMap[filter.id];
          }
          return map;
        }, {});

        this.form.patchValue(valuesPatched);

        this.filters.forEach((filter) => {
          // Filter and subscribing to changes for autocomplete
          if (filter.control === FilterControl.SELECTOR) {
            filter.filteredOptions = of(dataMap[filter.id]);
          }

          if (valuesPatched[filter.id]) {
            this.form.get(filter.id).markAsDirty();
          }
        });
      });
  }

  public toggleFilters() {
    this.toggleFilter = !this.toggleFilter;
  }

  public resetAllFilters() {
    this.form.reset();
    this.jobsStore.updateFilter({ queries: [] });
  }

  public removeCheckbox(control: AbstractControl, event: MouseEvent) {
    event.stopPropagation();
    control.reset();
    control.markAsPristine();
  }

  public compareWith(option, value) {
    if (typeof option === 'string') {
      return option === value;
    }
    return option.id === value?.id;
  }
}
