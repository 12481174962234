import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'personName'
})
export class PersonNamePipe implements PipeTransform {
  transform(person: { lastName: string; firstName: string }): string | null {
    if (!person?.lastName) {
      return null;
    }

    return `${person.lastName}, ${person.firstName}`;
  }
}
