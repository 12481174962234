import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CxBadgeNotification, CxIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderService } from '~/app/services/header.service';
import { SidebarIds, SidebarService } from '~/app/services/sidebar.service';
import { NotificationsStore } from '~/app/store/notification.store';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { UserComponent } from '../user/user.component';

@Component({
  selector: 'gplus-header',
  standalone: true,
  imports: [
    SearchBarComponent,
    UserComponent,
    TranslateModule,
    CxIcon,
    CommonModule,
    RouterModule,
    CxBadgeNotification
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  public showSearch: boolean = true;
  public animateBadge = 'animate-badge';
  public numNotification: number;

  public store = inject(NotificationsStore);

  constructor(public readonly headerService: HeaderService, private readonly sidebarService: SidebarService) {}

  public openMobileMenuClicked() {
    this.headerService.setMobileMenuBtn(!this.headerService.toggleMobileMenu());
  }

  public alarmClicked() {
    this.sidebarService.open(SidebarIds.ALARM_CENTER);
  }
}
