<div class="wrapper">
  <ul>
    <li routerLinkActive="active" routerLink="/home">
      <ng-container *ngTemplateOutlet="icon; context: { icon: 'dashboard' }"></ng-container>
      <span>
        {{ 'TherapyJobsDashboard' | translate }}
      </span>
    </li>

    <li routerLinkActive="active" routerLink="/prn">
      <ng-container *ngTemplateOutlet="icon; context: { icon: 'view_list' }"></ng-container>
      <span>
        {{ 'OnDemandPrescriptions' | translate }}
      </span>
    </li>
  </ul>
</div>

<ng-template #icon let-icon="icon">
  <cw-icon class="non-filled" [icon]="icon" [filled]="false"></cw-icon>
  <cw-icon class="filled" [icon]="icon" [filled]="true"></cw-icon>
</ng-template>
