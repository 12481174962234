import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';
import { map, take } from 'rxjs';
import { User } from '../models/User';

@Injectable({ providedIn: 'root' })
export class UserGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate() {
    return this.userService.getMe().pipe(
      take(1),
      map((me: User) => {
        return !!me;
      })
    );
  }
}
