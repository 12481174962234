import { Component, HostBinding, Input } from '@angular/core';
import { CwIcon } from '@bbraun/cortex-angular';
import { iconsMap } from '~/app/constants/notificationIconsMap';
import { NotificationLevel } from '~/app/enums/NotificationLevel';

@Component({
  selector: 'gplus-inline-banner',
  standalone: true,
  imports: [CwIcon],
  templateUrl: './inline-banner.component.html',
  styleUrl: './inline-banner.component.scss'
})
export class InlineBannerComponent {
  @Input() label: string;
  @Input() status: NotificationLevel;
  @Input() noBackground = false;

  public iconMap: { [key: string]: string } = iconsMap;
  @HostBinding('class') get hostClass(): string {
    return this.status;
  }

  @HostBinding('class.no-background') get noBackgroundClass(): boolean {
    return this.noBackground;
  }
}
