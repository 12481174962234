import { Component } from '@angular/core';

@Component({
  selector: 'gplus-icon-device',
  standalone: true,
  imports: [],
  templateUrl: './icon-device.component.html',
  styleUrl: './icon-device.component.scss'
})
export class IconDeviceComponent {}
