import { Component, HostBinding, Input } from '@angular/core';

import { HEART_BEAT_BASE64 } from './animation.base64';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'gplus-heart-beat',
  templateUrl: './heart-beat.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class HeartBeatComponent {
  @HostBinding() class: string;

  @Input() loading: boolean;
  @Input() width = '50%';

  public HEART_BEAT_BASE64 = HEART_BEAT_BASE64;
}
