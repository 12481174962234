@if(form){

<h3 class="page-sidebar-title full-group">
  {{ 'FormTJCreationSidebarTitle' | translate }}
</h3>

@if(therapyJob?.status === therapyStatus.PRESCRIBED){
<section class="page-sidebar-section first-group" [formGroup]="form">
  <cw-checkbox-extended formControlName="prepared">{{ 'DetailPagePrepared' | translate }}</cw-checkbox-extended>
</section>
}

<!-- Progress bar -->
@if (therapyJob?.status === therapyStatus.RUNNING) {
<section class="page-sidebar-section first-group">
  <div class="progress-bar-wrapper">
    <div class="progress-bar-eyebrow">
      <div *ngIf="therapyJob?.totalVolume">
        <b>{{
          therapyJob?.latestEvent?.injectedVolume
            ? therapyJob?.latestEvent?.injectedVolume + ' ' + ('UnitMl' | translate)
            : '-'
        }}</b>
        <span> / </span>
        <span>
          {{ therapyJob?.totalVolume ? therapyJob?.totalVolume + ' ' + ('UnitMl' | translate) : '-' }}
        </span>
      </div>
      <div>
        <cw-icon icon="speed" [filled]="false" size="sm"></cw-icon>

        <b>{{
          therapyJob?.latestEvent?.currentFlowRate
            ? therapyJob?.latestEvent?.currentFlowRate + ' ' + ('UnitMlHr' | translate)
            : '-'
        }}</b>
      </div>
    </div>

    @if(!tjNotificationMap?.[notificationType.DRIP_CHAMBER_DETACHED + '-' + therapyJob?.id] &&
    !tjNotificationMap?.[notificationType.THERAPY_JOB_UNPAIRED_FROM_MD + '-' + therapyJob?.id]){

    <gplus-infusion size="lg" [level]="mostCriticalStatus" [therapyJob]="therapyJob"></gplus-infusion>
    }

    <div class="progress-bar-sub b2">
      <div>
        <b>Started:</b>
        <span>
          {{ therapyJob?.startTime?.real ? (therapyJob.startTime.real | date : ' h:mm a') : '--:--' }}
        </span>
      </div>
      <div>
        <cw-icon icon="schedule" [filled]="false" size="xs"></cw-icon>

        <span>
          {{
            therapyJob?.latestEvent?.remainingTime
              ? (therapyJob?.latestEvent?.remainingTime | durationFormat : timeUnit.MINUTES)
              : '-'
          }}
        </span>
      </div>
    </div>
  </div>
</section>
}

<section class="page-sidebar-section second-group" [formGroup]="form">
  <h5 class="page-sidebar-section-title">
    {{ 'FormTJCreationPlanningSection' | translate }}
  </h5>

  <div *ngIf="timeError" class="error-value" [attr.data-vef]="'detailPage-delayedTime-label'">
    <cw-icon icon="warning" [filled]="false" size="sm"></cw-icon> {{ timeError }}
  </div>

  <gplus-autocomplete
    [filteredBy]="['lastName', 'firstName']"
    attrVef="form-create-manual-physician"
    [attrVefItem]="'form-create-manual-dropdown-item'"
    labelType="personName"
    [data]="data?.physicians"
    [control]="form.controls?.['physician']"
    [label]="'FormTJCreationPhysician' | translate"
    [readonly]="form.controls?.['physician']?.status === 'DISABLED'"
    [required]="true"
    [placeholder]="'FormTJCreationPhysicianPlaceholder' | translate"
    [validations]="{
      required: 'FormTJCreationPhysicianIsRequired' | translate,
      physicianNotExist: 'FormTJCreationPleaseSelectOneOfTheList' | translate
    }"
  ></gplus-autocomplete>

  <gplus-datepicker
    mode="single"
    attrVef="form-create-manual-planned-date"
    [control]="form.controls?.['startDate']"
    [label]="'FormTJCreationStartDate' | translate"
    [readonly]="form.controls['startDate'].status === 'DISABLED'"
    [required]="true"
    [placeholder]="'FormTJCreationStartDatePlaceholder' | translate"
    [validations]="{
      required: 'FormTJCreationDateIsRequired' | translate,
    }"
  ></gplus-datepicker>

  <gplus-input
    type="time"
    attrVef="form-create-manual-planned-time"
    [control]="form.controls['hours']"
    [label]="'FormTJCreationStartTime' | translate"
    [readonly]="form.controls['hours']?.status === 'DISABLED'"
    [required]="true"
    [placeholder]="'FormTJCreationStartTimePlaceholder' | translate"
    [validations]="{
      required: 'FormTJCreationStartTimeIsRequired' | translate
    }"
  ></gplus-input>
</section>

<section *ngIf="therapyJob" class="page-sidebar-section third-group">
  <h5 class="page-sidebar-section-title">
    {{ 'DetailPageDeviceSectionTitle' | translate }}
  </h5>

  <gplus-manage-device
    *ngIf="therapyJob"
    class="connect-selector"
    [event]="event"
    [therapyJob]="therapyJob"
    (click)="$event.stopPropagation()"
  ></gplus-manage-device>

  <div class="gplus-list" *ngIf="therapyJob">
    <div class="item" *ngIf="therapyJob?.pairingState === pairingStatus.PAIRED">
      <div class="label">{{ 'DetailPageDeviceSectionDeviceId' | translate }}</div>
      <div class="value">{{ therapyJob?.monitorDeviceId }}</div>
    </div>
    <div class="item">
      <div class="label">{{ 'DetailPageDeviceSectionStatus' | translate }}</div>
      <div class="value">
        @switch (therapyJob?.pairingState ) {
        <!--  -->
        @case (pairingStatus.UN_PAIRED) {
        <cw-badge-status type="secondary">
          {{ 'PairingStatusUN_PAIRED' | translate }}
        </cw-badge-status>
        }
        <!--  -->
        @case (pairingStatus.IN_PAIRING) {
        <cw-badge-status type="secondary" status="warning">
          {{ 'PairingStatusIN_PAIRING' | translate }}
        </cw-badge-status>
        }
        <!--  -->
        @case (pairingStatus.PAIRED) {

        <cw-badge-status type="secondary" status="success">
          {{ 'PairingStatusPAIRED' | translate }}
        </cw-badge-status>
        }
        <!--  -->
        @case(undefined) {
        <cw-badge-status type="secondary">
          {{ 'PairingStatusUN_PAIRED' | translate }}
        </cw-badge-status>
        } }
      </div>
    </div>
  </div>
</section>
}
