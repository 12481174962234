import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';
import { fadeIn } from '~/app/animations/enterLeave';
import { TherapyJobStore } from '~/app/store/job.store';

@Component({
  selector: 'gplus-search-bar',
  standalone: true,
  imports: [CommonModule, CwIcon, TranslateModule, FormsModule],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  animations: [fadeIn]
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
  public jobsStore = inject(TherapyJobStore);

  private destroy: Subject<void> = new Subject();
  private debounce: Subject<void> = new Subject();
  public value: string;

  @ViewChild('inputSearch') inputSearch: ElementRef;
  @HostBinding('@fadeIn') animate = true;
  @HostListener('document:keydown.escape', ['$event'])
  onEscape() {
    this.renderer2.removeClass(this.elm.nativeElement, 'focus');
    this.value = null;
    this.search();
  }

  constructor(private readonly renderer2: Renderer2, private readonly elm: ElementRef) {
    effect(() => {
      this.value = this.jobsStore.filter()?.queries?.find((q) => q.on === 'search')?.bindValue;
    });
  }

  ngOnInit(): void {
    this.debounce.pipe(takeUntil(this.destroy), debounceTime(500)).subscribe(() => {
      this.setFilters(this.value);
    });
  }

  ngAfterViewInit(): void {
    this.listenToFocus();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private setFilters(value: string | null) {
    const prevFilter = this.jobsStore.filter();
    let noSearchQueries = prevFilter.queries.filter((q) => q.on !== 'search');

    if (value) {
      this.jobsStore.updateFilter({
        ...prevFilter,
        queries: noSearchQueries.concat({
          on: 'search',
          bindValue: value.toLowerCase(),
          value: value.toLowerCase()
        })
      });
    } else {
      this.jobsStore.updateFilter({
        ...prevFilter,
        queries: noSearchQueries
      });
    }
  }

  private listenToFocus() {
    const inputElm = this.inputSearch?.nativeElement;
    if (!inputElm) {
      return;
    }
    fromEvent(inputElm, 'focus')
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.renderer2.addClass(this.elm.nativeElement, 'focus');
      });
    fromEvent(inputElm, 'blur')
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.renderer2.removeClass(this.elm.nativeElement, 'focus');
      });
  }

  public search() {
    this.debounce.next();
  }

  public clean() {
    this.value = null;
    this.debounce.next();
  }
}
