export const countFiltersApplied: (obj: Record<string, unknown>) => number = (obj) => {
  return Object.keys(obj).reduce((count, key) => {
    const hasChildren = obj[key] ? Object.keys(obj[key]).length : null;
    let allPropsAreFalse = false;
    if (hasChildren) {
      allPropsAreFalse = Object.keys(obj[key]).every((childKey) => {
        return !obj[key][childKey];
      });
    }

    if (obj[key] !== null && !allPropsAreFalse) {
      count++;
    }

    return count;
  }, 0);
};
