import { AfterViewInit, DestroyRef, Directive, ElementRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { ValueAccessor } from './ValueAccessor';

@Directive({
  selector: 'cw-textarea',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextAreaValueAccessor,
      multi: true
    }
  ],
  standalone: true
})
export class TextAreaValueAccessor extends ValueAccessor implements AfterViewInit {
  destroyRef = inject(DestroyRef);
  constructor(protected override el: ElementRef) {
    super(el);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.el?.nativeElement?.shadowRoot) {
        fromEvent(this.el.nativeElement.shadowRoot.querySelector('textarea'), 'input')
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((event: any) => {
            this.handleChangeEvent(event.target.value);
          });
      }
    }, 0);
  }
}
