@for(item of items; track item?.vefId){

<!-- With tooltip -->
@if(item?.tooltip){
<cw-tooltip *ngIf="item.visible" [content]="item.tooltip | translate">
  <div class="item">
    <div [class.disabled]="item.disabled">
      <div class="inner" (click)="item.onClick()" [attr.data-vef]="item.vefId">
        <cw-icon [icon]="item.icon" filled="false"></cw-icon>
        {{ item.label }}
      </div>
    </div>
  </div>
</cw-tooltip>

} @else{

<div *ngIf="item.visible" class="item" [class.disabled]="item.disabled">
  <div class="inner" (click)="item.onClick()" [attr.data-vef]="item.vefId">
    <cw-icon [icon]="item.icon" filled="false"></cw-icon>
    {{ item.label }}
  </div>
</div>

} }
