import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CwBadgeStatus, CwIcon } from '@bbraun/cortex-angular';
import { EntityMap } from '@ngrx/signals/entities';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationType } from '~/app/enums/NotificationId';
import { NotificationLevel } from '~/app/enums/NotificationLevel';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { Employee } from '~/app/models/generated/employee';
import { SessionData } from '~/app/models/SessionData';
import { SsePairing } from '~/app/models/sse-event';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobForm } from '~/app/models/TherapyJobForm';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { DurationFormatPipe, TimeUnit } from '~/app/pipes/DurationFormat';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { setTimeError } from '~/app/utils/DelayTime';
import { getCriticalType } from '~/app/utils/getCriticalNotificationType';
import { change } from '~/app/utils/valueChanges';
import { CheckboxExtendedComponent } from '../../../utils/CheckboxExtended';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { DatepickerComponent } from '../../datepicker/datepicker.component';
import { InfusionComponent } from '../../infusion/infusion.component';
import { InputComponent } from '../../input/input.component';
import { ManageDeviceComponent } from '../../manage-device/manage-device.component';
import { DetailPageStatus } from '../detail-page.component';

@Component({
  selector: 'gplus-detail-page-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    CwIcon,
    CheckboxExtendedComponent,
    ManageDeviceComponent,
    CwBadgeStatus,
    DurationFormatPipe,
    InfusionComponent,
    AutocompleteComponent,
    DatepickerComponent,
    InputComponent
  ],
  templateUrl: './detail-page-sidebar.component.html',
  styleUrl: './detail-page-sidebar.component.scss',
  providers: [TranslatePipe, DurationFormatPipe, PersonNamePipe]
})
export class DetailPageSidebarComponent implements OnInit, OnChanges {
  // Public enums
  public detailPageStatus = DetailPageStatus;
  public therapyStatus = TherapyJobStatus;
  public pairingStatus = PairingStatus;
  public timeUnit = TimeUnit;
  public notificationType = NotificationType;

  @Input() pageStatus: DetailPageStatus;

  // Loaders
  public preparedLoader: boolean;

  public filteredPhysician: Observable<Array<Employee>>;

  public prepared: boolean;

  public timeError: string;

  @Input() form: FormGroup<TherapyJobForm>;
  @Input() therapyJob: TherapyJob;
  @Input() data: SessionData;
  @Input() ticker: number;
  @Input() tjNotificationMap: EntityMap<TherapyJobNotification>;
  @Input() event: SsePairing;

  public mostCriticalStatus: NotificationLevel;
  private destroyRef = inject(DestroyRef);

  constructor(private readonly translatePipe: TranslatePipe, private readonly durationPipe: DurationFormatPipe) {}

  ngOnInit(): void {
    if (this.data) {
      this.filteredPhysician = change(this.form.get('physician'), this.data.physicians, 'lastName');
    }

    if (this.form) {
      this.form
        .get('prepared')
        .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          this.prepared = res;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticker) {
      this.setTimeError(this.therapyJob);
    }

    if (changes.tjNotificationMap) {
      this.setNotificationStatus();
    }
  }

  public displayPersonName(person: { lastName: string; firstName: string }): string {
    if (!person) {
      return '';
    }

    const personNamePipe = new PersonNamePipe();
    return personNamePipe.transform(person);
  }

  private setTimeError(therapyJob: TherapyJob): void {
    this.timeError = setTimeError(therapyJob.startTime.planned, this.durationPipe, this.translatePipe);
  }

  private setNotificationStatus() {
    // If one of the notification is an error we keep it as error
    this.mostCriticalStatus = getCriticalType(this.tjNotificationMap);
  }
}
