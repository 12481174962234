import { FormControl, FormGroup } from '@angular/forms';

/**
 * Recursively processes an object, applying provided functions to transform each value and key.
 *
 * @param {Object} obj - The object to be processed.
 * @param {Function} transformValue - A function that takes a value, its key, and its path, and returns a new value.
 * @param {Function} [transformKey=(key) => key] - A function that takes a key and its path, and returns a new key. Defaults to an identity function.
 * @param {string} [ancestorsKey=''] - The path of the current property, used internally for recursion.
 * @returns {Object} A new object with the same structure as the input object, but with keys and values transformed by the provided functions.
 *
 * @example
 * const data = {
 *   a: {
 *     b: 'value1',
 *     c: 'value2'
 *   },
 *   d: {
 *     e: 'value3'
 *   }
 * };
 *
 * const transformedData = recurseObject(
 *   data,
 *   (value, key, path) => `${path}: ${value}`,
 *   (key, path) => `transformed_${key}`
 * );
 * console.log(transformedData);
 * // Output:
 * // {
 * //   transformed_a: {
 * //     transformed_b: 'a.b: value1',
 * //     transformed_c: 'a.c: value2'
 * //   },
 * //   transformed_d: {
 * //     transformed_e: 'd.e: value3'
 * //   }
 * // }
 */
export const recurseObject = (
  obj,
  transformValue: (val: any, key: string, path: string) => any,
  transformKey: (key, path) => string = (key) => key,
  ancestorsKey = ''
) => {
  return Object.keys(obj).reduce((acc, key) => {
    const currentKey = ancestorsKey ? `${ancestorsKey}.${key}` : key;
    const transformedKey = transformKey(key, currentKey);

    if (
      typeof obj[key] === 'object' &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null &&
      // Objects with ID are values and we do not want to recurse further
      !obj[key].hasOwnProperty('id')
    ) {
      const nestedObj = recurseObject(obj[key], transformValue, transformKey, currentKey);
      acc[transformedKey] = { ...nestedObj };
    } else {
      acc[transformedKey] = transformValue(obj[key], key, currentKey);
    }
    return acc;
  }, {});
};

export const formFromObject = (obj, getValidator: (value: any, path: string) => any, ancestorsKey = '') => {
  return Object.keys(obj).reduce((form, key) => {
    const currentKey = ancestorsKey ? `${ancestorsKey}.${key}` : key;

    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      form.addControl(key, formFromObject(obj[key], getValidator, currentKey));
    } else {
      form.addControl(key, new FormControl(null, getValidator(obj[key], currentKey)));
    }

    return form;
  }, new FormGroup({}));
};
