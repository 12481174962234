import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { CxButton, CxIcon } from '@bbraun/cortex-angular';
import { computePosition, flip, offset, shift } from '@floating-ui/dom';

export interface MenuItem {
  // UI verification ID
  vefId?: string;
  label: string;
  icon: string;
  disabled: boolean;
  onClick: () => void;
}

@Component({
  selector: 'gplus-menu',
  standalone: true,
  imports: [CxButton, CxIcon, CommonModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  @HostBinding('style.transform') transform: string;

  @Input() items: Array<MenuItem>;
  @Input() set triggerElm(value: Element) {
    computePosition(value, this.elm.nativeElement, {
      placement: 'bottom-end',
      middleware: [flip(), shift(), offset(4)]
    }).then(({ x, y }) => {
      this.transform = `translate(${x}px, ${y}px)`;
    });
  }

  constructor(private readonly elm: ElementRef) {}
}
