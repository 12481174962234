import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CwButton, CwList } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { iif, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { CardFields } from '~/app/enums/CardFields';
import { ConfigControl, ConfigUiControl, Configuration, ConfigurationControl } from '~/app/models/Configuration';
import { Unit } from '~/app/models/User';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { ConfigService } from '~/app/services/config.service';
import { DialogIds, DialogService } from '~/app/services/dialog.service';
import { HeaderService } from '~/app/services/header.service';
import { UserService } from '~/app/services/user.service';
import { capitalize } from '~/app/utils/Capitalize';
import { formFromObject, recurseObject } from '~/app/utils/recurseObject';
import { filtersConfig } from '../filters/filters-config';
import { ConfigurationControlsComponent } from './configuration-controls/configuration-controls.component';
import { configurationCustomization, ControlsCustomization } from './configurationCustomization';

interface Tab {
  id: string;
  label: string;
}

@Component({
  selector: 'gplus-configuration',
  standalone: true,
  imports: [CommonModule, CwList, TranslateModule, CwButton, ConfigurationControlsComponent],
  providers: [PersonNamePipe],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss'
})
export class ConfigurationComponent {
  public tabs: Array<Tab>;
  public activeTab: Tab;
  public configUiControl = ConfigUiControl;

  public form: FormGroup;

  private controlOptionsMap: { [key: string]: Array<{ id: string; label: string }> };

  private rawConfiguration: Configuration;
  public isDefault: boolean;

  public uiControlObject: ConfigurationControl;

  public loading: boolean = true;

  public controlsCustomization: ControlsCustomization = configurationCustomization;

  constructor(
    private readonly configService: ConfigService,
    private readonly translate: TranslateService,
    private readonly headerService: HeaderService,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly userService: UserService
  ) {
    this.setHeader();

    this.controlOptionsMap = {
      ['appearanceConfiguration.filterOptions']: filtersConfig.map((filter) => ({
        id: filter.id,
        label: this.translate.instant(filter.label)
      })),
      ['appearanceConfiguration.cardDetails']: [
        { id: CardFields.MEDICINE, label: this.translate.instant('ConfigPageCardMedicine') },
        { id: CardFields.FLOW_RATE, label: this.translate.instant('ConfigPageCardFlowRate') },
        { id: CardFields.MONITOR_DEVICE_ID, label: this.translate.instant('ConfigPageCardDevice') },
        { id: CardFields.PHYSICIAN, label: this.translate.instant('ConfigPageCardPhysician') },
        { id: CardFields.VOLUME, label: this.translate.instant('ConfigPageCardVolume') },
        { id: CardFields.TIME, label: this.translate.instant('ConfigPageCardTime') }
      ],
      ['monitorDeviceConfiguration.language']: [
        { id: 'en', label: this.translate.instant('ConfigPageLanguageEnglish') },
        { id: 'de', label: this.translate.instant('ConfigPageLanguageGerman') }
      ]
    };

    toObservable(this.configService.configuration)
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        if (!res) {
          this.loading = false;
          return;
        }

        this.rawConfiguration = res.content;
        this.isDefault = res.isDefault;

        this.tabs = this.createTabs(res.content);
        this.activeTab = this.tabs[0];
        this.uiControlObject = this.createUIControls(res.content);

        this.form = formFromObject(res.content, (_, path) => this.getValidations(path));
        this.patchForm(res.content);
        this.form.markAsPristine();

        this.loading = false;
      });
  }

  public selectTab(tab: Tab) {
    this.activeTab = tab;
  }

  public resetForm() {
    this.patchForm(this.rawConfiguration);
    this.form.markAsPristine();
  }

  public saveChanges() {
    this.loading = true;
    this.configService
      .putConfig(this.getRequestValue())
      .pipe(take(1))
      .subscribe(({ error }) => {
        if (error) {
          this.loading = false;
        } else {
          this.form.markAsPristine();
          this.router.navigate(['/home']);
        }
      });
  }

  public restoreDefault() {
    this.dialogService.open(DialogIds.RESTORE_CONFIG_WARN);

    this.dialogService
      .dialog()
      .confirm.pipe(
        tap(() => {
          this.dialogService.close();
          this.loading = true;
        }),
        switchMap((value: boolean) =>
          iif(
            () => value,
            // If the user confirms
            this.configService.resetConfig(),
            // if the user cancels
            of(null)
          )
        )
      )
      .pipe(take(1))
      .subscribe(() => {
        this.loading = false;
      });
  }

  private createUIControls(config: Configuration): ConfigurationControl {
    return recurseObject(
      config,
      (value, key, path): ConfigControl => {
        const options: Array<{ id: string; label: string }> = this.getControlsOptions(value, path);
        const controlType: ConfigUiControl = this.getControlType(path, value);

        return {
          id: key,
          path: path,
          type: controlType,
          options,
          order: this.controlsCustomization[path]?.order
        };
      },
      // Fixes the translation key that uses the name of property in the config option
      (_, path) =>
        `ConfigPage${path
          .split('.')
          .map((s) => capitalize(s))
          .join('')}`
    ) as ConfigurationControl;
  }

  private createTabs(config: Configuration): Array<Tab> {
    return Object.keys(config).map((key) => {
      return {
        id: `ConfigPage${capitalize(key)}`,
        label: this.translate.instant(`ConfigPage${capitalize(key)}`)
      };
    });
  }

  private patchForm(config) {
    const patch = recurseObject(config, (value, _, path) => {
      const controlOptions = this.getControlsOptions(value, path);
      const controlType = this.getControlType(path, value);

      if (
        controlOptions &&
        (controlType === ConfigUiControl.DRAG_DROP_LIST || controlType === ConfigUiControl.MULTI_SELECT)
      ) {
        return value?.map((item) => ({
          id: item,
          label: controlOptions?.find((o) => o.id === item)?.label
        }));
      } else if (controlOptions && controlType === ConfigUiControl.SELECT) {
        return {
          id: value,
          label: controlOptions?.find((o) => o.id === value)?.label
        };
      }

      return value;
    });

    this.form.patchValue(patch);
  }

  private getControlType(path, value: any): ConfigUiControl {
    if (this.controlsCustomization[path]?.control) {
      return this.controlsCustomization[path]?.control;
    } else {
      switch (typeof value) {
        case 'boolean':
          return ConfigUiControl.TOGGLE;
        case 'number':
          return ConfigUiControl.NUMBER;
        case 'object':
          if (Array.isArray(value)) {
            return ConfigUiControl.MULTI_SELECT;
          }
          return ConfigUiControl.SELECT;

        case 'string':
          return ConfigUiControl.SELECT;

        default:
          return ConfigUiControl.TEXT;
      }
    }
  }

  private getControlsOptions(value, path) {
    const toSort =
      this.controlOptionsMap[path] || (Array.isArray(value) ? value.map((v) => ({ id: v, label: v })) : null);

    if (Array.isArray(value)) {
      // if is an array we sort
      // Create a map to store the index of each item in sortReference
      const sortIndex = new Map(value.map((item, index) => [item, index]));

      // Sort toSort based on the index in sortReference
      return toSort.sort((a, b) => {
        return (sortIndex.get(a.id) ?? value.length) - (sortIndex.get(b.id) ?? value.length);
      });
    }

    return toSort;
  }

  private getValidations(path: string): Validators {
    if (this.controlsCustomization[path]?.validation) {
      return this.controlsCustomization[path]?.validation;
    }

    return null;
  }

  private getRequestValue(): Configuration {
    const rawValue = this.form.getRawValue();
    const newConfig = recurseObject(rawValue, (value) => {
      if (Array.isArray(value)) {
        return value?.map((item) => item.id);
      } else if (value && value.id) {
        return value.id;
      }

      return value;
    });

    return newConfig as Configuration;
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.form?.dirty) {
      return of(true);
    }

    return new Observable<boolean>((observer) => {
      this.dialogService.open(DialogIds.LEAVE_WITH_UNSAVED);

      this.dialogService
        .dialog()
        .confirm.pipe(take(1))
        .subscribe((value) => {
          this.dialogService.close();

          observer.next(value);
          observer.complete();
        });
    });
  }

  private setHeader() {
    this.userService
      .getMe()
      .pipe(
        take(1),
        switchMap((me) => {
          return this.userService.getContext().pipe(
            take(1),
            map((context) => {
              return me.sites
                ?.find((site) => site.id === context?.siteId)
                ?.units?.find((unit) => unit.id === context?.unitId);
            })
          );
        })
      )
      .subscribe((unit: Unit) => {
        this.headerService.setHeader({
          titleKey: `${this.translate.instant('PageTitleConfigurationPage')} - ${unit?.name}`,
          showBackBtn: false,
          showSearch: false
        });
      });
  }
}
