import { CommonModule } from '@angular/common';
import { Component, effect, HostListener, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  combineLatest,
  debounceTime,
  filter,
  interval,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  tap
} from 'rxjs';
import { TherapyJobFormClass } from '~/app/classes/TherapyJobFormClass';
import { medicineUnits } from '~/app/enums/MedicineUnits';
import { TherapyJobMedicine } from '~/app/models/generated/therapyJobMedicine';
import { SessionData } from '~/app/models/SessionData';
import { SsePairing } from '~/app/models/sse-event';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { TherapyJobFormRaw } from '~/app/models/TherapyJobForm';
import { DurationFormatPipe } from '~/app/pipes/DurationFormat';
import { FilterNotificationPipe } from '~/app/pipes/FilterNotifications';
import { CarrierService } from '~/app/services/carrier.service';
import { DialogIds, DialogService } from '~/app/services/dialog.service';
import { EmployeeService } from '~/app/services/employee.service';
import { HeaderService } from '~/app/services/header.service';
import { MedicineService } from '~/app/services/medicine.service';
import { PatientService } from '~/app/services/patient.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { NotificationsStore } from '~/app/store/notification.store';
import { EventManager } from '~/app/utils/EventManager';
import { getTimeFromDate } from '~/app/utils/getTime';
import { DetailPageBodyComponent } from '../detail-page-body/detail-page-body.component';
import { DetailPageDetailsComponent } from '../detail-page-details/detail-page-details.component';
import { DetailPageFormActionsComponent } from '../detail-page-form-actions/detail-page-form-actions.component';
import { DetailPageHeaderComponent } from '../detail-page-header/detail-page-header.component';
import { DetailPageSidebarComponent } from '../detail-page-sidebar/detail-page-sidebar.component';

@Component({
  selector: 'gplus-detail-page-view',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DetailPageBodyComponent,
    DetailPageSidebarComponent,
    FilterNotificationPipe,
    DetailPageHeaderComponent,
    DetailPageDetailsComponent,
    DetailPageFormActionsComponent,
    TranslateModule
  ],
  providers: [TranslatePipe, DurationFormatPipe],
  templateUrl: './detail-page-view.component.html',
  styleUrls: ['../common/page.scss']
})
export class DetailPageViewComponent {
  public jobsStore = inject(TherapyJobStore);
  public notificationStore = inject(NotificationsStore);

  private patchSub: Subject<void> = new ReplaySubject();

  public formInstance: TherapyJobFormClass;
  public data: SessionData;
  public therapyJob: TherapyJobExtended;
  public ticker: number;
  public event: SsePairing;

  public loader: boolean;
  private paramId: string;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: Event) {
    if (this.formInstance.form?.dirty) {
      event?.preventDefault();
    }
  }

  constructor(
    private readonly therapyJobService: TherapyJobService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,

    // Public
    public readonly eventManager: EventManager,

    // Data Services
    private readonly patientService: PatientService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService,
    private readonly carrierService: CarrierService
  ) {
    interval(5000)
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        this.ticker = res;
      });

    effect(() => {
      // IMPORTANT: We listen to the effects of the entities signal
      // to get updates of the therapyjob in the pairing process
      // as well as infusion data

      const editedTherapyJob = this.jobsStore.entityMap()?.[this.paramId];
      // If the therapy job did exist before and now it does not, means the TJ was deleted we navigate back to home
      if (this.therapyJob && !editedTherapyJob) {
        console.info('The therapy job was deleted, redirecting to home');
        this.router.navigate(['/home']);
      } else {
        this.therapyJob = editedTherapyJob;
      }
    });

    this.patchSub.pipe(debounceTime(100)).subscribe(() => {
      this.patch();
    });

    const routing$ = this.route.paramMap;
    const entitiesLoaded$ = toObservable(this.jobsStore.entities).pipe(
      filter(() => this.jobsStore.loading() === false)
    );
    const allData$ = combineLatest([
      this.medicineService.getMedicines(),
      this.carrierService.getCarriers(),
      this.patientService.getPatients(),
      this.employeeService.getPhysicians()
    ]);

    this.loader = true;
    entitiesLoaded$
      .pipe(
        take(1),
        switchMap(() =>
          routing$.pipe(
            tap((params) => {
              this.paramId = params.get('id');
              if (!this.jobsStore.entityMap()?.[this.paramId]) {
                console.warn('Therapyjob does not exist, redirecting to home');
                this.router.navigate(['/home']);
              } else {
                this.therapyJob = this.jobsStore.entityMap()?.[this.paramId];
                this.setHeader();
              }
            }),
            switchMap(() => allData$)
          )
        )
      )
      .subscribe(([medicines, carriers, patients, physicians]) => {
        // Session data
        this.data = {
          medicines,
          carriers,
          patients,
          physicians
        };

        // Init and patch the form
        this.initForm(this.therapyJob);
        this.disableFields();
        this.patchSub.next();

        this.loader = false;
      });
  }

  private initForm(therapyJob: TherapyJobExtended) {
    if (!therapyJob) {
      console.warn('Therapyjob does not exist, redirecting to home');
      this.router.navigate(['/home']);
      return;
    }
    this.formInstance = new TherapyJobFormClass(this.patientService, this.medicineService, this.employeeService);
    this.formInstance.addMedicineControls(therapyJob.medication.medicines);
  }

  private setHeader() {
    this.headerService.setHeader({
      titleKey: `${this.translateService.instant('PageTitleTherapyJobPage')} - ${this.therapyJob?.id}`,
      showBackBtn: true,
      showSearch: false
    });
  }

  private disableFields() {
    if (!this.formInstance?.form) {
      console.warn('Form not initialized');
      return;
    }

    this.formInstance.form.get('medication').disable();
    this.formInstance.form.get('patient').disable();
    this.formInstance.form.get('physician').disable();
    this.formInstance.form.get('flowRate').disable();
    this.formInstance.form.get('startDate').disable();
    this.formInstance.form.get('hours').disable();
    this.formInstance.form.get('volume').disable();
  }

  public submitChange() {
    if (this.formInstance?.form?.valid) {
      this.loader = true;

      const payload = {
        prepared: this.formInstance.form.getRawValue().prepared,
        annotations: this.formInstance.form.getRawValue().annotations
      };

      this.therapyJobService.update(payload as Partial<TherapyJobExtended>, this.therapyJob.id).subscribe((res) => {
        if ((res as { error: string })?.error) {
          // something went wrong we reload
          this.jobsStore.loadAll().then(() => this.notificationStore.setAll());
          this.patchSub.next();
        } else {
          this.jobsStore.patchJob(payload as Partial<TherapyJobExtended>, this.therapyJob.id);
          console.info('Therapy Job successfully edited');
          this.formInstance.form.markAsPristine();
        }

        this.loader = false;
      });
    }
  }

  public resetChange() {
    this.patchSub.next();
  }

  private patch() {
    if (!this.therapyJob || !this.formInstance?.form) {
      return;
    }

    const medicines = this.therapyJob.medication.medicines.map((m: TherapyJobMedicine) => {
      return {
        medicine: m,
        customAmount: m.amount,
        unit: {
          id: medicineUnits.find((u) => u.label === m.unit)?.id,
          label: m.unit
        }
      };
    });

    const startDatePlanned = this.therapyJob?.startTime?.planned ? new Date(this.therapyJob.startTime.planned) : null;

    const value: TherapyJobFormRaw = {
      patient: this.therapyJob.patient,
      volume: this.therapyJob.totalVolume,
      medication: {
        ...this.therapyJob.medication,
        carrier: this.therapyJob.medication.carrier,
        medicines
      },
      flowRate: this.therapyJob.flowRate.planned,
      annotations: this.therapyJob.annotations,
      startDate: startDatePlanned,
      hours: startDatePlanned ? getTimeFromDate(startDatePlanned) : null,
      physician: this.therapyJob.physicianObject,
      prepared: this.therapyJob.prepared
    };

    this.formInstance.form.patchValue(value);
    this.formInstance.form.markAsPristine();
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.formInstance?.form?.dirty) {
      return of(true);
    }

    return new Observable<boolean>((observer) => {
      this.dialogService.open(DialogIds.LEAVE_WITH_UNSAVED);

      this.dialogService
        .dialog()
        .confirm.pipe(take(1))
        .subscribe((value) => {
          this.dialogService.close();

          observer.next(value);
          observer.complete();
        });
    });
  }
  public processChange(event: boolean) {
    this.loader = event;
  }
}
