import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, retry, Subject, Subscription, switchMap, takeUntil, timeout } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { NotificationLevel } from '../enums/NotificationLevel';
import { User } from '../models/User';
import { AuthService } from './auth.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class WsEventsService {
  public eventSource: Subject<any>;

  private wsEventsSub: Subject<any> = new Subject();
  private destroy: Subject<void> = new Subject();

  private webSocketSub: WebSocketSubject<any>;

  private firstConnection = true;
  private reconnection = false;

  private readonly aliveSignal: WritableSignal<boolean> = signal(null);
  public alive: Signal<boolean> = this.aliveSignal.asReadonly();

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly authService: AuthService
  ) {}

  public subscribeToWsEvents(): Observable<any> {
    return this.wsEventsSub.asObservable();
  }

  private buildWsUrl(siteId: string, unitId: string) {
    const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    return `${wsProtocol}//${window.location.host}/ws/${siteId}/${unitId}`;
  }

  public connect(me: User, info?: string): void {
    console.info('Connecting to websocket, by', `"${info}" at: `, new Date().toISOString());

    const keepAliveSubject = new Subject<void>();
    let timeoutSubscription = null;

    this.authService
      .getApiAuthToken()
      .pipe(switchMap((tokenResult) => this.connectWs(me, tokenResult?.accessToken)))
      .subscribe({
        next: (event: IWsEventMessage) => {
          switch (event.type) {
            case 'KEEP_ALIVE':
              if (timeoutSubscription?.closed || !timeoutSubscription) {
                timeoutSubscription = this.getTimeoutSubscription(keepAliveSubject);
              } else {
                // clean warnings
                this.aliveSignal.set(true);
                this.toastService.close();
              }

              keepAliveSubject.next();

              return;
            case 'ESTABLISHED':
              this.firstConnection = false;
              this.reconnection = false;
              // clean warnings
              this.aliveSignal.set(true);
              this.toastService.close();

              if (timeoutSubscription?.closed || !timeoutSubscription) {
                timeoutSubscription = this.getTimeoutSubscription(keepAliveSubject);
              }

              keepAliveSubject.next();

              return;
            case 'RECONNECT':
              this.reconnection = true;
              this.reconnectWs(me);
              return;
            case 'error':
              this.showErrorMessage(event.body);
              this.reconnectWs(me);
              return;

            default:
              if (event.body) {
                this.wsEventsSub.next({ ...event.body, type: event.type });
              } else {
                console.warn('No body found in the websocket event', event);
              }
              return;
          }
        },
        error: (err) => {
          // on error the websocket will retry reconnection
          // we just show a message
          this.showErrorMessage(err);
        },
        complete: () => {
          console.info('Websocket connection completed');
        }
      });
  }

  private connectWs(me: User, accessToken: string): Observable<any> {
    this.webSocketSub = webSocket({
      url: this.buildWsUrl(me.lastContext.siteId, me.lastContext.unitId),
      protocol: ['access-token', accessToken],
      openObserver: {
        next: () => {
          if (!this.firstConnection && !this.reconnection) {
            this.showSuccessMessage();
          }

          console.info('Websocket connection established', new Date().toISOString());
        }
      },
      closeObserver: {
        next: () => {
          if (!this.reconnection) {
            this.showErrorMessage({ error: 'Websocket connection closed' });
          }
          this.aliveSignal.set(false);
        }
      }
    });

    return this.webSocketSub.pipe(retry({ count: 10, delay: 1000, resetOnSuccess: true }), takeUntil(this.destroy));
  }

  private reconnectWs(me: User): void {
    this.destroy.next();
    this.connect(me, 'Reconnect');
  }

  private getTimeoutSubscription(keepAliveSubject: Subject<void>): Subscription {
    return keepAliveSubject
      .pipe(
        timeout({
          each: 61_000
        }),
        takeUntil(this.destroy)
      )
      .subscribe({
        error: (err) => {
          console.warn('Web Socket not alive', new Date().toISOString());
          // Warn
          this.aliveSignal.set(false);
          this.showErrorMessage(err, 'ErrorNoResponseFromServer', false);
        }
      });
  }

  private showErrorMessage(error, msm?: string, autoclose: boolean = true) {
    this.toastService.show({
      message: this.translateService.instant(msm || 'ConnectionLostMessage'),
      type: NotificationLevel.ERROR,
      enableCloseButton: true,
      delay: autoclose ? 20_000 : null
    });

    console.error(error);
  }

  private showSuccessMessage() {
    this.toastService.show({
      message: this.translateService.instant('ConnectionRestored'),
      type: NotificationLevel.SUCCESS,
      enableCloseButton: true,
      delay: 5_000
    });
  }

  public close(): void {
    if (this.webSocketSub) {
      console.info('Closing websocket connection');
      this.webSocketSub.unsubscribe();
    }
  }
}

export interface IWsEventMessage {
  type: string;
  body: any;
}
