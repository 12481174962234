<cw-sidebar
  customShadowCss
  styles=".sidebar-content{overflow: auto}"
  [heading]="sidebar?.config?.title | translate"
  [open]="sidebar?.open"
  [secondaryButtonLabel]="sidebar?.config?.secondaryLabel | translate"
  [actionButtonLabel]="null"
>
  @if(sidebar?.open){
  <ng-container *ngComponentOutlet="sidebar?.component"> </ng-container>
  }
</cw-sidebar>
