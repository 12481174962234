import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { Prescription } from '../models/prescription';
import { EmployeeService } from './employee.service';
import { EnvironmentService } from './environment.service';
import { MedicineService } from './medicine.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
  private prescriptions: Array<Prescription>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly employeeService: EmployeeService,
    private readonly medicineService: MedicineService
  ) {}

  public getOnDemandPrescriptions(patientId: string): Observable<Array<Prescription>> {
    return this.httpClient.get<Array<Prescription>>(`${this.env.getBaseRestUrl()}/prescription/type/ondemand`).pipe(
      switchMap((prescriptions) =>
        this.employeeService.getPhysicians().pipe(
          switchMap((employees) => {
            return this.medicineService.getMedicines().pipe(
              map((medicines) => {
                this.prescriptions = prescriptions.filter(
                  (p) =>
                    // We filter out prescriptions with physicians that are not in the Unit
                    employees.find((e) => e.id === p.physician) &&
                    // we also filter out prescriptions without unauthorized medicine
                    p.medication.medicines.every((m) => medicines.find((med) => med.id === m.id)) &&
                    // and we only return by provided patientId
                    p.patient.id === patientId
                );
                return this.prescriptions;
              })
            );
          })
        )
      ),
      catchError((err) => {
        console.warn('Error requesting PRN prescriptions', err);
        this.toastGenericError();

        return of([]);
      })
    );
  }

  private toastGenericError(): void {
    this.toastService.show({
      message: this.translateService.instant('ErrorGeneric'),
      type: NotificationLevel.ERROR,
      enableCloseButton: true,
      delay: 10_000
    });
  }
}
