<cw-input
  [class.disabled]="disabled"
  [class.readonly]="readonly"
  [attr.data-vef]="attrVef"
  [(ngModel)]="inputTextValue"
  [type]="type"
  [status]="error ? 'error' : null"
  [readonly]="readonly"
  [disabled]="disabled"
  [required]="required"
  [placeholder]="placeholder"
  (ngModelChange)="change($event)"
  [label]="label"
  [assistiveText]="error"
>
</cw-input>
