@if(readonly || disabled){
<ng-container *ngTemplateOutlet="input"></ng-container>

} @else {
<cw-menu customShadowCss styles=".menu{z-index:3!important;width: 100%!important;}">
  <div slot="trigger">
    <ng-container *ngTemplateOutlet="input"></ng-container>
  </div>

  @for(item of filteredData | async; track item){
  <cw-menu-item [attr.data-vef]="attrVefItem" [active]="item === control?.value" (click)="selected(item)">{{
    item | printLabel : labelType
  }}</cw-menu-item>

  }
</cw-menu>

}

<ng-template #input>
  <cw-input
    [attr.data-vef]="attrVef"
    [class.disabled]="disabled"
    [class.readonly]="readonly"
    type="text"
    [status]="error ? 'error' : null"
    [readonly]="readonly"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    (valueChange)="selected($event)"
    [value]="inputTextValue"
    [label]="label"
    [assistiveText]="error"
  >
  </cw-input>
</ng-template>
