import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { Patient } from '../models/generated/patient';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private patientsObs: Observable<Array<Patient>>;

  constructor(private readonly httpClient: HttpClient, private readonly env: EnvironmentService) {}

  public getPatients(): Observable<Array<Patient>> {
    if (!this.patientsObs) {
      this.patientsObs = this.httpClient.get<Array<Patient>>(`${this.env.getBaseRestUrl()}/patient`).pipe(
        catchError((err) => {
          console.warn('Error requesting patients', err);
          return of([]);
        }),
        map((patients: Array<Patient>) => [...patients].sort((a, b) => a.lastName.localeCompare(b.lastName))),
        shareReplay()
      );
    }
    return this.patientsObs;
  }
  public patientExists(value: string | Patient): Observable<boolean> {
    const id = value === 'string' ? value : (value as Patient).id;

    return this.getPatients().pipe(map((patients) => !!patients.find((patient) => patient.id === id)));
  }
}
