import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormat } from '../enums/DateFormat';

@Pipe({
  standalone: true,
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(date: number | string, format: DateFormat = DateFormat.MEDIUM): string | null {
    if (date === null) {
      return null;
    }

    const locale = this.translateService.currentLang;
    const datePipe = new DatePipe(locale || 'en');

    let transformation;
    try {
      transformation = datePipe.transform(date, format);
    } catch (error) {
      console.warn(`No registered locale for ${locale}`);
      transformation = new DatePipe('en').transform(date, format);
    }

    return transformation;
  }
}
