<svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none">
  <mask id="path-1-inside-1_2810_65552" fill="white">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.166 0H0.166016V20H14.166V15.8293C13.8532 15.9398 13.5167 16 13.166 16H12.166C10.5092 16 9.16602 14.6569 9.16602 13V7C9.16602 5.34315 10.5092 4 12.166 4H13.166C13.5167 4 13.8532 4.06015 14.166 4.17071V0Z"
    />
  </mask>
  <path
    class="body"
    d="M0.166016 0V-1.5H-1.33398V0H0.166016ZM14.166 0H15.666V-1.5H14.166V0ZM0.166016 20H-1.33398V21.5H0.166016V20ZM14.166 20V21.5H15.666V20H14.166ZM14.166 15.8293H15.666V13.7082L13.6661 14.415L14.166 15.8293ZM14.166 4.17071L13.6661 5.58497L15.666 6.29182V4.17071H14.166ZM0.166016 1.5H14.166V-1.5H0.166016V1.5ZM1.66602 20V0H-1.33398V20H1.66602ZM14.166 18.5H0.166016V21.5H14.166V18.5ZM12.666 15.8293V20H15.666V15.8293H12.666ZM13.6661 14.415C13.5117 14.4696 13.344 14.5 13.166 14.5V17.5C13.6893 17.5 14.1948 17.4101 14.6659 17.2436L13.6661 14.415ZM13.166 14.5H12.166V17.5H13.166V14.5ZM12.166 14.5C11.3376 14.5 10.666 13.8284 10.666 13H7.66602C7.66602 15.4853 9.68073 17.5 12.166 17.5V14.5ZM10.666 13V7H7.66602V13H10.666ZM10.666 7C10.666 6.17157 11.3376 5.5 12.166 5.5V2.5C9.68073 2.5 7.66602 4.51472 7.66602 7H10.666ZM12.166 5.5H13.166V2.5H12.166V5.5ZM13.166 5.5C13.344 5.5 13.5117 5.53037 13.6661 5.58497L14.6659 2.75645C14.1948 2.58994 13.6893 2.5 13.166 2.5V5.5ZM12.666 0V4.17071H15.666V0H12.666Z"
    fill="red"
    mask="url(#path-1-inside-1_2810_65552)"
  />
  <rect class="window" x="10.916" y="5.75" width="2.5" height="8.5" rx="1.25" stroke="red" stroke-width="1.5" />
</svg>
