import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import localeDe from '@angular/common/locales/de';
import localeES from '@angular/common/locales/es';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  constructor(private readonly translateService: TranslateService) {}

  public init() {
    this.registerLocaleData();
    this.setDefaultLocale();
    this.setLocale(this.getBrowserLocale());
  }

  private setDefaultLocale() {
    this.translateService.setDefaultLang('en');
  }

  private getBrowserLocale(): string {
    return this.translateService.getBrowserLang();
  }

  private setLocale(locale: string) {
    this.translateService
      .use(locale)
      .pipe(take(1))
      .subscribe(() => {
        console.info('Setting language to:', locale);
      });
  }

  private registerLocaleData() {
    registerLocaleData(localeDe);
    registerLocaleData(localeES);
  }
}
