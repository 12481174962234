import { Validators } from '@angular/forms';
import { ConfigUiControl } from '~/app/models/Configuration';

export interface ControlsCustomization {
  [key: string]: {
    control?: ConfigUiControl;
    showTitle?: boolean;
    disableWith?: string;
    disabled?: boolean;
    validation?: Array<Validators>;
    validationMsgs?: Record<string, string>;
    dragDropHeaders?: Array<string>;
    order?: number;
  };
}

export const configurationCustomization: ControlsCustomization = {
  ['monitorDeviceConfiguration.language']: {
    control: ConfigUiControl.SELECT,
    disabled: true,
    validation: [Validators.required]
  },
  ['appearanceConfiguration.filterOptions']: {
    control: ConfigUiControl.DRAG_DROP_LIST,
    dragDropHeaders: ['ConfigPageFilterOptionsAvailable', 'ConfigPageFilterOptionsSelected']
  },
  ['appearanceConfiguration.cardDetails']: { control: ConfigUiControl.MULTI_SELECT, validation: [Validators.required] },
  ['monitoringSettings.flowRateDeviation.deviationPercentage']: {
    order: 3,
    disableWith: 'monitoringSettings.flowRateDeviation.enableAlert',
    validation: [Validators.required, Validators.min(5), Validators.max(50)],
    validationMsgs: {
      min: 'ConfigPageNumberMinLimit5',
      max: 'ConfigPageNumberMaxLimit50'
    },
    disabled: true
  },
  ['monitoringSettings.volumeDeviation.deviationPercentage']: {
    order: 5,
    disableWith: 'monitoringSettings.volumeDeviation.enableAlert',
    validation: [Validators.required, Validators.min(5), Validators.max(50)],
    validationMsgs: {
      min: 'ConfigPageNumberMinLimit5',
      max: 'ConfigPageNumberMaxLimit50'
    },
    disabled: true
  },
  ['general.defaultNumDropsPerMl']: {
    validation: [Validators.required, Validators.min(1)],
    validationMsgs: {
      min: 'ConfigPageNumberMinLimit1'
    },
    disabled: true
  },
  ['general.allowStatusChangeInJobs']: { disabled: true },
  ['general.enableAdHocMonitoring']: { disabled: true },
  ['general.enableFlushReminder']: { disabled: true },
  ['monitoringSettings.enableDripChamberDetachmentAlert']: { disabled: true, order: 1 },
  ['monitoringSettings.flowRateDeviation.enableAlert']: { disabled: true, order: 2 },
  ['monitoringSettings.volumeDeviation.enableAlert']: { disabled: true, order: 4 }
};
