import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarComponent } from 'projects/gravpl-shared/src/lib/components/progress-bar/progress-bar.component';
import { TherapyJob } from '~/app/models/TherapyJob';
import { DurationFormatPipe, TimeUnit } from '~/app/pipes/DurationFormat';
import { ProgressPipe } from '~/app/pipes/Progress';

@Component({
  selector: 'gplus-infusion',
  standalone: true,
  imports: [CommonModule, TranslateModule, ProgressBarComponent, DurationFormatPipe, ProgressPipe],
  templateUrl: './infusion.component.html',
  styleUrl: './infusion.component.scss'
})
export class InfusionComponent {
  public timeUnit = TimeUnit;

  @Input() therapyJob: TherapyJob;
  @Input() size: 'lg' | 'sm' = 'sm';
  @Input() level: 'error' | 'info' = 'info';
}
