import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { CwButton, CwIcon, CwList, CwListItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';

import Sortable from 'sortablejs';

export interface DragDropListItem {
  id: string;
  label: string;
}

const moveItem = (array, fromIndex, toIndex) => {
  const [item] = array.splice(fromIndex, 1); // Remove the item from the original position
  array.splice(toIndex, 0, item); // Insert the item at the new position
};

@Component({
  selector: 'gplus-drag-drop-list',
  standalone: true,
  imports: [CwIcon, CwList, CwListItem, CwButton, TranslateModule, CommonModule],
  templateUrl: './drag-drop-list.component.html',
  styleUrl: './drag-drop-list.component.scss'
})
export class DragDropListComponent implements AfterViewInit, OnInit {
  @Input() control: AbstractControl<Array<DragDropListItem>>;
  @Input() items: Array<DragDropListItem> = [];
  @Input() headers: Array<string>;

  public selectedItems: Array<DragDropListItem> = [];
  public noSelectedItems: Array<DragDropListItem> = [];
  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {
    this.noSelectedItems = this.items;

    if (this.control) {
      // Subscribe to value changes
      this.selectedItems = this.updateItems(this.control?.value)?.selected;
      this.noSelectedItems = this.updateItems(this.control?.value)?.noSelected;

      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        // if the control value is patch or clear we can update the input text value
        this.selectedItems = this.updateItems(this.control?.value)?.selected;
        this.noSelectedItems = this.updateItems(this.control?.value)?.noSelected;
      });
    }
  }

  ngAfterViewInit(): void {
    const el = document.getElementById('draggable');

    Sortable.create(el, {
      animation: 150,
      handle: '.handle',
      ghostClass: 'sortable-ghost',
      chosenClass: 'sortable-chosen',
      dragClass: 'sortable-drag',
      disabled: false,
      onEnd: (evt) => {
        moveItem(this.selectedItems, evt.oldIndex, evt.newIndex);
        this.change(this.selectedItems);
      }
    });
  }

  public addItem(item: DragDropListItem): void {
    this.change([...this.selectedItems, item]);
  }

  public removeItem(item: DragDropListItem): void {
    this.change(this.selectedItems.filter((d) => d?.id !== item?.id));
  }

  public change(value: Array<DragDropListItem>) {
    if (!this.control) {
      return;
    }
    this.control.setValue(value);
    this.control.markAsDirty();
  }

  private updateItems(selected: Array<DragDropListItem>): {
    selected: Array<DragDropListItem>;
    noSelected: Array<DragDropListItem>;
  } {
    return {
      // we keep the order of the selected items
      selected: selected?.map((s) => this.items.find((i) => i?.id === s?.id)),
      noSelected: this.items.filter((d) => selected?.findIndex((s) => s?.id === d?.id) === -1)
    };
  }
}
