<cw-textarea
  customShadowCss
  [class.disabled]="disabled"
  [class.readonly]="readonly"
  [attr.data-vef]="attrVef"
  [ngModel]="inputTextValue"
  (ngModelChange)="change($event)"
  [styles]="customShadowCss"
  [placeholder]="placeholder"
  rows="5"
  [label]="label"
  [assistiveText]="error"
  [required]="required"
  [disabled]="disabled"
  [readOnly]="readonly"
></cw-textarea>
