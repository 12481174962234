@if(form){

<h3 class="page-sidebar-title full-group">
  {{ 'FormTJCreationSidebarTitle' | translate }}
</h3>

@if(therapyJob?.status === therapyStatus.PRESCRIBED){
<section class="page-sidebar-section first-group" [formGroup]="form">
  <cx-checkbox-extended formControlName="prepared">{{ 'DetailPagePrepared' | translate }}</cx-checkbox-extended>
</section>
}

<!-- Progress bar -->
@if (therapyJob?.status === therapyStatus.RUNNING) {
<section class="page-sidebar-section first-group">
  <div class="progress-bar-wrapper">
    <div class="progress-bar-eyebrow">
      <div *ngIf="therapyJob?.totalVolume">
        <b>{{
          therapyJob?.latestEvent?.injectedVolume
            ? therapyJob?.latestEvent?.injectedVolume + ' ' + ('UnitMl' | translate)
            : '-'
        }}</b>
        <span> / </span>
        <span>
          {{ therapyJob?.totalVolume ? therapyJob?.totalVolume + ' ' + ('UnitMl' | translate) : '-' }}
        </span>
      </div>
      <div>
        <cx-icon icon="speed" [filled]="false" size="sm"></cx-icon>

        <b>{{
          therapyJob?.latestEvent?.currentFlowRate
            ? therapyJob?.latestEvent?.currentFlowRate + ' ' + ('UnitMlHr' | translate)
            : '-'
        }}</b>
      </div>
    </div>

    @if(!tjNotificationMap?.[notificationType.DRIP_CHAMBER_DETACHED + '-' + therapyJob?.id] &&
    !tjNotificationMap?.[notificationType.THERAPY_JOB_UNPAIRED_FROM_MD + '-' + therapyJob?.id]){

    <gplus-infusion size="lg" [level]="mostCriticalStatus" [therapyJob]="therapyJob"></gplus-infusion>
    }

    <div class="progress-bar-sub b2">
      <div>
        <b>Started:</b>
        <span>
          {{ therapyJob?.startTime?.real ? (therapyJob.startTime.real | date : ' h:mm a') : '--:--' }}
        </span>
      </div>
      <div>
        <cx-icon icon="schedule" [filled]="false" size="xs"></cx-icon>

        <span>
          {{
            therapyJob?.latestEvent?.remainingTime
              ? (therapyJob?.latestEvent?.remainingTime | durationFormat : timeUnit.MINUTES)
              : '-'
          }}
        </span>
      </div>
    </div>
  </div>
</section>
}

<section class="page-sidebar-section second-group" [formGroup]="form">
  <h5 class="page-sidebar-section-title">
    {{ 'FormTJCreationPlanningSection' | translate }}
  </h5>

  <div *ngIf="timeError" class="error-value" [attr.data-vef]="'detailPage-delayedTime-label'">
    <cx-icon icon="warning" [filled]="false" size="sm"></cx-icon> {{ timeError }}
  </div>

  <mat-form-field
    [attr.data-vef]="'form-create-manual-physician'"
    [class.cx-readonly]="form.controls?.['physician']?.status === 'DISABLED'"
  >
    <mat-label>{{ 'FormTJCreationPhysician' | translate }}</mat-label>

    <input
      type="text"
      matInput
      #basicAuto
      formControlName="physician"
      [matAutocomplete]="autoPhysician"
      required
      [placeholder]="'FormTJCreationPhysicianPlaceholder' | translate"
    />
    <mat-autocomplete #autoPhysician="matAutocomplete" [displayWith]="displayPersonName">
      @for(physician of filteredPhysician | async; track physician.id){
      <mat-option [value]="physician"> {{ physician | personName }} </mat-option>
      }
    </mat-autocomplete>

    <mat-error>
      @if(form.controls?.['physician']?.errors?.['physicianNotExist']){
      {{ 'FormTJCreationPleaseSelectOneOfTheList' | translate }}
      } @else {
      {{ 'FormTJCreationPhysicianIsRequired' | translate }}

      }
    </mat-error>
  </mat-form-field>

  <mat-form-field
    [attr.data-vef]="'form-create-manual-planned-date'"
    [class.cx-readonly]="form.controls['startDate'].status === 'DISABLED'"
  >
    <mat-label>{{ 'FormTJCreationStartDate' | translate }}</mat-label>
    <input
      matInput
      autocomplete="off"
      [matDatepicker]="myDatepicker1"
      formControlName="startDate"
      required
      [placeholder]="'FormTJCreationStartDatePlaceholder' | translate"
    />
    <mat-datepicker-toggle matSuffix [for]="myDatepicker1"></mat-datepicker-toggle>
    <mat-datepicker #myDatepicker1></mat-datepicker>

    <mat-error>
      @if(form?.controls?.['startDate']?.errors?.['required']){
      {{ 'FormTJCreationDateIsRequired' | translate }}
      }
    </mat-error>
  </mat-form-field>

  <mat-form-field
    [attr.data-vef]="'form-create-manual-planned-time'"
    [class.cx-readonly]="form.controls['hours'].status === 'DISABLED'"
  >
    <mat-label>{{ 'FormTJCreationStartTime' | translate }}</mat-label>
    <input
      matInput
      formControlName="hours"
      type="time"
      [placeholder]="'FormTJCreationStartTimePlaceholder' | translate"
    />

    <mat-error>
      @if(form?.controls['hours'].errors?.['min']){
      {{ 'FormTJCreationMinLimit' | translate }}
      } @if(form?.controls['hours'].errors?.['notNumber']){
      {{ 'FormTJCreationNotNumber' | translate }}
      } @if(form?.controls?.['hours']?.errors?.['required']){
      {{ 'FormTJCreationStartTimeIsRequired' | translate }}
      }
    </mat-error>
  </mat-form-field>
</section>

<section *ngIf="therapyJob" class="page-sidebar-section third-group">
  <h5 class="page-sidebar-section-title">
    {{ 'DetailPageDeviceSectionTitle' | translate }}
  </h5>

  <gplus-manage-device
    *ngIf="therapyJob"
    class="connect-selector"
    [therapyJob]="therapyJob"
    (click)="$event.stopPropagation()"
  ></gplus-manage-device>

  <div class="gplus-list" *ngIf="therapyJob">
    <div class="item" *ngIf="therapyJob?.pairingState === pairingStatus.PAIRED">
      <div class="label">{{ 'DetailPageDeviceSectionDeviceId' | translate }}</div>
      <div class="value">{{ therapyJob?.monitorDeviceId }}</div>
    </div>
    <div class="item">
      <div class="label">{{ 'DetailPageDeviceSectionStatus' | translate }}</div>
      <div class="value">
        @switch (therapyJob?.pairingState ) {
        <!--  -->
        @case (pairingStatus.UN_PAIRED) {
        <cx-badge-status type="secondary">
          {{ 'PairingStatusUN_PAIRED' | translate }}
        </cx-badge-status>
        }
        <!--  -->
        @case (pairingStatus.IN_PAIRING) {
        <cx-badge-status type="secondary" status="warning">
          {{ 'PairingStatusIN_PAIRING' | translate }}
        </cx-badge-status>
        }
        <!--  -->
        @case (pairingStatus.PAIRED) {

        <cx-badge-status type="secondary" status="success">
          {{ 'PairingStatusPAIRED' | translate }}
        </cx-badge-status>
        }
        <!--  -->
        @case(undefined) {
        <cx-badge-status type="secondary">
          {{ 'PairingStatusUN_PAIRED' | translate }}
        </cx-badge-status>
        } }
      </div>
    </div>
  </div>
</section>
}
