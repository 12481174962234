import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { Prescription } from '../models/prescription';
import { EnvironmentService } from './environment.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
  private prescriptions: Array<Prescription>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly sessionService: SessionService
  ) {}
  public getOnDemandPrescriptions(wardId: string): Observable<Array<Prescription>> {
    let params = new HttpParams().set('ward', wardId);

    return this.httpClient
      .get<Array<Prescription>>(`${this.env.getBaseRestUrl()}/api/prescription/type/ondemand`, { params: params })
      .pipe(
        tap((prescriptions) => {
          this.prescriptions = prescriptions;
        }),
        catchError((err) => {
          console.warn('Error requesting patients', err);
          return of([]);
        })
      );
  }

  public getOnDemandPrescription(id: string): Observable<Prescription> {
    if (!this.prescriptions) {
      return this.getOnDemandPrescriptions(this.sessionService.currentWard).pipe(
        map((prescriptions) => prescriptions.find((p) => p.id === id))
      );
    }
    return of(this.prescriptions?.find((p) => p.id === id));
  }
}
