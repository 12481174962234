import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable, of, take } from 'rxjs';
import { TherapyJobFormClass } from '~/app/classes/TherapyJobFormClass';
import { SessionData } from '~/app/models/SessionData';
import { CarrierService } from '~/app/services/carrier.service';
import { ConfigService } from '~/app/services/config.service';
import { DialogIds, DialogService } from '~/app/services/dialog.service';
import { EmployeeService } from '~/app/services/employee.service';
import { HeaderService } from '~/app/services/header.service';
import { MedicineService } from '~/app/services/medicine.service';
import { PatientService } from '~/app/services/patient.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { NotificationsStore } from '~/app/store/notification.store';
import { getPayloadFromForm } from '~/app/utils/getPayload';
import {
  PrescriptionExtended,
  TherapiesOnDemandComponent
} from '../../therapies-on-demand/therapies-on-demand.component';
import { DetailPageBodyComponent } from '../detail-page-body/detail-page-body.component';
import { DetailPageFormActionsComponent } from '../detail-page-form-actions/detail-page-form-actions.component';
import { DetailPageHeaderComponent } from '../detail-page-header/detail-page-header.component';
import { DetailPageSidebarComponent } from '../detail-page-sidebar/detail-page-sidebar.component';

@Component({
  selector: 'gplus-detail-page-new',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DetailPageBodyComponent,
    DetailPageHeaderComponent,
    DetailPageSidebarComponent,
    TherapiesOnDemandComponent,
    DetailPageFormActionsComponent,
    TranslateModule
  ],
  templateUrl: './detail-page-new.component.html',
  styleUrls: ['../common/page.scss']
})
export class DetailPageNewComponent implements OnInit {
  public formInstance: TherapyJobFormClass;
  public resetChange: EventEmitter<void> = new EventEmitter();
  public data: SessionData;
  public loader: boolean;
  public showSubmitButtonDisabled: boolean;
  public showResetButtonDisabled: boolean;

  // Depends on what the user is creating,
  //  PRN: has permission
  //  Manual: depends on config.general.allowManualJobs
  public permissionCreate: boolean;

  private jobsStore = inject(TherapyJobStore);
  private notificationsStore = inject(NotificationsStore);

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: Event) {
    if (this.formInstance.form?.dirty) {
      event?.preventDefault();
    }
  }

  constructor(
    private readonly therapyJobService: TherapyJobService,
    private readonly router: Router,
    private readonly headerService: HeaderService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,

    // Public
    public readonly configService: ConfigService,

    // Data Services
    private readonly patientService: PatientService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService,
    private readonly carrierService: CarrierService
  ) {}

  ngOnInit(): void {
    this.setHeader();

    this.loader = true;
    combineLatest([
      this.medicineService.getMedicines(),
      this.carrierService.getCarriers(),
      this.patientService.getPatients(),
      this.employeeService.getPhysicians()
    ])
      .pipe(
        take(1),
        map(([medicines, carriers, patients, physicians]) => ({ medicines, carriers, patients, physicians }))
      )
      .subscribe((sessionData) => {
        this.data = sessionData;
        this.initForm();

        // we set the default selection as manual
        // so we can display the buttons
        this.selectedTherapy('manual');

        this.loader = false;
      });
  }

  private initForm() {
    this.formInstance = new TherapyJobFormClass(this.patientService, this.medicineService, this.employeeService);
  }

  private setHeader() {
    this.headerService.setHeader({
      titleKey: this.translateService.instant('PageTitleAddTherapyPage'),
      showBackBtn: true,
      showSearch: false
    });
  }

  public submitChange() {
    if (this.formInstance.form.valid) {
      this.loader = true;

      this.therapyJobService
        .create(getPayloadFromForm(this.formInstance.form))
        .pipe(take(1))
        .subscribe(() => {
          // After creation we reload the list
          this.jobsStore.loadAll().then(() => {
            // we update notifications
            this.notificationsStore.setAll();
          });

          this.loader = false;

          this.formInstance.form.reset();
          this.router.navigate(['/home']);
        });
    }
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.formInstance.form?.dirty) {
      return of(true);
    }

    return new Observable<boolean>((observer) => {
      this.dialogService.open(DialogIds.LEAVE_WITH_UNSAVED);

      this.dialogService
        .dialog()
        .confirm.pipe(take(1))
        .subscribe((value) => {
          this.dialogService.close();

          observer.next(value);
          observer.complete();
        });
    });
  }

  public selectedTherapy(prescription: PrescriptionExtended | 'manual') {
    this.permissionCreate =
      prescription === 'manual' ? this.configService.configuration()?.content?.general.allowManualJobs : true;
  }
}
