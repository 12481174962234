@if (therapyJob?.pairingState !== pairingStatus.PAIRED && therapyJob?.status !== therapyJobStatus.COMPLETED) {
<div class="wrapper">
  <mat-form-field [attr.data-vef]="'device-selector'">
    <mat-select
      [disabled]="disableSelector"
      [(ngModel)]="selected"
      (ngModelChange)="connectMonitorDevice()"
      [placeholder]="'selectMonitorDevice' | translate"
      (openedChange)="toggleSelector($event)"
    >
      <mat-option [value]="defaultOption" [attr.data-vef]="'device-selector-dropdown-autoPair-option'">
        <cx-icon class="auto-pair-icon" icon="published_with_changes"></cx-icon>
        {{ defaultOption.key | translate }}
      </mat-option>

      <mat-optgroup *ngIf="activeDevices.options || loadingActiveDevices" [label]="activeDevices.key | translate">
        <mat-option *ngIf="loadingActiveDevices" [disabled]="true">
          <span *ngIf="loadingActiveDevices" class="loading-option">
            {{ 'Searching' | translate }}
            <div class="loader"></div>
          </span>
        </mat-option>
        @for (option of activeDevices.options; track option.id) {
        <mat-option
          [value]="option"
          [class.low-battery]="option.isBatteryLow"
          [attr.data-vef]="'device-selector-dropdown-activeDevice-option'"
        >
          <div class="wrapper-option">
            {{ option.key ? (option.key | translate) : option.id }}

            @if(option.isBatteryLow){
            <span class="low-battery-label">({{ 'LowBattery' | translate }})</span>
            <cx-icon class="low-battery-icon" icon="battery_1_bar" size="sm"></cx-icon>
            }
          </div>
        </mat-option>
        }
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
  <cx-button
    *ngIf="showCancel"
    button-color="secondary"
    variant="outlined"
    type="button"
    as="button"
    (click)="$event.stopPropagation(); cancelPairing()"
    [disabled]="processingCancel"
  >
    {{ 'TherapyJobCancelPairing' | translate }}
  </cx-button>
</div>

<div class="assistive">
  @switch (message?.type) { @case('loading'){

  <div class="loading">
    <div class="loader"></div>
    {{ message?.text }}
  </div>

  } @case('error'){

  <div class="error"><cx-icon icon="error" [filled]="true" size="xs"></cx-icon>{{ message?.text }}</div>

  } @case('warning'){

  <div class="warning"><cx-icon icon="warning" [filled]="true" size="xs"></cx-icon>{{ message?.text }}</div>

  } @case('info'){

  <div class="info"><cx-icon icon="error" [filled]="true" size="xs"></cx-icon>{{ message?.text }}</div>

  } @case('success'){

  <div class="success"><cx-icon icon="check_circle" [filled]="true" size="xs"></cx-icon>{{ message?.text }}</div>

  } }
</div>

}
