import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { Patient } from '../models/generated/patient';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private patients: Array<Patient>;

  constructor(private readonly httpClient: HttpClient, private readonly env: EnvironmentService) {}

  public getPatients(wardId: string): Observable<Array<Patient>> {
    let params = new HttpParams().set('ward', wardId);

    return this.httpClient.get<Array<Patient>>(`${this.env.getBaseRestUrl()}/patient`, { params: params }).pipe(
      catchError((err) => {
        console.warn('Error requesting patients', err);
        return of([]);
      }),
      map((patients: Array<Patient>) => {
        this.patients = [...patients].sort((a, b) => a.lastName.localeCompare(b.lastName));
        return this.patients;
      })
    );
  }
  public patientExists(value: string | Patient): Observable<boolean> {
    const id = value === 'string' ? value : (value as Patient).id;

    return of(!!this.patients?.find((patient) => patient.id === id));
  }
}
