import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarComponent } from 'projects/gravpl-shared/src/lib/components/progress-bar/progress-bar.component';
import { DateFormat } from '~/app/enums/DateFormat';
import { NotificationLevel } from '~/app/enums/NotificationLevel';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { DurationFormatPipe, TimeUnit } from '~/app/pipes/DurationFormat';
import { IsTodayPipe } from '~/app/pipes/IsToday';
import { LocalizedDatePipe } from '~/app/pipes/LocalizedDate';
import { ProgressPipe } from '~/app/pipes/Progress';

@Component({
  selector: 'gplus-infusion',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ProgressBarComponent,
    DurationFormatPipe,
    ProgressPipe,
    IsTodayPipe,
    LocalizedDatePipe
  ],
  templateUrl: './infusion.component.html',
  styleUrl: './infusion.component.scss'
})
export class InfusionComponent {
  public timeUnit = TimeUnit;
  public therapyJobStatus = TherapyJobStatus;
  public pairingStatus = PairingStatus;
  public dateFormat = DateFormat;

  @Input() therapyJob: TherapyJobExtended;
  @Input() level: NotificationLevel;

  @Input() container: 'card' | 'detail';
  @HostBinding('class') get hostClass() {
    return this.container;
  }
}
