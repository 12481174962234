import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  standalone: true,
  name: 'getFormControl'
})
export class GetFormControlPipe implements PipeTransform {
  transform(path: string, form: FormGroup) {
    if (!form || !path) {
      return null;
    }
    return form.get(path);
  }
}
