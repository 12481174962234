import { Component, Input } from '@angular/core';
import { CxBadgeStatus, CxIcon } from '@bbraun/cortex-angular';

@Component({
  selector: 'gplus-snackbar',
  standalone: true,
  imports: [CxBadgeStatus, CxIcon],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss'
})
export class SnackbarComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() timestamp: string;
  @Input() type: string;
  @Input() icon: string;
}
