import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CwDivider } from '@bbraun/cortex-angular';
import { EntityMap } from '@ngrx/signals/entities';
import { TranslateModule } from '@ngx-translate/core';
import { ONLY_NOTES_MAX_LENGTH, TherapyJobFormClass } from '~/app/classes/TherapyJobFormClass';
import { AutocompleteComponent } from '~/app/components/autocomplete/autocomplete.component';
import { medicineUnits } from '~/app/enums/MedicineUnits';
import { NotificationType } from '~/app/enums/NotificationId';
import { NotificationLevel } from '~/app/enums/NotificationLevel';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { TimeUnit } from '~/app/pipes/DurationFormat';
import { IsTodayPipe } from '~/app/pipes/IsToday';
import { LocalizedDatePipe } from '~/app/pipes/LocalizedDate';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { getCriticalType } from '~/app/utils/getCriticalNotificationType';
import { DatepickerComponent } from '../../datepicker/datepicker.component';
import { InfusionComponent } from '../../infusion/infusion.component';
import { InlineBannerComponent } from '../../inline-banner/inline-banner.component';
import { InputComponent } from '../../input/input.component';
import { TextareaComponent } from '../../textarea/textarea.component';

@Component({
  selector: 'gplus-detail-page-body',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    CwDivider,
    AutocompleteComponent,
    InputComponent,
    TextareaComponent,
    DatepickerComponent,
    InfusionComponent,
    InlineBannerComponent
  ],
  templateUrl: './detail-page-body.component.html',
  styleUrl: './detail-page-body.component.scss',
  providers: [PersonNamePipe, LocalizedDatePipe, IsTodayPipe]
})
export class DetailPageBodyComponent implements OnChanges {
  @Input() formInstance: TherapyJobFormClass;
  @Input() data: SessionData;
  @Input() therapyJob: TherapyJobExtended;
  @Input() tjNotificationMap: EntityMap<TherapyJobNotification>;
  @Input() showPhysician: boolean;
  @Input() mostCriticalStatus: NotificationLevel;

  public _renderFields: boolean;
  @Input() set renderFields(value: boolean) {
    this._renderFields = value === null || value === undefined ? true : value;
  }

  public units = medicineUnits;

  public submitLoader: boolean;
  public dataLoader: boolean;
  public therapyStatus = TherapyJobStatus;
  public notificationType = NotificationType;
  public timeUnit = TimeUnit;

  public annotationMaxLength = ONLY_NOTES_MAX_LENGTH;

  constructor(public readonly personName: PersonNamePipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tjNotificationMap) {
      this.setNotificationCriticality();
    }
  }

  public trackFn(control: AbstractControl) {
    return control?.value?.id || Math.random();
  }

  private setNotificationCriticality() {
    // when we have multiple notifications we set the global criticality by the highest
    this.mostCriticalStatus = getCriticalType(this.tjNotificationMap);
  }
}
