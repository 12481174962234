@if(sidebar?.open){
<div class="sidebar-wrapper" @fadeIn>
  <div class="sidebar-header">
    <h3>{{ 'AlarmCenterTitle' | translate }}</h3>
    <cw-button rounded="true" button-color="secondary" (click)="close()">
      <cw-icon class="icon" icon="close" filled="true"></cw-icon>
    </cw-button>
  </div>
  <ng-container *ngComponentOutlet="sidebar?.component"> </ng-container>
</div>
}
