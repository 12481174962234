export const timePassedInMs = (date: number | string | Date): number => {
  if (!date) {
    return 0;
  }

  if (date instanceof Date) {
    return Date.now() - date.getTime();
  }

  return Date.now() - new Date(date).getTime();
};
