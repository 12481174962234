import { DEFAULT_COLUMNS } from '../constants/defaultColumns';
import { TherapyJob } from '../models/TherapyJob';
import { Column } from '../models/ui/Column';

export const getColumns: (therapyJobs: Array<TherapyJob>) => Array<Column> = (therapyJobs) => {
  return DEFAULT_COLUMNS.map((c) => ({
    id: c,
    therapyJobs: therapyJobs?.filter((tj) => tj.status === c) || []
  }));
};
