import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { CwToggle } from '@bbraun/cortex-angular';

@Component({
  selector: 'gplus-toggle',
  standalone: true,
  imports: [CwToggle],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss'
})
export class ToggleComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() attrVef: string;
  @Input() validations: Record<string, any> = {};

  public error: string;
  public value: boolean;
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.control) {
      this.value = this.control.value;

      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.value = this.control.value;

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }

  public change(event: CustomEvent) {
    if (!this.control) {
      return;
    }
    this.control.setValue(event.detail);
    this.control.markAsDirty();
  }
}
