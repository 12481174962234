@if(form){

<div class="full-group page-body-header">
  <gplus-detail-page-header [therapyJob]="therapyJob" [form]="form"></gplus-detail-page-header>

  <div class="actions">
    @if(!form.valid || form.pristine){
    <cw-button
      [attr.data-vef]="'form-create-manual-cancel'"
      button-color="secondary"
      variant="outlined"
      [disabled]="true"
    >
      {{ 'FormTJCreationResetChanges' | translate }}
    </cw-button>

    <cw-tooltip
      [content]="
        (!form.valid ? 'FormTJCreationMissingRequiredFields' : form.pristine ? 'FormTJCreationNothingToSave' : '')
          | translate
      "
      placement="bottom"
    >
      <cw-button
        [attr.data-vef]="'form-create-manual-submit'"
        button-color="accent"
        variant="filled"
        [disabled]="true"
        type="submit"
      >
        {{ 'FormTJSaveChanges' | translate }}
      </cw-button>
    </cw-tooltip>

    } @else{

    <cw-button
      [attr.data-vef]="'form-create-manual-cancel'"
      button-color="secondary"
      variant="outlined"
      (click)="resetChange.emit()"
    >
      {{ 'FormTJCreationResetChanges' | translate }}
    </cw-button>

    <cw-button
      [attr.data-vef]="'form-create-manual-submit'"
      button-color="accent"
      variant="filled"
      (click)="submitChange.emit()"
      [disabled]="submitLoader"
      type="submit"
    >
      {{ 'FormTJSaveChanges' | translate }}
      <span *ngIf="submitLoader" class="loader inline white"></span>
    </cw-button>

    }
  </div>
</div>

@if(tjNotificationMap){
<div class="full-group" *ngFor="let item of tjNotificationMap | keyvalue">
  <cw-banner
    customShadowCss
    styles=".banner-title{font-size: 1rem!important}"
    [type]="item.value.level"
    [label]="item.value.translationKey | translate"
    [description]="item.value.hintKey | translate"
    [enableClose]="false"
  ></cw-banner>
</div>
}

<section
  class="full-group page-body-section"
  *ngIf="pageStatus === detailPageStatus.CREATION_MANUAL"
  [formGroup]="form"
>
  <div class="first-group">
    <gplus-autocomplete
      [control]="form.controls['patient']"
      [filteredBy]="['lastName', 'firstName']"
      [data]="data?.patients"
      [label]="'FormTJCreationPatient' | translate"
      [required]="true"
      [placeholder]="'FormTJCreationPatientPlaceholder' | translate"
      [attrVef]="'form-create-manual-patient'"
      [attrVefItem]="'form-create-manual-dropdown-item'"
      [validations]="{
        required: 'FormTJCreationPatientIsRequired' | translate,
        patientNotExist: 'FormTJCreationPleaseSelectOneOfTheList' | translate
      }"
      labelType="personName"
    ></gplus-autocomplete>
  </div>
</section>

<section class="full-group page-body-section" [formGroup]="form">
  <h5 class="full-group page-body-section-title">
    {{ 'FormTJCreationDrugSection' | translate }}
  </h5>

  <ng-container [formGroup]="form.controls['medication']">
    <ng-container formArrayName="medicines">
      @for (medicineObj of medicines?.controls; track medicineObj; let i = $index; let last = $last; let first = $first)
      { @if(filteredAllMedicines){

      <ng-container [formGroup]="medicineObj">
        <div class="first-group">
          <gplus-autocomplete
            [control]="medicineObj.controls['medicine']"
            filteredBy="display"
            [data]="data?.medicines"
            [label]="'FormTJCreationMedicine' | translate"
            [required]="true"
            [readonly]="medicineObj.controls['medicine']?.status === 'DISABLED'"
            [placeholder]="'FormTJCreationMedicinePlaceholder' | translate"
            [attrVef]="'form-create-manual-medicine'"
            [attrVefItem]="'form-create-manual-dropdown-item'"
            [validations]="{
              required: 'FormTJCreationMedicineIsRequired' | translate,
              unauthorizedMedicine: 'FormTJCreationPleaseSelectOneOfTheList' | translate
            }"
            labelType="medicine"
          ></gplus-autocomplete>
        </div>

        <div class="second-group">
          <gplus-input
            type="number"
            [control]="medicineObj.controls['customAmount']"
            [label]="'FormTJCreationDose' | translate"
            [required]="true"
            [readonly]="medicineObj.controls['customAmount']?.status === 'DISABLED'"
            [placeholder]="'FormTJCreationDosePlaceholder' | translate"
            [attrVef]="'form-create-manual-dose'"
            [validations]="{
              required: 'FormTJCreationDoseIsRequired' | translate,
              max: 'FormTJCreationMaxDosageLimit' | translate: {amount: medicineObj?.controls?.['medicine']?.value?.amount},
              min: 'FormTJCreationMinLimit' | translate,
              notNumber: 'FormTJCreationNotNumber' | translate
            }"
          ></gplus-input>
        </div>

        <div class="third-group">
          <gplus-autocomplete
            filteredBy="label"
            [control]="medicineObj.controls['unit']"
            [label]="'FormTJCreationUnit' | translate"
            [data]="units"
            [required]="true"
            [readonly]="medicineObj.controls['unit']?.status === 'DISABLED'"
            [placeholder]="'FormTJCreationUnitPlaceholder' | translate"
            [attrVef]="'form-create-manual-unit'"
            [attrVefItem]="'form-create-manual-dropdown-item'"
            [validations]="{
              required: 'FormTJCreationUnitIsRequired' | translate,
              unitNotExist: 'FormTJCreationPleaseSelectOneOfTheList' | translate
            }"
            labelType="unit"
          ></gplus-autocomplete>
        </div>
      </ng-container>

      <div *ngIf="form.controls['medication']?.status !== 'DISABLED'" class="fourth-group v-center">
        @if(first){
        <cw-button variant="outlined" button-color="secondary" (click)="addMedicine()">
          <cw-icon icon="add"></cw-icon>
        </cw-button>

        } @else {

        <cw-button variant="outlined" button-color="secondary" (click)="removeMedicine(i)">
          <cw-icon icon="delete"></cw-icon>
        </cw-button>
        }
      </div>

      } }
    </ng-container>

    <cw-divider class="full-group" axis="horizontal" type="default"></cw-divider>

    <div class="first-group">
      <gplus-autocomplete
        filteredBy="description"
        attrVef="form-create-manual-carrier"
        [attrVefItem]="'form-create-manual-dropdown-item'"
        labelType="carrier"
        [control]="form.controls['medication'].controls['carrier']"
        [data]="data?.carriers"
        [label]="'FormTJCreationCarrier' | translate"
        [required]="true"
        [readonly]="form.controls['medication'].controls['carrier']?.status === 'DISABLED'"
        [placeholder]="'FormTJCreationCarrierPlaceholder' | translate"
        [validations]="{
          required: 'FormTJCreationCarrierIsRequired' | translate,
        }"
      ></gplus-autocomplete>
    </div>
  </ng-container>

  <div class="second-group">
    <gplus-input
      type="number"
      [control]="form.controls['volume']"
      [label]="'FormTJCreationVolume' | translate"
      [readonly]="form.controls['volume']?.status === 'DISABLED'"
      [placeholder]="'FormTJCreationVolumePlaceholder' | translate"
      [attrVef]="'form-create-manual-volume'"
      [validations]="{
        min: 'FormTJCreationMinLimit' | translate,
        notNumber: 'FormTJCreationNotNumber' | translate
      }"
    ></gplus-input>
  </div>

  <div class="third-group">
    <gplus-input
      type="number"
      [control]="form.controls['flowRate']"
      [label]="'FormTJCreationFlowRate' | translate"
      [readonly]="form.controls['flowRate']?.status === 'DISABLED'"
      [placeholder]="'FormTJCreationFlowRatePlaceholder' | translate"
      [attrVef]="'form-create-manual-flowrate'"
      [validations]="{
        min: 'FormTJCreationMinLimit' | translate,
        notNumber: 'FormTJCreationNotNumber' | translate
      }"
    ></gplus-input>
  </div>
</section>

<section class="full-group page-body-section" [formGroup]="form">
  <h5 class="full-group page-body-section-title h5">{{ 'FormTJCreationComment' | translate }}</h5>

  <div class="first-group">
    <gplus-textarea
      [control]="form.controls['annotations']"
      [readonly]="form.controls['annotations']?.status === 'DISABLED'"
      [placeholder]="'FormTJCreationNotesPlaceholder' | translate"
      [attrVef]="'form-create-manual-annotations'"
      [validations]="{
        notString: 'FormTJCreationNotString' | translate,
      }"
    ></gplus-textarea>
  </div>
</section>
}
