<div class="wrapper">
  <h6 class="mb-3" *ngIf="headers?.length">{{ headers[0] | translate }}</h6>
  <div class="list-container">
    <div class="inner-container">
      <cw-list id="selectable">
        @for( item of noSelectedItems; track item?.id){

        <cw-list-item [itemTitle]="item?.label" [id]="item?.id">
          <div slot="right">
            <cw-button rounded="true" button-color="secondary" (click)="addItem(item)">
              <cw-icon icon="add" filled="true"></cw-icon>
            </cw-button>
          </div>
        </cw-list-item>

        }
      </cw-list>
    </div>
  </div>
</div>

<div class="wrapper">
  <h6 class="mb-3" *ngIf="headers?.length === 2">{{ headers[1] | translate }}</h6>
  <div class="list-container">
    <div class="inner-container">
      <cw-list id="draggable">
        @for( item of selectedItems; track item?.id){

        <cw-list-item [itemTitle]="item?.label" [id]="item?.id">
          <div slot="left">
            <cw-icon class="handle" icon="drag_indicator"></cw-icon>
          </div>
          <div slot="right">
            <cw-button rounded="true" button-color="secondary" (click)="removeItem(item)">
              <cw-icon icon="close" filled="true"></cw-icon>
            </cw-button>
          </div>
        </cw-list-item>

        }
      </cw-list>
    </div>
  </div>
</div>
