@if(loader){
<div class="container-loader">
  <span class="loader absolute-centered"></span>
</div>

} @if(therapyJob && data){
<form class="page" *ngIf="formInstance?.form" [class.loading]="loader" [formGroup]="formInstance?.form">
  <gplus-detail-page-header
    [therapyJob]="therapyJob"
    [formInstance]="formInstance"
    (processing)="processChange($event)"
  ></gplus-detail-page-header>

  <div class="content">
    <gplus-detail-page-sidebar>
      <gplus-detail-page-details
        [therapyJob]="therapyJob"
        [formInstance]="formInstance"
        [ticker]="ticker"
        [event]="eventManager.pairingSignal()"
      ></gplus-detail-page-details>
    </gplus-detail-page-sidebar>
    <gplus-detail-page-body
      [therapyJob]="therapyJob"
      [data]="data"
      [formInstance]="formInstance"
      [tjNotificationMap]="notificationStore.entityMap() | filterNotification : therapyJob.id"
      [renderFields]="true"
    >
      <gplus-detail-page-form-actions
        class="actions"
        [disableSubmitTooltip]="
          (formInstance?.form?.pristine
            ? 'FormTJCreationNothingToSave'
            : formInstance?.form?.invalid
            ? 'FormTJCreationHasErrors'
            : ''
          ) | translate
        "
        [showSubmitButtonDisabled]="!formInstance?.form?.valid || formInstance?.form?.pristine"
        [showResetButtonDisabled]="!formInstance?.form?.dirty"
        (submitChange)="submitChange()"
        (resetChange)="resetChange()"
      ></gplus-detail-page-form-actions>
    </gplus-detail-page-body>
  </div>
</form>
}
