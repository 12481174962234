<div class="body">
  @for (field of fields(); track field) {
  <ng-container *ngTemplateOutlet="control; context: { field: field }"></ng-container>
  }
</div>

<ng-template #control let-field="field">
  @switch (field.id) {
  <!-- Cases -->
  @case (cardFields.MEDICINE) {
  <div class="group">
    <cw-icon class="icon" icon="medication" size="sm"></cw-icon>
    <span [attr.data-vef]="'card-medicine'" [innerHTML]="medicine?.first | highlight : highlightTerm"> </span>
    <span class="plus-more" *ngIf="medicine?.more">{{ medicine.more }}</span>
  </div>
  }
  <!--  -->
  @case (cardFields.FLOW_RATE) {
  <div class="group" [class.disabled]="!flowRate">
    <cw-icon class="icon" icon="speed" size="sm"></cw-icon>
    <span [attr.data-vef]="'card-flowRate'">{{ flowRate || '-' }}</span>
  </div>

  }
  <!--  -->
  @case (cardFields.MONITOR_DEVICE_ID) {
  <div class="group" [class.disabled]="therapyJob.pairingState !== pairingStatus.PAIRED">
    <gplus-icon-device [class.disabled]="therapyJob.pairingState !== pairingStatus.PAIRED"></gplus-icon-device>
    <span [attr.data-vef]="'card-deviceId-label'">{{
      therapyJob.pairingState === pairingStatus.PAIRED ? therapyJob.monitorDeviceId : '-'
    }}</span>
  </div>
  }
  <!--  -->
  @case (cardFields.PHYSICIAN) {
  <div class="group">
    <cw-icon class="icon" icon="person" size="sm"></cw-icon>
    <span [attr.data-vef]="'card-physician'">{{ therapyJob.physicianObject | personName }}</span>
  </div>

  }
  <!--  -->
  @case (cardFields.VOLUME) {

  <div class="group" [class.disabled]="!therapyJob.totalVolume">
    <cw-icon class="icon" icon="water_drop" size="sm"></cw-icon>
    <span [attr.data-vef]="'card-volume'">
      {{
        therapyJob.totalVolume
          ? ('TotalVolume' | translate) + ': ' + therapyJob.totalVolume + ' ' + ('UnitMl' | translate)
          : '-'
      }}</span
    >
  </div>
  }
  <!--  -->
  @case (cardFields.TIME) {
  <div class="group" [class.disabled]="!time">
    @switch (therapyJob.status) { @case (therapyJobStatus.PRESCRIBED) {
    <cw-icon class="icon" icon="schedule" size="sm"></cw-icon>

    } @case (therapyJobStatus.RUNNING) {
    <cw-icon class="icon" icon="alarm" size="sm"></cw-icon>

    } @case (therapyJobStatus.COMPLETED) {
    <cw-icon class="icon" icon="sports_score" size="sm"></cw-icon>

    } @case (therapyJobStatus.COMPLETE_CONFIRMED) {
    <cw-icon class="icon" icon="sports_score" size="sm"></cw-icon>

    } @case (therapyJobStatus.ABORTED) {
    <cw-icon class="icon" icon="close" size="sm"></cw-icon>

    }@case (therapyJobStatus.ABORTION_TRIGGERED) {
    <cw-icon class="icon" icon="close" size="sm"></cw-icon>

    } }
    <span class="time-label">
      <span [attr.data-vef]="'card-time'">{{ time || '-' }} </span
      ><span class="error-value" [attr.data-vef]="'card-delayedTime-label'">{{ timeError }}</span></span
    >
  </div>

  }
  <!-- End -->
  }
</ng-template>
