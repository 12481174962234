<div
  *ngIf="therapyJob"
  class="wrapper"
  [class.error]="mostCriticalStatus === notificationLevel.ERROR"
  (click)="openDetail()"
>
  <div class="header">
    <h4 class="name">
      <span
        [attr.data-vef]="'card-patientName'"
        *ngIf="therapyJob?.patient"
        [innerHtml]="therapyJob?.patient | personName | highlight : highlightTerm"
      >
      </span>
    </h4>
    <div class="header-left">
      <div class="badges">
        @if(therapyJob?.prepared && therapyJob?.status === therapyJobStatus.PRESCRIBED){

        <!-- Preparation -->
        <cw-badge-status
          *ngIf="configService.configuration()?.content?.general.allowPreparationMarking"
          [attr.data-vef]="'card-prepared-badge'"
          type="secondary"
          status="success"
          >{{ 'StatusPrepared' | translate }}</cw-badge-status
        >
        }

        <!-- Location -->
        @if(mostCriticalStatus === notificationLevel.ERROR){
        <cw-badge-status [status]="mostCriticalStatus">
          <cw-icon class="icon" icon="location_on" size="xs"></cw-icon> {{ therapyJob.patient?.careUnit?.room }} -
          {{ therapyJob.patient?.careUnit?.bed }}
        </cw-badge-status>

        } @else {

        <div [attr.data-vef]="'card-careUnit'" class="care-unit">
          <cw-icon class="icon" icon="location_on" size="xs"></cw-icon> {{ therapyJob.patient?.careUnit?.room }} -
          {{ therapyJob.patient?.careUnit?.bed }}
        </div>

        }
      </div>

      <gplus-therapy-job-menu
        class="menu"
        container="card"
        dataVefId="card-menu-toggle"
        [therapyJob]="therapyJob"
        (onDelete)="onDelete.emit($event)"
        (onUnpair)="onUnpair.emit($event)"
        (onAbort)="onAbort.emit($event)"
        (togglePrepared)="togglePrepared($event)"
        (toggleAnnotations)="toggleAnnotations($event)"
      ></gplus-therapy-job-menu>
    </div>
  </div>

  <gplus-therapy-job-body
    [therapyJob]="therapyJob"
    [highlightTerm]="highlightTerm"
    [timeError]="timeError"
  ></gplus-therapy-job-body>

  <div class="footer" [class.has-annotations]="therapyJob.annotations && !showAnnotations">
    <!-- Annotations -->
    <gplus-annotations
      class="annotations"
      [class.show]="showAnnotations"
      [value]="therapyJob.annotations"
      [showAnnotations]="showAnnotations"
      (onSubmit)="onSubmitAnnotations($event)"
      (toggle)="toggleAnnotations($event)"
    ></gplus-annotations>

    <!-- Progress Metrics -->
    @if(therapyJob?.status !== therapyJobStatus.PRESCRIBED ){

    <gplus-infusion container="card" [level]="mostCriticalStatus" [therapyJob]="therapyJob"></gplus-infusion>
    }

    <!-- Monitor device -->
    @if (therapyJob && (therapyJob?.status === therapyJobStatus.RUNNING || therapyJob?.status ===
    therapyJobStatus.PRESCRIBED) && therapyJob.pairingState !== pairingStatus.PAIRED) {

    <gplus-manage-device
      class="connect-selector"
      [therapyJob]="therapyJob"
      [event]="event"
      [level]="mostCriticalStatus"
    ></gplus-manage-device>
    }

    <!-- Move to history button -->
    @if(therapyJob?.status === therapyJobStatus.COMPLETE_CONFIRMED || therapyJob?.status === therapyJobStatus.ABORTED){
    <cw-button
      class="finalize-btn"
      button-color="secondary"
      variant="outlined"
      type="button"
      as="button"
      (click)="$event.stopPropagation(); onFinalize.emit(therapyJob)"
    >
      {{ 'TherapyJobFinishJobBtn' | translate }}
    </cw-button>

    }
  </div>

  <!-- Notifications -->
  @if ((tjNotificationMap | keyvalue)?.length) {

  <div class="notifications">
    @for (item of tjNotificationMap | keyvalue; track $index) {

    <gplus-inline-banner
      [noBackground]="mostCriticalStatus === notificationLevel.ERROR"
      [class.show-as-black-text]="
        mostCriticalStatus === notificationLevel.ERROR && item.value.level !== notificationLevel.ERROR
      "
      [status]="item.value.level"
      [label]="item.value.translationKey | translate"
    ></gplus-inline-banner>

    }
  </div>
  }
</div>
