import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray } from '@angular/forms';
import { CwList, CwRadioButton } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, iif, of, ReplaySubject, Subject, switchMap, take, tap } from 'rxjs';
import { TherapyJobFormClass } from '~/app/classes/TherapyJobFormClass';
import { medicineUnits } from '~/app/enums/MedicineUnits';
import { Configuration } from '~/app/models/Configuration';
import { Patient } from '~/app/models/generated/patient';
import { TherapyJobMedicine } from '~/app/models/generated/therapyJobMedicine';
import { Prescription } from '~/app/models/prescription';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJobFormRaw } from '~/app/models/TherapyJobForm';
import { PrescriptionService } from '~/app/services/prescription.service';
import { getTimeFromDate } from '~/app/utils/getTime';

export interface PrescriptionExtended extends Prescription {
  medicinesFormatted: string;
  dosesFormatted: string;
}

@Component({
  selector: 'gplus-therapies-on-demand',
  standalone: true,
  imports: [CommonModule, TranslateModule, CwList, CwRadioButton],
  templateUrl: './therapies-on-demand.component.html',
  styleUrl: './therapies-on-demand.component.scss'
})
export class TherapiesOnDemandComponent implements OnInit {
  @Input() formInstance: TherapyJobFormClass;
  @Input() data: SessionData;
  @Input() resetChange: EventEmitter<void>;
  @Input() configuration: Configuration;
  @Output() selected: EventEmitter<PrescriptionExtended | 'manual'> = new EventEmitter();

  public prescriptions: Array<PrescriptionExtended>;
  private selectedPrescription: PrescriptionExtended | 'manual';
  public dataLoader: boolean = null;
  public selectedValue: Subject<PrescriptionExtended | 'manual'> = new ReplaySubject(1);

  private destroyRef = inject(DestroyRef);

  constructor(private readonly prescriptionService: PrescriptionService) {}

  ngOnInit(): void {
    if (this.formInstance?.form) {
      this.subscribeToFormChanges();
      this.subscribeToTherapiesSelector();

      this.formInstance.setDisabledForm(true);
    }

    if (this.resetChange) {
      this.resetChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.select(this.selectedPrescription);
      });
    }
  }

  private subscribeToTherapiesSelector() {
    this.selectedValue
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((prescription: PrescriptionExtended | 'manual') => {
        this.selectedPrescription = prescription;
        this.select(prescription);

        this.selected.emit(prescription);
      });
  }

  private select(prescription: PrescriptionExtended | 'manual') {
    if (!prescription) {
      console.warn('No prescription selected');
      return;
    }

    if (prescription === 'manual') {
      this.formInstance.addMedicineControls();
      this.clearForm();
    } else {
      this.formInstance.addMedicineControls(prescription.medication.medicines);
      this.patchFormWithPrescription(prescription);
    }
  }

  private clearForm() {
    if (!this.formInstance?.form) {
      console.warn('No form instance found');
      return;
    }
    const value: TherapyJobFormRaw = {
      volume: null,
      medication: {
        carrier: null,
        count: null,
        order: null,
        medicines: []
      },
      flowRate: null,
      physician: null,
      startDate: new Date(),
      hours: getTimeFromDate(new Date()),
      annotations: null
    };

    this.formInstance.form.patchValue(value);
    this.formInstance.form.markAsPristine();
  }

  private patchFormWithPrescription(prescription: PrescriptionExtended) {
    if (!this.formInstance?.form) {
      console.warn('No form instance found');
      return;
    }
    const value: TherapyJobFormRaw = {
      volume: prescription.volume,
      medication: {
        ...prescription.medication.medicines,
        carrier: prescription.medication?.carrier,
        count: prescription.medication?.count,
        order: prescription.medication?.order,
        medicines: prescription.medication.medicines.map((m: TherapyJobMedicine) => ({
          medicine: m,
          customAmount: m.amount,
          unit: {
            id: medicineUnits.find((u) => u.label === m.unit)?.id,
            label: m.unit
          }
        }))
      },
      flowRate: prescription.flowRate,
      physician: this.data?.physicians?.find((p) => p.id === prescription.physician),
      startDate: new Date(),
      hours: getTimeFromDate(new Date()),
      annotations: null
    };

    this.formInstance.form.patchValue(value);
    this.formInstance.form.markAsPristine();

    // Disable medicine and units
    (this.formInstance.form.get('medication.medicines') as FormArray)?.controls?.forEach((control) => {
      control.get('medicine').disable();
      control.get('unit').disable();
    });
  }

  private subscribeToFormChanges() {
    this.formInstance?.form
      .get('patient')
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(300),
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
        switchMap((patient: Patient) => {
          this.dataLoader = true;

          // Selecting a patient should not mark as dirty the form
          this.formInstance.form.markAsPristine();

          // IF there is a patient selected, we get the prescriptions
          // ELSE we reset the data loader
          return iif(
            () => !!patient,
            // if there is a patient selected, we get the prescriptions
            this.prescriptionService.getOnDemandPrescriptions(patient?.id).pipe(
              take(1),
              tap((prescriptions: Array<Prescription>) => {
                this.dataLoader = false;
                this.prescriptions = this.formatPrnPrescriptionDetails(prescriptions);

                this.setDefault();

                this.formInstance.setDisabledForm(false);
              })
            ),
            // if there is no patient selected we reset the data loader
            of(null).pipe(tap(() => (this.dataLoader = null)))
          );
        })
      )
      .subscribe();
  }

  private setDefault() {
    if (this.configuration?.general?.allowManualJobs) {
      this.selectedValue.next('manual');
    } else if (this.prescriptions?.length) {
      this.selectedValue.next(this.prescriptions[0]);
    } else {
      this.selectedValue.next('manual');
    }
  }

  private formatPrnPrescriptionDetails(prescriptions: Array<Prescription>): Array<PrescriptionExtended> {
    return prescriptions.map((p) => {
      // we collect all the medicines names in a single string
      const medicinesFormatted = p?.medication?.medicines.reduce((acc, m, i, array) => {
        acc += `${m.display}${array.length - 1 === i ? '' : ', '}`;
        return acc;
      }, '');
      // we collect all the doses in a single string
      const dosesFormatted = p?.medication?.medicines.reduce((acc, m, i, array) => {
        acc += `${m.amount} ${m.unit}${array.length - 1 === i ? '' : ', '}`;
        return acc;
      }, '');
      return {
        ...p,
        medicinesFormatted,
        dosesFormatted
      };
    });
  }
}
