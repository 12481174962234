import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { EmployeeType } from '../enums/EmployeeType';
import { NotificationLevel } from '../enums/NotificationLevel';
import { Employee } from '../models/generated/employee';
import { EnvironmentService } from './environment.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private employeeObservable: Observable<Array<Employee>>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  public getEmployees(): Observable<Array<Employee>> {
    if (!this.employeeObservable) {
      this.employeeObservable = this.httpClient.get<Array<Employee>>(`${this.env.getBaseRestUrl()}/employee`).pipe(
        catchError((err) => {
          console.warn('Error requesting employees', err);
          this.toastGenericError();
          return of([]);
        }),
        shareReplay()
      );
    }

    return this.employeeObservable;
  }

  public getPhysicians(): Observable<Array<Employee>> {
    return this.getEmployees().pipe(
      map((employees) =>
        employees
          .filter((e) => e.types.includes(EmployeeType.PHYSICIAN))
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
      )
    );
  }

  public employeeExists(value: string | Employee): Observable<boolean> {
    if (!value) {
      return of(false);
    }

    const id = value === 'string' ? value : (value as Employee).id;
    return this.getEmployees().pipe(map((employees) => employees.some((e) => e.id === id)));
  }

  public getEmployeeTypes(userId: string): Observable<Array<EmployeeType>> {
    return this.getEmployees().pipe(
      map((employees) => {
        const user = employees.find((employee) => employee.id === userId);
        return (user?.types as Array<EmployeeType>) || [];
      })
    );
  }

  private toastGenericError(): void {
    this.toastService.show({
      message: this.translateService.instant('ErrorGeneric'),
      type: NotificationLevel.ERROR,
      enableCloseButton: true,
      delay: 10_000
    });
  }
}
