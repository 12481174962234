import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SseClient } from 'ngx-sse-client';
import { finalize, Observable, ReplaySubject, Subject, Subscription, switchMap, take, takeUntil } from 'rxjs';
import { TEMP_SITE_ID } from '../interceptors/auth-interceptor';
import { SseInfusion, SsePairing, SseState } from '../models/sse-event';
import { EnvironmentService } from './environment.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public eventSource: Subject<any>;

  private sseEventsSub: Subject<any> = new ReplaySubject(1);
  private destroySse: Subject<void> = new Subject();

  // Temporarily to be able to show a loader when the simulation starts
  public onTJStarts: Subject<void> = new ReplaySubject(1);
  public sseObs$: Subscription;

  constructor(
    private readonly translateService: TranslateService,
    private readonly env: EnvironmentService,
    private readonly sseClient: SseClient,
    private readonly userService: UserService
  ) {
    this.connect();
  }

  public subscribeToServerSentEvents(): Observable<SseInfusion | SsePairing | SseState> {
    return this.sseEventsSub.asObservable();
  }

  private connect() {
    this.sseObs$ = this.userService
      .getMe()
      .pipe(
        take(1),
        switchMap((me) => {
          const headers = new HttpHeaders().set('BB-Site-Id', TEMP_SITE_ID).set('BB-Unit-Id', me.id);

          return this.sseClient
            .stream(
              `${this.env.getBaseRestUrl()}/api/events`,
              { keepAlive: true, reconnectionDelay: 1_000, responseType: 'event' },
              { headers },
              'GET'
            )
            .pipe(
              takeUntil(this.destroySse),
              finalize(() => console.log('Finalize sseClient.stream'))
            );
        })
      )
      .subscribe((event: any) => {
        if (event.type === 'error') {
          const errorEvent = event as ErrorEvent;
          console.error(errorEvent.error, errorEvent.message);
          this.showErrorMessage(errorEvent.error);
        } else {
          const messageEvent = event as MessageEvent;

          if (messageEvent.data && typeof messageEvent.data === 'string') {
            this.sseEventsSub.next({ ...JSON.parse(messageEvent.data), type: messageEvent.type });
          }
        }
      });
  }

  public close(): void {
    this.destroySse.next();
  }

  private showErrorMessage(error) {
    const snackbarConfig = {
      type: 'error',
      message: this.translateService.instant('ConnectionLostMessage'),
      enableCloseButton: true,
      delay: 10000
    };
    window.dispatchEvent(new CustomEvent('cx-snackbar-open', { detail: snackbarConfig }));
    console.error(`There is an error with the new EventSource('/api/events')`, error);
  }
}
