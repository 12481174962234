export const environment: any = {
  id: 'dev',
  production: false,
  msalConfig: {
    redirectUri: 'https://grp.dev-bbrauncloud.com',
    postLogoutUrl: 'https://grp.dev-bbrauncloud.com',
    auth: {
      clientId: '08626c06-f48f-4128-a858-a4b86bd9e420'
    }
  },
  gprApiConfig: {
    scopes: ['openid', 'offline_access', 'https://test.account.bbraun.com/auth/gpr/User.Read'],
    uri: 'https://api.myprofile.test.account.bbraun.com/api/'
  },
  gravityApiConfig: {
    scopes: ['openid', 'offline_access', 'https://test.account.bbraun.com/119b8279-4b58-43ee-813f-4e87edfd75e2/Read'],
    uri: 'https://grp.dev-bbrauncloud.com/api/'
  }
};
