import { Injectable, Signal, signal, WritableSignal } from '@angular/core';

export interface Header {
  showSearch?: boolean;
  showBackBtn?: boolean;
  titleKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private readonly signal: WritableSignal<Header> = signal(null);
  public header: Signal<Header> = this.signal.asReadonly();

  private readonly toggleMobileMenuSignal: WritableSignal<boolean> = signal(null);
  public toggleMobileMenu: Signal<boolean> = this.toggleMobileMenuSignal.asReadonly();

  public setHeader(header: Header) {
    this.signal.set(header);
  }

  public setMobileMenuBtn(value: boolean) {
    this.toggleMobileMenuSignal.set(value);
  }
}
