import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { EmployeeService } from '../services/employee.service';

export class PhysicianValidator {
  static createValidator(service: EmployeeService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return service
        .employeeExists(control.value)
        .pipe(map((result: boolean) => (result ? null : { physicianNotExist: true })));
    };
  }
}
