import { CommonModule, NgComponentOutlet } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { CxSidebar } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { Sidebar, SidebarService } from '~/app/services/sidebar.service';

@Component({
  selector: 'gplus-sidebar-container',
  standalone: true,
  imports: [CxSidebar, TranslateModule, NgComponentOutlet, CommonModule, CustomShadowCssDirective],
  templateUrl: './sidebar-container.component.html',
  styleUrl: './sidebar-container.component.scss'
})
export class SidebarContainerComponent implements AfterViewInit {
  @Input() sidebar: Sidebar;

  constructor(private readonly sidebarService: SidebarService) {}

  ngAfterViewInit(): void {
    const cxSidebar = document.querySelector('cx-sidebar');
    if (!cxSidebar) {
      console.warn('cx-sidebar has not been found');
      return;
    }

    fromEvent(cxSidebar, 'sidebarClose').subscribe(() => {
      this.sidebarService.close();
    });
  }
}
