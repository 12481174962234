import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CxButton, CxIcon, CxMenu } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, debounceTime, map, of, take } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { EmployeeType } from '~/app/enums/EmployeeType';
import { CarrierService } from '~/app/services/carrier.service';
import { EmployeeService } from '~/app/services/employee.service';
import { MedicineService } from '~/app/services/medicine.service';
import { PatientService } from '~/app/services/patient.service';
import { SessionService } from '~/app/services/session.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { CxCheckboxExtendedComponent } from '~/app/utils/CxCheckboxExtended';
import { groupGenerator } from '~/app/utils/FormGroupGenerator';
import { countFiltersApplied } from '~/app/utils/filtersApplied';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { FilterConfig, FilterControl, filtersConfig } from './filters-config';

@Component({
  selector: 'gplus-filters',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    CxCheckboxExtendedComponent,
    CxMenu,
    CxButton,
    CxIcon,
    TranslateModule,
    CustomShadowCssDirective,
    SearchBarComponent
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss'
})
export class FiltersComponent {
  public filterControl = FilterControl;
  public filters: Array<FilterConfig> = filtersConfig.filter((f) => f.show);

  public dataLoader: boolean = true;

  public form: FormGroup;

  public totalFiltersApplied: number;

  public jobsStore = inject(TherapyJobStore);

  constructor(
    private readonly sessionService: SessionService,
    private readonly patientService: PatientService,
    public readonly carrierService: CarrierService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService
  ) {
    this.form = groupGenerator(this.filters);

    // We subscribe to the changes in the filters
    this.form.valueChanges.pipe(takeUntilDestroyed(), debounceTime(300)).subscribe((filters) => {
      this.totalFiltersApplied = countFiltersApplied(filters);

      let newQueries = [];

      if (this.totalFiltersApplied) {
        newQueries = Object.keys(filters).reduce((acc, key) => {
          const filterConfig = this.filters.find((f) => f.id === key);

          if (filters[key] === null) {
            return acc;
          }

          const bindValueKey = filterConfig.bindValue;

          if (!bindValueKey || typeof filters[key] === 'string' || typeof filters[key] === 'number') {
            return acc.concat([
              {
                on: key,
                bindValue: filters[key],
                value: filters[key]
              }
            ]);
          }

          let bindValue;
          if (Array.isArray(filters[key])) {
            bindValue = filters[key]?.map((d) => d[filterConfig.bindValue]);
          } else {
            bindValue = filters[key][filterConfig.bindValue];
          }

          return acc.concat([
            {
              on: key,
              bindValue,
              value: filters[key]
            }
          ]);
        }, []);
      }

      const searchQuery = this.jobsStore.filter().queries.find((q) => q.on === 'search');
      this.jobsStore.updateFilter({
        queries: searchQuery ? newQueries.concat(searchQuery) : newQueries
      });
    });

    combineLatest([
      this.employeeService.getEmployees(EmployeeType.PHYSICIAN),
      this.patientService.getPatients(this.sessionService.currentWard),
      this.medicineService.getMedicines(this.sessionService.currentWard),
      this.carrierService.getCarriers(this.sessionService.currentWard)
    ])
      .pipe(
        take(1),
        map(([physician, patient, medicine, carrier]) => {
          return {
            physician,
            patient,
            medicine,
            carrier,
            room: Array.from(new Set(patient.map((p) => p.careUnit.room))).sort((a, b) => a.localeCompare(b))
          };
        })
      )
      .subscribe((dataMap) => {
        this.dataLoader = false;
        const filterValuesMap = this.jobsStore.filter().queries.reduce((map, f) => {
          map[f.on] = f.value;
          return map;
        }, {});

        // Patch values if there were any filters active
        const valuesPatched: { [filterId: string]: any } = this.filters.reduce((map, filter) => {
          if (filterValuesMap[filter.id] !== undefined) {
            map[filter.id] = filterValuesMap[filter.id];
          }
          return map;
        }, {});

        this.form.patchValue(valuesPatched);

        this.filters.forEach((filter) => {
          // Filter and subscribing to changes for autocomplete
          if (filter.control === FilterControl.SELECTOR) {
            filter.filteredOptions = of(dataMap[filter.id]);
          }

          if (valuesPatched[filter.id]) {
            this.form.get(filter.id).markAsDirty();
          }
        });
      });
  }

  public resetAllFilters() {
    this.form.reset();
    this.jobsStore.updateFilter({ queries: [] });
  }

  public cleanSelector(control: AbstractControl, event: MouseEvent) {
    event.stopPropagation();
    control.reset();
    control.markAsPristine();
  }

  public compareWith(option, value) {
    if (typeof option === 'string') {
      return option === value;
    }
    return option.id === value?.id;
  }
}
