import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { CxChip } from '@bbraun/cortex-angular';
import { CxSearchBarModule } from '@bbraun/cortex/search-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, take } from 'rxjs';
import { Patient } from '~/app/models/generated/patient';
import { Prescription } from '~/app/models/prescription';
import { DurationFormatPipe, TimeUnit } from '~/app/pipes/DurationFormat';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { EventsService } from '~/app/services/events.service';
import { HeaderService } from '~/app/services/header.service';
import { PrescriptionService } from '~/app/services/prescription.service';
import { SessionService } from '~/app/services/session.service';
import { compare } from '~/app/utils/compare';
import { ComponentSSE } from '~/app/utils/ComponentSSE';
import { GplusCellDirective } from '../table/directives/cellDirective.directive';
import { GplusTableColumn, TableComponent } from '../table/table.component';

@Component({
  selector: 'gplus-create-therapy-job-on-demand',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CxSearchBarModule,
    TableComponent,
    CxChip,

    // Directives
    GplusCellDirective,

    // Pipes
    PersonNamePipe
  ],
  templateUrl: './create-therapy-job-on-demand.component.html',
  styleUrl: './create-therapy-job-on-demand.component.scss'
})
export class CreateTherapyJobOnDemandComponent extends ComponentSSE {
  public columnOrder = ['patient', 'medicine', 'dose', 'perDay', 'actions'];

  private onDemandPrescriptions: Array<Prescription & { available: boolean; message: string }>;

  public filterValues: Array<Prescription>;
  public selectedValue: Subject<Patient> = new Subject();

  public dataLoader: boolean;
  public columns: Array<GplusTableColumn> = [
    { translationKey: 'TablePRNPatient', bindValue: 'patient', useAsHeader: true },
    { translationKey: 'TablePRNMedication', bindValue: 'medication' },
    { translationKey: 'TablePRNDose', bindValue: 'dose' },
    { translationKey: 'TablePRNAvailable', bindValue: 'available' },
    null
  ];

  constructor(
    private readonly prescriptionService: PrescriptionService,
    protected override translateService: TranslateService,
    protected readonly sessionService: SessionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly headerService: HeaderService,
    protected override eventsService: EventsService
  ) {
    super(eventsService, translateService);

    this.headerService.setHeader({
      titleKey: this.route.snapshot?.data?.titleKey,
      showBackBtn: false,
      showSearch: false
    });

    this.dataLoader = true;

    this.prescriptionService
      .getOnDemandPrescriptions(this.sessionService.currentWard)
      .pipe(take(1))
      .subscribe((prescriptions) => {
        this.onDemandPrescriptions = prescriptions
          .sort((a, b) => a.patient.lastName.localeCompare(b.patient.lastName))
          .map((p) => {
            return {
              ...p,
              ...this.getAvailability(p)
            };
          })
          .filter((p) => p.available);

        this.filterValues = this.onDemandPrescriptions;
        this.dataLoader = false;
      });
  }

  public search(value: string) {
    if (!value) {
      this.filterValues = this.onDemandPrescriptions;
      return;
    }

    this.filterValues = this.onDemandPrescriptions.filter(
      (p) =>
        p?.patient?.lastName?.toLowerCase().startsWith(value.toLowerCase()) ||
        p?.patient?.firstName?.toLowerCase().startsWith(value.toLowerCase())
    );
  }

  public setSearchBarValue(event: Patient): void {
    this.selectedValue.next(event);
  }

  public selected(id: string) {
    this.router.navigate(['/detail', { prn: id }]);
  }

  public closeTherapyForm() {
    this.selected(null);
    this.search(null);
  }

  private getAvailability(prescription: Prescription): { available: boolean; message: string } {
    // if the full dosage has being administrated
    if (prescription.medication.count === prescription.medication.order) {
      return {
        available: false,
        message: this.translateService.instant('OnDemandTJCreationMaxDoseExceeded')
      };

      //  if the prescription has expired
    } else if (new Date(prescription.expiration).getTime() < Date.now()) {
      return {
        available: false,
        message: this.translateService.instant('OnDemandTJCreationPrescriptionHasExpired')
      };

      // if there is waiting time
    } else if (new Date(prescription.availableIn).getTime() - Date.now() > 0) {
      return {
        available: false,
        message:
          this.translateService.instant('OnDemandTJCreationPrescriptionWillBeAvailableIn') +
          ' ' +
          new DurationFormatPipe(this.translateService).transform(
            new Date(prescription.availableIn).getTime(),
            TimeUnit.MILLISECONDS
          )
      };
    }

    return {
      available: true,
      message: null
    };
  }

  public sortData(sort: Sort) {
    if (!sort.active || sort.direction === '' || !this.filterValues) {
      return;
    }
    const data = JSON.parse(JSON.stringify(this.filterValues));

    this.filterValues = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'patient':
          return compare(a.patient.lastName, b.patient.lastName, isAsc);
        case 'medicine':
          return compare(a.medication?.medicines?.[0]?.display, b.medication?.medicines?.[0]?.display, isAsc);
        default:
          return 0;
      }
    });
  }
}
