import { NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormsModule } from '@angular/forms';
import { CwDropdown, CwDropdownItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { labelType, PrintLabelPipe } from '~/app/pipes/PrintLabel';

@Component({
  selector: 'gplus-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [
    CwDropdown,
    CwDropdownItem,
    PrintLabelPipe,
    FormsModule,
    CustomShadowCssDirective,
    NgTemplateOutlet,
    TranslateModule
  ]
})
export class DropdownComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() data: Array<{ id: string; attrVef?: string; disabled?: boolean }>;
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() attrVef: string;
  @Input() validations: Record<string, any> = {};
  @Input() labelType: labelType;
  @Input() loading: boolean;

  @Input() label: string;
  @HostBinding('class.no-label') get noLabel() {
    return !this.label;
  }

  public error: string;
  public inputTextValue: any | Array<any>;

  // set styles for the first level of shadowRoot
  public shadowStyles = `cw-menu{width:100%!important;display:block!important;}
  .dropdown-label{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
  .dropdown-input{height:48px!important;}`;

  public deepShadow: { selector: string; styles: string };
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    // Set styles for the second level of shadowRoot
    this.deepShadow = {
      selector: 'cw-menu',
      styles: `.menu{width:100%!important;z-index:3!important}.menu-trigger{width:100%!important}div:has(.menu){position:relative!important;}
    `
    };

    if (this.control) {
      this.inputTextValue = this.findValues(this.control?.value);

      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.inputTextValue = this.findValues(this.control?.value);

        if (this.control.pristine) {
          this.error = null;
          return;
        }

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }

  public selected(selection: Record<string, unknown>) {
    if (!this.control) {
      return;
    }

    this.control.setValue(selection);
    this.control.markAsDirty();
  }

  private findValues(controlValue: any): Array<any> | any {
    if (controlValue === null) {
      return this.multiple ? [] : null;
    }

    if (Array.isArray(this.control.value)) {
      return this.data?.filter((d) => controlValue?.findIndex((v) => v.id === d.id) !== -1);
    } else {
      return this.data?.filter((d) => d === controlValue);
    }
  }
}
