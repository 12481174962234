import { signal, WritableSignal } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TherapyJobForm } from '../models/TherapyJobForm';
import { TherapyJobMedicine } from '../models/generated/therapyJobMedicine';
import { EmployeeService } from '../services/employee.service';
import { MedicineService } from '../services/medicine.service';
import { PatientService } from '../services/patient.service';
import { DataTypeValidator } from '../utils/DataTypeValidator';
import { MedicineUnitValidator } from '../utils/MedicineUnitValidator';
import { getTimeFromDate } from '../utils/getTime';
import { MedicineValidator } from '../utils/medicineValidator';
import { PatientValidator } from '../utils/patientValidator';
import { PhysicianValidator } from '../utils/physicianValidator';

const ANNOTATION_MAX_LENGTH = 1000;
export const ABORT_REASON_MAX_LENGTH = 255;
export const ONLY_NOTES_MAX_LENGTH = ANNOTATION_MAX_LENGTH - ABORT_REASON_MAX_LENGTH;

export class TherapyJobFormClass {
  private therapyJobForm: any;

  private disabledFormSignal: WritableSignal<boolean> = signal(null);
  public disabledForm = this.disabledFormSignal.asReadonly();

  constructor(
    private readonly patientService: PatientService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService
  ) {
    this.therapyJobForm = new FormGroup<TherapyJobForm>({
      patient: new FormControl(null, [Validators.required], [PatientValidator.createValidator(this.patientService)]),
      volume: new FormControl(null, [Validators.required, Validators.min(0), DataTypeValidator.isNumber()]),
      flowRate: new FormControl(null, [Validators.required, Validators.min(0), DataTypeValidator.isNumber()]),
      startDate: new FormControl(new Date(), [Validators.required]),
      hours: new FormControl(getTimeFromDate(new Date()), [Validators.required]),
      physician: new FormControl(
        null,
        [Validators.required],
        [PhysicianValidator.createValidator(this.employeeService)]
      ),
      annotations: new FormControl(null, Validators.maxLength(ONLY_NOTES_MAX_LENGTH)),
      medication: new FormGroup({
        medicines: new FormArray([
          new FormGroup({
            medicine: new FormControl(
              null,
              [Validators.required],
              [MedicineValidator.createValidator(this.medicineService)]
            ),
            customAmount: new FormControl(null, [Validators.required, Validators.min(0), DataTypeValidator.isNumber()]),
            unit: new FormControl(null, [Validators.required, MedicineUnitValidator.unitNotExist()])
          })
        ]),
        carrier: new FormControl(null, [Validators.required]),
        count: new FormControl(),
        order: new FormControl()
      }),
      prepared: new FormControl()
    });
  }

  get form(): FormGroup<TherapyJobForm> {
    return this.therapyJobForm;
  }

  public addMedicineControls(prescribedMedicines?: Array<TherapyJobMedicine>): void {
    const medicines = this.form.get('medication.medicines') as FormArray;
    medicines.clear();

    if (!prescribedMedicines) {
      medicines.push(
        new FormGroup({
          medicine: new FormControl(
            null,
            [Validators.required],
            [MedicineValidator.createValidator(this.medicineService)]
          ),
          customAmount: new FormControl(null, [Validators.required, Validators.min(0), DataTypeValidator.isNumber()]),
          unit: new FormControl(null, [Validators.required, MedicineUnitValidator.unitNotExist()])
        })
      );

      return;
    }

    const controls = prescribedMedicines.map((m) => {
      return new FormGroup({
        medicine: new FormControl(
          null,
          [Validators.required],
          [MedicineValidator.createValidator(this.medicineService)]
        ),
        customAmount: new FormControl(null, [
          Validators.required,
          Validators.max(m.amount),
          Validators.min(0),
          DataTypeValidator.isNumber()
        ]),
        unit: new FormControl(null, [Validators.required, MedicineUnitValidator.unitNotExist()])
      });
    });

    controls.forEach((control) => {
      medicines.push(control);
    });

    this.form.updateValueAndValidity();
  }

  public disable(listName: Array<string>) {
    listName.forEach((controlName) => {
      this.form.get(controlName).disable();
    });
  }

  public setDisabledForm(value: boolean) {
    this.disabledFormSignal.set(value);
  }
}
