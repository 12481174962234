import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EntityMap } from '@ngrx/signals/entities';
import { debounceTime, ReplaySubject, Subject } from 'rxjs';
import { medicineUnits } from '~/app/enums/MedicineUnits';
import { TherapyJobMedicine } from '~/app/models/generated/therapyJobMedicine';
import { SessionData } from '~/app/models/SessionData';
import { SsePairing } from '~/app/models/sse-event';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobForm, TherapyJobFormRaw } from '~/app/models/TherapyJobForm';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { FilterNotificationPipe } from '~/app/pipes/FilterNotifications';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { NotificationsStore } from '~/app/store/notification.store';
import { getTimeFromDate } from '~/app/utils/getTime';
import { DetailPageBodyComponent } from '../detail-page-body/detail-page-body.component';
import { DetailPageSidebarComponent } from '../detail-page-sidebar/detail-page-sidebar.component';
import { DetailPageStatus } from '../detail-page.component';

@Component({
  selector: 'gplus-detail-page-view',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DetailPageBodyComponent,
    DetailPageSidebarComponent,
    FilterNotificationPipe
  ],
  templateUrl: './detail-page-view.component.html',
  styleUrls: ['../common/page.scss']
})
export class DetailPageViewComponent implements OnInit {
  public jobsStore = inject(TherapyJobStore);
  public notificationStore = inject(NotificationsStore);

  private patchSub: Subject<void> = new ReplaySubject();

  @Input() form: FormGroup<TherapyJobForm>;
  @Input() data: SessionData;
  @Input() pageStatus: DetailPageStatus;
  @Input() therapyJob: TherapyJob;
  @Input() ticker: number;
  @Input() tJNotificationsMap: EntityMap<TherapyJobNotification>;
  @Input() event: SsePairing;

  public submitLoader: boolean;

  constructor(private readonly therapyJobService: TherapyJobService) {}

  ngOnInit(): void {
    this.patchSub.pipe(debounceTime(100)).subscribe(() => {
      this.patch();
    });
    this.patchSub.next();
    this.disableFields();
  }

  private disableFields() {
    if (!this.form) {
      return;
    }

    this.form.get('medication').disable();
    this.form.get('patient').disable();
    this.form.get('physician').disable();
    this.form.get('flowRate').disable();
    this.form.get('startDate').disable();
    this.form.get('hours').disable();
    this.form.get('volume').disable();
  }

  public submitChange() {
    if (this.form.valid) {
      this.submitLoader = true;

      const payload: Partial<TherapyJob> = {
        prepared: this.form.getRawValue().prepared,
        annotations: this.form.getRawValue().annotations
      };

      this.therapyJobService.updateTherapyJob(payload, this.therapyJob.id).subscribe((res) => {
        if ((res as { error: string })?.error) {
          // something went wrong we reload
          this.jobsStore.loadAll().then(() => this.notificationStore.setAll());
          this.patchSub.next();
        } else {
          this.jobsStore.patchJob(payload, this.therapyJob.id);
          console.info('Therapy Job successfully edited');
          this.form.markAsPristine();
        }

        this.submitLoader = false;
      });
    }
  }

  public resetChange() {
    this.patchSub.next();
  }

  private patch() {
    const medicines = this.therapyJob.medication.medicines.map((m: TherapyJobMedicine) => {
      return {
        medicine: m,
        customAmount: m.amount,
        unit: {
          id: medicineUnits.find((u) => u.label === m.unit)?.id,
          label: m.unit
        }
      };
    });

    const startDatePlanned = this.therapyJob?.startTime?.planned ? new Date(this.therapyJob.startTime.planned) : null;

    const value: TherapyJobFormRaw = {
      patient: this.therapyJob.patient,
      volume: this.therapyJob.totalVolume,
      medication: {
        ...this.therapyJob.medication,
        carrier: this.therapyJob.medication.carrier,
        medicines
      },
      flowRate: this.therapyJob.flowRate.planned,
      annotations: this.therapyJob.annotations,
      startDate: startDatePlanned,
      hours: startDatePlanned ? getTimeFromDate(startDatePlanned) : null,
      physician: this.data?.physicians?.find((p) => p.id === this.therapyJob.physician),
      prepared: this.therapyJob.prepared
    };

    this.form.patchValue(value);
    this.form.markAsPristine();
  }
}
