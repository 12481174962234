<div class="header">
  <div class="header-main">
    @if(patient?.lastName) {
    <h2 class="header-main-name">
      {{ patient | personName }}
      <cw-icon *ngIf="genderIcon" [icon]="genderIcon" size="md" [filled]="false"></cw-icon>
    </h2>
    } @else{
    <h2 class="header-main-name disabled">{{ 'DetailPagePatientName' | translate }}</h2>
    }

    <div class="header-badges">
      <cw-badge-status *ngIf="headerBadge?.type" [type]="headerBadge.type" [status]="headerBadge.status">
        {{ 'Columns' + headerBadge?.label | translate }}
      </cw-badge-status>

      <cw-badge-status type="secondary" *ngIf="isCustomJob">{{ 'CustomJob' | translate }}</cw-badge-status>
    </div>
  </div>

  <div class="header-sub b1">
    <div [class.disabled]="!patient" class="header-sub-item">
      <span> {{ 'DetailPageJobIdLabel' | translate }}: </span>
      <span>
        {{ therapyJobId || '-' }}
      </span>
    </div>
    <div class="divider"></div>
    <div [class.disabled]="!patient" class="header-sub-item">
      <span> {{ 'DetailPageBirthLabel' | translate }}:</span>
      <span> {{ patient?.birthDate || '-' }}</span>
    </div>
    <div class="divider"></div>
    <div [class.disabled]="!patient" class="header-sub-item">
      <span>{{ 'DetailPageLocationLabel' | translate }}:</span>
      <span>
        @if(patient?.careUnit?.room){
        {{ patient.careUnit.room + ' - ' + patient.careUnit.bed || '-' }}
        } @else { - }</span
      >
    </div>
  </div>
</div>
