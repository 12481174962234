import { Injectable, signal, WritableSignal } from '@angular/core';
import { Subject } from 'rxjs';
import { ContextModalComponent } from '../components/context-modal/context-modal.component';
import { FinalizeModalComponent } from '../components/finalize-modal/finalize-modal.component';
import { TherapyJobExtended } from '../models/TherapyJob';

export enum DialogIds {
  LEAVE_WITH_UNSAVED = 'leaveWithUnsaved',
  CONTEXT_SELECTOR = 'contextSelector',
  RESTORE_CONFIG_WARN = 'restoreConfigWarn',
  DELETE_TJ = 'deleteTJ',
  FINALIZE = 'finalize'
}

export interface Dialog {
  open: boolean;
  id: DialogIds;
  config: {
    title: string;
    actionLabel: string;
    secondaryLabel: string;
    content: string;
  };
  component: any;
  confirm: Subject<boolean | any>;
  actionBtnColor?: string;
  data?: TherapyJobExtended;
}

export const dialogs: Array<Dialog> = [
  {
    open: false,
    id: DialogIds.LEAVE_WITH_UNSAVED,
    config: {
      title: 'DialogUnsavedChangesTitle',
      actionLabel: 'DialogUnsavedChangesActionLabel',
      secondaryLabel: 'DialogUnsavedChangesCancelLabel',
      content: 'DialogUnsavedChangesContent'
    },
    confirm: new Subject(),
    component: null
  },
  {
    open: false,
    id: DialogIds.CONTEXT_SELECTOR,
    config: {
      title: 'WardSelectorModalTitle',
      actionLabel: null,
      secondaryLabel: null,
      content: null
    },
    confirm: new Subject(),
    component: ContextModalComponent
  },
  {
    open: false,
    id: DialogIds.RESTORE_CONFIG_WARN,
    config: {
      title: 'ConfigPageRestoreConfigWarnTitle',
      actionLabel: 'ConfigPageRestoreConfigConfirm',
      secondaryLabel: 'ConfigPageRestoreConfigCancel',
      content: 'ConfigPageRestoreConfigWarnMsm'
    },
    confirm: new Subject(),
    component: null
  },
  {
    open: false,
    id: DialogIds.DELETE_TJ,
    config: {
      title: 'TherapyJobDeletionTitle',
      actionLabel: 'TherapyJobDeletionDelete',
      secondaryLabel: 'TherapyJobDeletionCancel',
      content: 'TherapyJobDeletionConfirm'
    },
    confirm: new Subject(),
    component: null,
    actionBtnColor: 'error'
  },
  {
    open: false,
    id: DialogIds.FINALIZE,
    config: {
      title: 'TherapyJobFinishTitle',
      actionLabel: null,
      secondaryLabel: null,
      content: null
    },
    confirm: new Subject(),
    component: FinalizeModalComponent
  }
];

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogSignal: WritableSignal<Dialog> = signal(null);
  public dialog = this.dialogSignal.asReadonly();

  public open(id: DialogIds, data?: any): void {
    const dialog = dialogs.find((d) => d.id === id);
    this.dialogSignal.update(() => ({ ...dialog, open: true, data }));
  }

  public close() {
    this.dialogSignal.update((current) => ({ ...current, open: false }));
  }
}
