import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { Configuration } from '../models/Configuration';
import { EnvironmentService } from './environment.service';
import { ToastService } from './toast.service';

export interface ConfigurationResponse {
  content?: Configuration;
  isDefault?: boolean;
  error?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configurationSignal: WritableSignal<ConfigurationResponse> = signal(null);
  public configuration = this.configurationSignal.asReadonly();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  public getConfig(): Observable<ConfigurationResponse> {
    return this.httpClient.get<ConfigurationResponse>(`${this.env.getBaseRestUrl()}/config`).pipe(
      tap((res) => this.configurationSignal.set(res)),
      catchError((err) => {
        console.warn('Error requesting configuration', err);
        this.showToast(this.translateService.instant('ConfigPageErrorRequestingConfig'), NotificationLevel.ERROR);
        return of({ error: true });
      })
    );
  }

  public putConfig(config: Configuration): Observable<ConfigurationResponse> {
    return this.httpClient.put<any>(`${this.env.getBaseRestUrl()}/config`, config).pipe(
      switchMap(() => {
        return this.getConfig();
      }),
      catchError((err) => {
        console.warn('Error saving the configuration', err);
        this.showToast(this.translateService.instant('ConfigPageErrorSavingConfig'), NotificationLevel.ERROR);
        return of({ error: true });
      })
    );
  }

  public resetConfig(): Observable<ConfigurationResponse> {
    return this.httpClient.delete(`${this.env.getBaseRestUrl()}/config`).pipe(
      switchMap(() => {
        this.showToast(this.translateService.instant('ConfigPageSuccessRestoringConfig'), NotificationLevel.SUCCESS);
        return this.getConfig();
      }),
      catchError((err) => {
        console.warn('Error deleting the configuration', err);
        this.showToast(this.translateService.instant('ConfigPageErrorRestoringConfig'), NotificationLevel.ERROR);
        return of({ error: true });
      })
    );
  }

  private showToast(message, type) {
    this.toastService.show({
      message,
      type,
      enableCloseButton: true,
      delay: 20_000
    });
  }
}
