import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CxButton, CxIcon } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, catchError, of, take } from 'rxjs';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { MonitorDevice } from '~/app/models/MonitorDevice';
import { ResponseError, ResponseErrorExtended } from '~/app/models/ResponseError';
import { SseEventMessageKey } from '~/app/models/sse-event';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { MonitorDeviceService } from '~/app/services/monitor-device.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';

interface Option {
  id: string;
  key?: string;
  isBatteryLow?: boolean;
}

interface Group {
  key: string;
  options: Array<Option>;
}

const AUTO_PAIR_ID = 'autoPair';
@Component({
  selector: 'gplus-manage-device',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    CxButton,
    CxIcon
  ],
  templateUrl: './manage-device.component.html',
  styleUrl: './manage-device.component.scss'
})
export class ManageDeviceComponent implements OnChanges, OnDestroy {
  @Input() therapyJob: TherapyJobExtended;

  // Assistive text
  public message: {
    type: 'error' | 'warning' | 'info' | 'loading' | 'success';
    text: string;
  };

  public defaultOption: Option = {
    id: AUTO_PAIR_ID,
    key: 'AutoPairMonitorDevice'
  };

  public activeDevices: Group = {
    key: 'ActiveDevices',
    options: []
  };

  public loadingActiveDevices: boolean;
  public selected: Option;

  public showCancel: boolean;
  public processingCancel: boolean;
  public disableSelector: boolean;
  public deviceId: string;

  // public enum
  public pairingStatus = PairingStatus;
  public therapyJobStatus = TherapyJobStatus;

  private destroy: Subject<void> = new Subject();

  constructor(
    private readonly monitorDeviceService: MonitorDeviceService,
    private readonly translateService: TranslateService,
    private readonly therapyJobService: TherapyJobService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.therapyJob) {
      this.setSelector();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private setSelector() {
    switch (this.therapyJob?.pairingState) {
      case PairingStatus.UN_PAIRED:
        this.message = null;
        if (this.therapyJob.monitorDeviceId) {
          this.message = {
            type: 'info',
            text: this.translateService.instant('PairingRejectedFromMonitorDevice', {
              deviceId: this.therapyJob.monitorDeviceId
            })
          };

          // after some time we hide the message
          setTimeout(() => {
            this.message = null;
          }, 3000);
        }

        this.selected = null;
        this.showCancel = false;
        this.disableSelector = false;
        this.deviceId = null;

        break;
      case PairingStatus.IN_PAIRING:
        this.message = {
          type: 'info',
          text: this.translateService.instant('WaitingForPairingConfirmation', {
            deviceId: this.therapyJob.monitorDeviceId
          })
        };

        this.showCancel = true;
        this.disableSelector = true;
        this.deviceId = this.therapyJob.monitorDeviceId;

        break;
      case PairingStatus.PAIRED:
        this.message = {
          type: 'success',
          text: this.translateService.instant('PairingCompleted', { deviceId: this.therapyJob.monitorDeviceId })
        };

        setTimeout(() => {
          this.selected = null;
          this.showCancel = null;
          this.disableSelector = true;
          this.deviceId = this.therapyJob.monitorDeviceId;
        }, 1000);

        break;
      case null:
        this.message =
          this.therapyJob?.messageKey === SseEventMessageKey.PAIRING_FAILED
            ? {
                type: 'error',
                text: this.translateService.instant('DeviceNotFound')
              }
            : null;

        this.selected = null;
        this.showCancel = false;
        this.disableSelector = false;
        this.deviceId = null;

        break;
    }
  }

  public connectMonitorDevice() {
    this.disableSelector = true;
    this.message = {
      type: 'loading',
      text: this.translateService.instant('SearchingMonitorDevice')
    };

    const pairWith = this.selected.id !== AUTO_PAIR_ID ? this.selected.id : null;
    this.message = {
      type: 'loading',
      text: this.translateService.instant(pairWith ? 'PairingInProgress' : 'SearchingMonitorDevice')
    };

    this.monitorDeviceService
      .pair(this.therapyJob?.id, pairWith)
      .pipe(
        take(1),
        catchError((e) => {
          this.message = {
            type: 'error',
            text: this.translateService.instant('DeviceNotFound')
          };
          this.selected = null;
          this.showCancel = false;
          this.disableSelector = false;
          this.deviceId = null;
          return of();
        })
      )
      .subscribe();
  }

  public cancelPairing() {
    this.processingCancel = true;
    this.message = {
      type: 'loading',
      text: this.translateService.instant('cancellingPairing')
    };

    if (!this.deviceId) {
      this.processingCancel = false;
      return;
    }
    this.monitorDeviceService
      .cancel(this.deviceId)
      .pipe(take(1))
      .subscribe(() => {
        this.processingCancel = false;

        this.message = null;
        this.selected = null;
        this.showCancel = false;
        this.disableSelector = false;
      });
  }

  public toggleSelector(open: boolean) {
    this.activeDevices.options = [];

    if (
      open &&
      this.therapyJob?.pairingState !== PairingStatus.PAIRED &&
      this.therapyJob?.status !== TherapyJobStatus.COMPLETED
    ) {
      this.loadingActiveDevices = true;
      this.monitorDeviceService
        .getActive(this.therapyJob.id)
        .pipe(take(1))
        .subscribe((res: Array<MonitorDevice> | ResponseError) => {
          if ((res as ResponseErrorExtended).messageKey) {
            this.activeDevices.options = [];
          } else {
            this.activeDevices.options = res as Array<MonitorDevice>;
          }
          this.loadingActiveDevices = false;
        });
    }
  }
}
