<!-- Reset button -->
@if(showResetButtonDisabled){
<!-- Disabled -->
<cw-button [attr.data-vef]="'form-create-manual-cancel'" button-color="secondary" variant="outlined" [disabled]="true">
  {{ 'FormTJCreationResetChanges' | translate }}
</cw-button>
} @else{
<!-- Enabled -->
<cw-button
  [attr.data-vef]="'form-create-manual-cancel'"
  button-color="secondary"
  variant="outlined"
  (click)="resetChange.emit()"
>
  {{ 'FormTJCreationResetChanges' | translate }}
</cw-button>
}

<!-- Submit button -->
@if(showSubmitButtonDisabled && disableSubmitTooltip){
<!-- Disabled -->
<cw-tooltip [content]="disableSubmitTooltip" placement="bottom">
  <cw-button
    [attr.data-vef]="'form-create-manual-submit'"
    button-color="accent"
    variant="filled"
    [disabled]="true"
    type="submit"
  >
    {{ 'FormTJSaveChanges' | translate }}
  </cw-button>
</cw-tooltip>

} @else{
<!-- Enabled -->
<cw-button
  [attr.data-vef]="'form-create-manual-submit'"
  button-color="accent"
  variant="filled"
  (click)="submitChange.emit()"
  type="submit"
>
  {{ 'FormTJSaveChanges' | translate }}
</cw-button>
}
