import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { HighlightService } from '~/app/services/highlight.service';
import { SidebarService } from '~/app/services/sidebar.service';
import { NotificationsStore } from '~/app/store/notification.store';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'gplus-alarm-center',
  standalone: true,
  imports: [SnackbarComponent, TranslateModule, PersonNamePipe, DatePipe, CommonModule],
  templateUrl: './alarm-center.component.html',
  styleUrl: './alarm-center.component.scss'
})
export class AlarmCenterComponent {
  public notificationsStore = inject(NotificationsStore);

  constructor(
    private readonly highlightService: HighlightService,
    private readonly sidebarService: SidebarService,
    private readonly location: Location,
    private readonly router: Router
  ) {}

  public highlight(therapyJobId: string) {
    this.sidebarService.close();

    if (!this.location.path().endsWith('home')) {
      this.router.navigate(['/home']);
    }

    this.highlightService.highlightTJ(therapyJobId);
  }
}
