import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { NotificationLevel } from '../enums/NotificationLevel';
import { PairingStatus } from '../enums/PairingStatus';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import {
  SseAborted,
  SseDeletion,
  SseDripChamberDetected,
  SseEventMessageKey,
  SseEventType,
  SseFinalized,
  SseInfusion,
  SsePairing
} from '../models/sse-event';
import { TherapyJobExtended } from '../models/TherapyJob';
import { ToastService } from '../services/toast.service';
import { WsEventsService } from '../services/websocket.service';
import { TherapyJobStore } from '../store/job.store';
import { NotificationsStore } from '../store/notification.store';

@Injectable({
  providedIn: 'root'
})
export class EventManager {
  public jobsStore = inject(TherapyJobStore);
  public notificationStore = inject(NotificationsStore);

  private pairing: WritableSignal<SsePairing> = signal(null);
  public pairingSignal = this.pairing.asReadonly();
  private timer: ReturnType<typeof setTimeout>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly ws: WsEventsService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  public initSubscription() {
    this.ws
      .subscribeToWsEvents()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sseEvent) => {
        if (!sseEvent) {
          return;
        }

        if (sseEvent.type === SseEventType.JOB_UPDATE) {
          this.handleJobUpdate(sseEvent as SseInfusion);
          return;
        }

        if (sseEvent.type === SseEventType.JOB_START) {
          this.handleJobStart(sseEvent as SseInfusion);
          return;
        }

        if (sseEvent.type === SseEventType.JOB_FINISH) {
          this.handleJobFinish(sseEvent as SseInfusion);
          return;
        }

        if (sseEvent.type === SseEventType.PAIRING) {
          this.handlePairing(sseEvent as SsePairing);
          return;
        }

        if (sseEvent.type === SseEventType.DRIP_CHAMBER_DETECTION) {
          this.handleDripChamberDetection(sseEvent as SseDripChamberDetected);
          return;
        }

        if (sseEvent.type === SseEventType.JOB_DELETED) {
          this.handleJobDeletion(sseEvent as SseDeletion);
          return;
        }

        if (sseEvent.type === SseEventType.NEW_THERAPY_JOB) {
          this.handleJobCreation();
          return;
        }

        if (sseEvent.type === SseEventType.JOB_FINALIZED) {
          this.handleJobFinalized(sseEvent as SseFinalized);
          return;
        }

        if (sseEvent.type === SseEventType.ABORTION_TRIGGERED) {
          this.handleJobAbortedTriggered(sseEvent as SseAborted);
        }
      });
  }

  private handleJobUpdate(sseEvent: SseInfusion) {
    this.jobsStore.patchJob(
      { latestEvent: sseEvent, event: { type: SseEventType.JOB_UPDATE, timestamp: sseEvent.ts } },
      sseEvent.therapyJobId
    );
  }

  private handleJobStart(sseEvent: SseInfusion) {
    const tj = this.jobsStore.entityMap()[sseEvent.therapyJobId];
    this.jobsStore.patchJob(
      {
        status: TherapyJobStatus.RUNNING,
        startTime: {
          real: sseEvent.ts,
          planned: tj.startTime?.planned
        },
        event: { type: SseEventType.JOB_UPDATE, timestamp: sseEvent.ts }
      },
      sseEvent.therapyJobId
    );
  }

  private handleJobFinish(sseEvent: SseInfusion) {
    this.jobsStore.patchJob(
      {
        status: TherapyJobStatus.COMPLETED,
        endTime: sseEvent.ts,
        event: { type: SseEventType.JOB_UPDATE, timestamp: sseEvent.ts }
      },
      sseEvent.therapyJobId
    );

    this.notificationStore.setPendingConfirmation(sseEvent.therapyJobId);
    // we want to clean the drip chamber notifications
    this.notificationStore.setDripChamberNotification(sseEvent.therapyJobId);
  }

  private handlePairing(sseEvent: SsePairing) {
    // we set a new value to the pairing signal, so all the
    // components that are listening can update
    this.signalizePairing(sseEvent);

    // we prepare the patch object that will be used to update the job
    const tj = this.jobsStore.entityMap()?.[sseEvent.therapyJobId];

    // we get the abort/complete time if the status is aborted
    const possibleEndTime =
      sseEvent.therapyJobStatus === TherapyJobStatus.ABORTED ||
      sseEvent.therapyJobStatus === TherapyJobStatus.COMPLETE_CONFIRMED
        ? sseEvent.ts
        : null;

    const patch: Partial<TherapyJobExtended> = {
      monitorDeviceId: sseEvent.deviceId,
      pairingState: sseEvent.pairingState,
      status: sseEvent.therapyJobStatus || tj.status,
      endTime: possibleEndTime,
      messageKey: sseEvent?.messageKey as SseEventMessageKey,
      event: { type: SseEventType.PAIRING, timestamp: sseEvent.ts }
    };

    // We check if the pairing state is unpaired and if the neverPaired property should be set to false
    // this will edit the patch object
    this.possibleMarkAsOncePaired(sseEvent, patch);

    this.jobsStore.patchJob(patch, sseEvent.therapyJobId);

    // we run a checking if is necessary to notify something
    this.notificationStore.setUnpairOnRunning(sseEvent.therapyJobId);
    this.notificationStore.setPendingConfirmation(sseEvent.therapyJobId);
    this.notificationStore.setDripChamberNotification(sseEvent.therapyJobId);
    this.notificationStore.setAbortTriggered(sseEvent.therapyJobId);
  }

  private handleDripChamberDetection(sseEvent: SseDripChamberDetected) {
    this.jobsStore.patchJob(
      {
        dripChamberDetected: sseEvent.detected,
        event: { type: SseEventType.JOB_STATE, timestamp: sseEvent.ts }
      },
      sseEvent.therapyJobId
    );

    this.notificationStore.setDripChamberNotification(sseEvent.therapyJobId);
  }

  private handleJobDeletion(sseEvent: SseDeletion) {
    this.jobsStore.deleteJob(sseEvent.therapyJobId);
    this.showInfoMessage('TherapyJobDeletionInfo', sseEvent.therapyJobId);
  }

  private handleJobCreation() {
    // because we retrieve all the jobs we need to set the notifications
    // we may have some TJs already created with warnings
    this.jobsStore.loadAll().then(() => this.notificationStore.setAll());
  }

  private handleJobAbortedTriggered(sseEvent: SseAborted) {
    // we want to update the status
    this.jobsStore.patchJob(
      {
        status: TherapyJobStatus.ABORTION_TRIGGERED,
        event: { type: SseEventType.ABORTION_TRIGGERED, timestamp: sseEvent.ts }
      },
      sseEvent.therapyJobId
    );

    this.notificationStore.setDripChamberNotification(sseEvent.therapyJobId);
    this.notificationStore.setUnpairOnRunning(sseEvent.therapyJobId);
    this.notificationStore.setAbortTriggered(sseEvent.therapyJobId);
  }

  private signalizePairing(sseEvent: SsePairing) {
    // Clear any existing timer to avoid multiple resets
    clearTimeout(this.timer);

    // Set the new pairing event
    this.pairing.set(sseEvent);

    // Reset the pairing event after 1 second
    this.timer = setTimeout(() => {
      this.pairing.set(null);
    }, 1000);
  }

  private possibleMarkAsOncePaired(sseEvent: SsePairing, patch: Partial<TherapyJobExtended>) {
    // if there is an event about paired we should set the neverPaired property to false
    const shouldEditNeverPairedProperty = sseEvent.pairingState === PairingStatus.PAIRED;
    // we check if the neverPaired property is pristine
    const isNeverPairedPropPristine =
      this.jobsStore.entityMap()?.[sseEvent.therapyJobId]?.neverPaired !== undefined
        ? this.jobsStore.entityMap()?.[sseEvent.therapyJobId]?.neverPaired
        : null;

    if (shouldEditNeverPairedProperty && isNeverPairedPropPristine) {
      Object.assign(patch, { neverPaired: false });
    }
  }

  private handleJobFinalized(sseEvent: SseFinalized) {
    this.jobsStore.patchJob(
      {
        status: TherapyJobStatus.IN_HISTORY,
        endTime: sseEvent.ts
      },
      sseEvent.therapyJobId
    );
  }

  private showInfoMessage(messageKey: string, id?: string) {
    this.toastService.show({
      message: this.translateService.instant(messageKey, { id }),
      type: NotificationLevel.INFO,
      enableCloseButton: true,
      delay: 20_000
    });
  }
}
