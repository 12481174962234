<h2 class="header-title">
  <cw-icon
    class="icon mobile-drawer-toggle"
    [icon]="headerService.toggleMobileMenu() ? 'close' : 'menu'"
    size="md"
    (click)="openMobileMenuClicked()"
  ></cw-icon>

  <cw-icon
    *ngIf="headerService?.header()?.showBackBtn"
    class="icon"
    routerLink="/home"
    icon="arrow_back"
    size="md"
  ></cw-icon>
  {{ headerService?.header()?.titleKey || 'AppTitle' | translate }}
</h2>
<gplus-search-bar *ngIf="headerService?.header()?.showSearch" class="header-search"></gplus-search-bar>

<div class="left-actions">
  <div [attr.data-vef]="'header-notifications-button'" class="alarm" (click)="alarmClicked()">
    <cw-icon class="header-icon" icon="notifications" size="sm"> </cw-icon>
    <cw-badge-notification *ngIf="store.count()" class="badge-notification" [ngClass]="animateBadge" size="lg">{{
      store.count()
    }}</cw-badge-notification>
  </div>

  <gplus-user class="header-user"></gplus-user>
</div>
