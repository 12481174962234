import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { CwDatepicker } from '@bbraun/cortex-angular';

@Component({
  selector: 'gplus-datepicker',
  standalone: true,
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  imports: [CwDatepicker]
})
export class DatepickerComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() attrVef: string;
  @Input() mode: 'single' | 'range' = 'single';
  @Input() validations: Record<string, any> = {};

  public error: string;
  public value: Date;
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.control) {
      this.value = this.control.value;

      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: Date) => {
        this.value = res;

        if (this.control.pristine) {
          this.error = null;
          return;
        }

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }

  public selected(inputtedValue: CustomEvent<Array<Date>>) {
    this.control.setValue(this.mode === 'single' ? inputtedValue[0] : inputtedValue);
    this.control.markAsDirty();
  }
}
