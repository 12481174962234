<div class="amplifier">
  <!-- Intentionally empty -->
</div>

<cw-icon class="icon" [icon]="iconsMap[type]" [filled]="true" size="sm"></cw-icon>
<div class="wrapper">
  <div class="snack-title">{{ title }}</div>
  <div class="snack-badge">
    <ng-content select="[badge]"></ng-content>
  </div>
  <div class="snack-text" [innerHTML]="text"></div>
  <ng-content select="[info]"></ng-content>
  <div class="snack-timestamp">
    {{ timestamp }}
  </div>
</div>

@if(closeable){
<cw-button (click)="close.emit()" button-color="secondary" [rounded]="true">
  <cw-icon icon="close" />
</cw-button>
}
