import { AbstractControl } from '@angular/forms';
import { Observable, debounceTime, map, startWith } from 'rxjs';

export const change: (
  control: AbstractControl,
  array: Array<any>,
  valueProp?: string | Array<string>
) => Observable<Array<any>> = (control, array, valueProp) => {
  return control.valueChanges.pipe(
    startWith(''),
    debounceTime(100),
    map((value) => {
      if (!value || typeof value !== 'string') {
        return array;
      }
      if (valueProp) {
        if (Array.isArray(valueProp)) {
          return array.filter((item) => {
            return valueProp.some((prop) => matchWords(value, item[prop]));
          });
        } else {
          return array.filter((item) => matchWords(value, item[valueProp]));
        }
      }

      return array.filter((item) => matchWords(value, item));
    })
  );
};

const matchWords: (value: string, str: string) => boolean = (value, str) => {
  const words = value.split(' ');
  const pattern = `(${words.join('|')})`;
  const regex = new RegExp(pattern, 'gi');
  const matches = str.match(regex);
  return !!matches?.length;
};
