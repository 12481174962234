<cx-icon class="search-icon" icon="search" size="sm"> </cx-icon>
<input
  #inputSearch
  [(ngModel)]="value"
  (ngModelChange)="search()"
  class="search-input"
  [placeholder]="'SearchBar' | translate"
  type="text"
/>
<cx-icon *ngIf="value" (click)="clean()" class="clear-icon" icon="close" size="sm"> </cx-icon>
