<div class="track" [class.loading]="progress === null">
  <div
    class="bar"
    [ngStyle]="{
      width: progress + '%'
    }"
  ></div>

  @if(progress){
  <span class="percentage" [class.after-half]="progress > 50">
    {{ progress + '%' }}
  </span>
  }
</div>
<div *ngIf="value" class="value">{{ value }}</div>
