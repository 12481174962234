import { TranslateLoader } from '@ngx-translate/core';
import { catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(private readonly httpClient: HttpClient) {}
  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    return this.httpClient.get(`./assets/i18n/${lang}.json`).pipe(catchError(() => of({})));
  }
}
