import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Patient } from '~/app/models/generated/patient';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobForm } from '~/app/models/TherapyJobForm';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const GENDER_ICONS = ['agender', 'female', 'male'];

interface HeaderBarge {
  type: 'primary' | 'secondary';
  status: 'warning' | 'success';
  label: TherapyJobStatus;
}

@Component({
  selector: 'gplus-detail-page-header',
  standalone: true,
  imports: [CommonModule, TranslateModule, PersonNamePipe],
  templateUrl: './detail-page-header.component.html',
  styleUrl: './detail-page-header.component.scss'
})
export class DetailPageHeaderComponent implements OnInit {
  public patient: Patient;
  public genderIcon: string;
  public headerBadge: HeaderBarge;
  public isCustomJob: boolean;
  public therapyJobId: string;

  private destroyRef = inject(DestroyRef);

  @Input() set therapyJob(value: TherapyJob) {
    this.therapyJobId = value?.id;
    this.isCustomJob = !!value?.createdBy;
    this.headerBadge = this.getHeaderBadge(value?.status);
  }
  @Input() form: FormGroup<TherapyJobForm>;

  ngOnInit(): void {
    if (this.form) {
      this.form
        .get('patient')
        .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((patient) => {
          this.patient = patient;
          this.genderIcon = patient?.sex !== undefined ? GENDER_ICONS[patient.sex] : null;
        });
    }
  }

  private getHeaderBadge(status: TherapyJobStatus): HeaderBarge {
    switch (status) {
      case TherapyJobStatus.PRESCRIBED:
        return {
          type: 'secondary',
          status: 'warning',
          label: TherapyJobStatus.PRESCRIBED
        };
      case TherapyJobStatus.RUNNING:
        return {
          type: 'secondary',
          status: 'success',
          label: TherapyJobStatus.RUNNING
        };

      case TherapyJobStatus.COMPLETED:
        return {
          type: 'primary',
          status: 'success',
          label: TherapyJobStatus.COMPLETED
        };

      default:
        return null;
    }
  }
}
