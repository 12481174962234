<form *ngIf="form" class="form" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <!-- FILTERS -->

  <cx-menu class="cx-menu" customShadowCss styles=".menu-list{max-height: 50vh!important;}">
    <cx-button
      [buttonColor]="jobsStore.filter()?.queries?.length ? 'primary' : 'secondary'"
      slot="trigger"
      variant="ghost"
      buttonColor="secondary"
    >
      <cx-icon icon="filter_alt" [filled]="jobsStore.filter()?.queries?.length"></cx-icon>
      {{ 'FiltersBtn' | translate }}
      <cx-icon class="cx-menu-trigger" icon="arrow_drop_down" filled="true"></cx-icon>
    </cx-button>

    <cx-menu-item
      class="search-bar"
      customShadowCss
      styles=".menu-item:hover{background: none!important;}"
      (click)="$event.stopPropagation()"
      menu-type="result"
    >
      <gplus-search-bar class="header-search on-white"></gplus-search-bar>
    </cx-menu-item>

    @for(filter of filters; track filter.id){

    <cx-menu-item
      customShadowCss
      styles=".menu-item:hover{background: none!important;}"
      (click)="$event.stopPropagation()"
      menu-type="result"
    >
      <ng-container *ngTemplateOutlet="filterSwitch; context: { filter }"></ng-container>
    </cx-menu-item>

    }
  </cx-menu>

  <!-- QUICK FILTERS -->

  <div class="quick-filters">
    @for(filter of filters; track filter.id){

    <ng-container *ngIf="form.controls[filter.id].dirty">
      <ng-container *ngTemplateOutlet="filterSwitch; context: { filter }"></ng-container>
    </ng-container>

    }
  </div>

  <!--  SWITCH TEMPLATE -->
  <ng-template #filterSwitch let-filter="filter">
    <!-- SWITCH -->
    @switch (filter.control) {
    <!-- CASE -->
    @case (filterControl.SELECTOR) {
    <mat-form-field class="field" [class.multiple]="filter.multiple">
      @if(filter.multiple){

      <mat-select
        multiple
        #multipleSelect
        [formControlName]="filter.id"
        [value]="form?.controls?.[filter.id]?.value"
        [placeholder]="filter.placeholder | translate"
        [compareWith]="compareWith"
      >
        <mat-select-trigger>
          <span class="selector-trigger">
            <span class="selector-selection">
              {{ form.controls[filter.id].value?.[0] ? filter.displayWith(form.controls[filter.id].value?.[0]) : ''}}
            </span>

            @if ((form.controls[filter.id].value?.length || 0) > 1) {
            <span class="selector-additional-selection">
              (+{{ (form.controls[filter.id].value?.length || 0) - 1 }} {{ 'FiltersMultiSelectionMore' | translate }})
            </span>
            }
          </span>
        </mat-select-trigger>

        @for(option of filter.filteredOptions | async; track option){
        <mat-option (click)="$event.stopPropagation()" [value]="option">{{ filter.displayWith(option) }}</mat-option>

        }
      </mat-select>
      }@else {

      <mat-select
        [formControlName]="filter.id"
        [placeholder]="filter.placeholder | translate"
        [compareWith]="compareWith"
      >
        @for(option of filter.filteredOptions | async; track option){
        <mat-option [value]="option">{{ filter.displayWith(option) }}</mat-option>

        }
      </mat-select>
      }

      <cx-icon
        *ngIf="form.controls[filter.id].value"
        (click)="cleanSelector(form.controls[filter.id], $event)"
        class="selector-clear-icon"
        icon="close"
        size="sm"
      >
      </cx-icon>
    </mat-form-field>
    }

    <!-- CASE -->
    @case (filterControl.DATE) {
    <mat-form-field>
      <input
        matInput
        autocomplete="off"
        [matDatepicker]="myDatepicker1"
        [formControlName]="filter.id"
        [placeholder]="filter.placeholder | translate"
      />
      <mat-datepicker-toggle matSuffix [for]="myDatepicker1"></mat-datepicker-toggle>
      <mat-datepicker #myDatepicker1></mat-datepicker>

      <cx-icon
        *ngIf="form.controls[filter.id].value"
        (click)="cleanSelector(form.controls[filter.id], $event)"
        class="selector-clear-icon"
        icon="close"
        size="sm"
      >
      </cx-icon>
    </mat-form-field>
    }

    <!-- CASE -->
    @case (filterControl.INPUT_NUMBER) {
    <mat-form-field>
      <input type="number" matInput [placeholder]="filter.placeholder | translate" />
    </mat-form-field>

    }

    <!-- CASE -->
    @case (filterControl.INPUT_TEXT) {
    <mat-form-field>
      <input matInput [placeholder]="filter.placeholder | translate" />
    </mat-form-field>
    }

    <!-- CASE -->
    @case (filterControl.CHECKBOX) {
    <div class="cx-checkbox-wrapper">
      <cx-checkbox-extended [formControlName]="filter.id"
        ><span class="cx-checkbox-label">{{ filter.label | translate }}</span>
      </cx-checkbox-extended>
      <cx-icon
        *ngIf="form.controls[filter.id].value !== null"
        (click)="cleanSelector(form.controls[filter.id], $event)"
        class="selector-clear-icon inline"
        icon="close"
        size="sm"
      >
      </cx-icon>
    </div>

    } }
  </ng-template>

  <cx-button
    class="clear-btn"
    *ngIf="jobsStore.filter()?.queries?.length"
    (click)="resetAllFilters()"
    slot="trigger"
    button-color="secondary"
    variant="ghost"
  >
    {{ 'FiltersClearBtn' | translate }}
  </cx-button>
</form>
