import { TranslatePipe } from '@ngx-translate/core';
import { DurationFormatPipe, TimeUnit } from '../pipes/DurationFormat';

const TEN_MINUTES = 600_000;

export const setTimeError = (plannedTime: number, durationPipe: DurationFormatPipe, translatePipe: TranslatePipe) => {
  if (!plannedTime || Date.now() - plannedTime <= TEN_MINUTES) {
    return null;
  }

  const delayedTime = durationPipe.transform(Date.now() - plannedTime, TimeUnit.MILLISECONDS);

  if (delayedTime) {
    return `(${delayedTime} ${translatePipe.transform('Delay')})`;
  }

  return null;
};
