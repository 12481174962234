<div class="header">
  <div class="header-main">
    <!-- On Manual creation we show the patient selector -->
    @if(creationMode){

    <gplus-autocomplete
      class="patient-selector"
      data-test="patient-selector"
      [control]="formInstance?.form.controls['patient']"
      [filteredBy]="['lastName', 'firstName']"
      [data]="data?.patients"
      [required]="true"
      [placeholder]="'FormTJCreationPatientPlaceholder' | translate"
      [attrVef]="'form-create-manual-patient'"
      [attrVefItem]="'form-create-manual-dropdown-item'"
      [validations]="{
        required: 'FormTJCreationPatientIsRequired' | translate,
        patientNotExist: 'FormTJCreationPleaseSelectOneOfTheList' | translate
      }"
      labelType="personName"
    ></gplus-autocomplete>

    } @else {
    <h2 data-test="patient-name" class="header-main-name">
      {{ patient | personName }}
      <cw-icon data-test="patient-gender" *ngIf="genderIcon" [icon]="genderIcon" size="md" [filled]="false"></cw-icon>
    </h2>
    }
  </div>

  <div class="header-sub">
    <div data-test="patient-id" [class.disabled]="!patient" class="header-sub-item">
      <span class="header-sub-item-label"> {{ 'DetailPagePatientIdLabel' | translate }}</span>
      <span class="header-sub-item-value">
        {{ patient?.id || '-' }}
      </span>
    </div>
    <div class="divider"></div>
    <div data-test="patient-birthDate" [class.disabled]="!patient" class="header-sub-item">
      <span class="header-sub-item-label"> {{ 'DetailPageBirthLabel' | translate }}</span>
      <span class="header-sub-item-value">
        {{ (patient?.birthDate | localizedDate : dateFormat.BIRTH_DATE) || '-' }}</span
      >
    </div>

    @if(formInstance?.form){
    <div class="divider"></div>
    <div
      data-test="patient-physician"
      [class.disabled]="!formInstance?.form.controls['physician']?.value"
      class="header-sub-item"
    >
      <span class="header-sub-item-label">{{ 'DetailPagePhysicianLabel' | translate }}</span>
      <span class="header-sub-item-value">
        @if(formInstance?.form.controls['physician']?.value){
        {{ formInstance?.form.controls['physician']?.value | personName }}
        } @else { - }</span
      >
    </div>
    }
    <div class="divider"></div>
    <div data-test="patient-location" [class.disabled]="!patient" class="header-sub-item">
      <span class="header-sub-item-label">{{ 'DetailPageLocationLabel' | translate }}</span>
      <span class="header-sub-item-value">
        @if(patient?.careUnit?.room){
        {{ patient.careUnit.room + ' - ' + patient.careUnit.bed || '-' }}
        } @else { - }</span
      >
    </div>
  </div>
</div>

@if(!creationMode){
<div class="actions">
  <!-- Finish Button -->
  @if(therapyJob?.status === therapyJobStatus.COMPLETE_CONFIRMED || therapyJob?.status === therapyJobStatus.ABORTED){
  <cw-button button-color="accent" variant="filled" (click)="onFinalize()" type="submit">
    {{ 'TherapyJobFinishJobBtn' | translate }}
  </cw-button>

  }
  <!--  Dotted menu -->
  <gplus-therapy-job-menu
    container="detail"
    [therapyJob]="therapyJob"
    (onDelete)="onDelete($event)"
    (onUnpair)="onUnpair($event)"
    (onAbort)="onAbort($event)"
  ></gplus-therapy-job-menu>
</div>
}
