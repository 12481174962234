import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { addEntities, addEntity, removeEntity, withEntities } from '@ngrx/signals/entities';
import { NotificationType } from '../enums/NotificationId';
import { NotificationKey } from '../enums/NotificationKey';
import { NotificationLevel } from '../enums/NotificationLevel';
import { TherapyJobExtended } from '../models/TherapyJob';
import { TherapyJobNotification } from '../models/TherapyJobNotification';
import { notifyDetachedDripChamber, unNotifyDetachedDripChamber } from '../utils/notifications/DetachedDripChamber';
import { notifyUnpairOnRunning, unNotifyUnpairOnRunning } from '../utils/notifications/UnpairedOnRunning';
import { TherapyJobStore } from './job.store';

interface NotificationsState {
  loading: boolean;
}

const initialNotificationSate: NotificationsState = {
  loading: false
};

export const NotificationsStore = signalStore(
  { providedIn: 'root' },
  withEntities<TherapyJobNotification>(),
  withDevtools('notifications'),
  withState(initialNotificationSate),
  withMethods((store, jobStore = inject(TherapyJobStore)) => ({
    setAll() {
      patchState(store, { loading: true });
      const unpairedNotifications: Array<TherapyJobNotification> = jobStore
        .entities()
        .filter((tj) => notifyUnpairOnRunning(store, tj))
        .map((tj) => getNotification(tj, NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD, NotificationLevel.ERROR));

      const detachedNotifications: Array<TherapyJobNotification> = jobStore
        .entities()
        .filter((tj) => notifyDetachedDripChamber(store, tj))
        .map((tj) => getNotification(tj, NotificationType.DRIP_CHAMBER_DETACHED, NotificationLevel.ERROR));

      patchState(store, addEntities([...unpairedNotifications, ...detachedNotifications]));
      patchState(store, { loading: false });
    },
    setDripChamberNotification(id: string) {
      const tj = jobStore.entityMap()[id];

      if (notifyDetachedDripChamber(store, tj)) {
        patchState(
          store,
          addEntity(getNotification(tj, NotificationType.DRIP_CHAMBER_DETACHED, NotificationLevel.ERROR))
        );
      } else if (unNotifyDetachedDripChamber(store, tj)) {
        patchState(store, removeEntity(`${NotificationType.DRIP_CHAMBER_DETACHED}-${tj.id}`));
      }
    },
    setUnpairOnRunning(id: string) {
      const tj = jobStore.entityMap()[id];

      if (!tj) {
        return;
      }

      if (notifyUnpairOnRunning(store, tj)) {
        patchState(
          store,
          addEntity(getNotification(tj, NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD, NotificationLevel.ERROR))
        );
      } else if (unNotifyUnpairOnRunning(store, tj)) {
        patchState(store, removeEntity(`${NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD}-${tj.id}`));
      }
    }
  })),
  withComputed((state) => ({
    count: computed(() => {
      return state.entities()?.length;
    })
  }))
);

const getNotification: (
  tj: TherapyJobExtended,
  type: NotificationType,
  level: NotificationLevel
) => TherapyJobNotification = (tj, type, level) => {
  const id = `${type}-${tj.id}`;
  return {
    id,
    type,
    timestamp: tj?.event?.timestamp,
    therapyJobId: tj.id,
    translationKey: `Notification${type}` as NotificationKey,
    hintKey: `Notification${type}Hint` as NotificationKey,
    level,
    showAlarmCenter: true,
    therapyJob: tj
  };
};
