import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { MedicineService } from '../services/medicine.service';
import { Observable, map, of } from 'rxjs';

export class MedicineValidator {
  static createValidator(medicineService: MedicineService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      // the value is not required
      if (!control.value) {
        return of(null);
      }
      return medicineService.medicineExists(control.value).pipe(
        map((result: boolean) => {
          return result ? null : { unauthorizedMedicine: true };
        })
      );
    };
  }
}
