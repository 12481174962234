import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BoardComponent } from '~/app/components/board/board.component';
import { TopBarComponent } from '~/app/components/top-bar/top-bar.component';
import { EventsService } from '~/app/services/events.service';
import { HeaderService } from '~/app/services/header.service';
import { MonitorDeviceService } from '~/app/services/monitor-device.service';

@Component({
  selector: 'gplus-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [CommonModule, TopBarComponent, BoardComponent, FormsModule]
})
export class HomeComponent {
  constructor(
    readonly monitorDeviceService: MonitorDeviceService,
    public readonly eventsService: EventsService,
    private readonly route: ActivatedRoute,
    private readonly headerService: HeaderService
  ) {
    this.headerService.setHeader({
      titleKey: this.route.snapshot?.data?.titleKey,
      showBackBtn: false,
      showSearch: true
    });
  }
}
