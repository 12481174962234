import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[customShadowCss]'
})
export class CustomShadowCssDirective implements OnInit {
  @Input()
  styles: string;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(this.styles);
    this.el.nativeElement.shadowRoot.adoptedStyleSheets.push(sheet); // You want to add to stylesheets list
  }
}
