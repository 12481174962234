import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Employee } from '../models/generated/employee';
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public currentWard = '1';

  // For now, we use a partial model of a user until we know how is going to be
  public getUser(): Observable<Partial<Employee>> {
    return of({
      id: `${3}`,
      firstName: 'Emilia',
      lastName: 'Becker',
      type: 'NURSE',
      sex: 1
    });
  }
}
