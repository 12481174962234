import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CreateTherapyJobOnDemandComponent } from './components/create-therapy-job-on-demand/create-therapy-job-on-demand.component';
import { DetailPageComponent } from './components/detail-page/detail-page.component';
import { CanDeactivateGuard } from './guards/canDeactive.guard';
import { UserGuard } from './guards/session-guard';
import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [UserGuard, MsalGuard]
  },
  {
    path: 'prn',
    component: CreateTherapyJobOnDemandComponent,
    canActivate: [UserGuard, MsalGuard],
    data: {
      titleKey: 'PageTitlePrnPage'
    }
  },
  {
    path: 'detail',
    component: DetailPageComponent,
    canActivate: [UserGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      titleKey: 'PageTitleTherapyJobPage'
    }
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
