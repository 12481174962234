export enum TherapyJobStatus {
  PRESCRIBED = 'PRESCRIBED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  COMPLETE_CONFIRMED = 'COMPLETE_CONFIRMED',
  IN_HISTORY = 'IN_HISTORY',
  ABORTED = 'ABORTED',
  ABORTION_TRIGGERED = 'ABORTION_TRIGGERED'
}
