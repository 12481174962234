import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TherapyJobForm } from '../models/TherapyJobForm';
import { EmployeeService } from '../services/employee.service';
import { MedicineService } from '../services/medicine.service';
import { PatientService } from '../services/patient.service';
import { DataTypeValidator } from './DataTypeValidator';
import { MedicineUnitValidator } from './MedicineUnitValidator';
import { getTimeFromDate } from './getTime';
import { MedicineValidator } from './medicineValidator';
import { PatientValidator } from './patientValidator';
import { PhysicianValidator } from './physicianValidator';

export class TherapyJobFormGroup {
  private therapyJobForm;

  constructor(
    private readonly patientService: PatientService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService
  ) {}

  get form() {
    return this.therapyJobForm;
  }

  public initForm(medicinesNum: number) {
    this.therapyJobForm = new FormGroup<TherapyJobForm>({
      patient: new FormControl(null, [Validators.required], [PatientValidator.createValidator(this.patientService)]),
      volume: new FormControl(null, [Validators.min(0), DataTypeValidator.isNumber()]),
      flowRate: new FormControl(null, [Validators.min(0), DataTypeValidator.isNumber()]),
      startDate: new FormControl(new Date(), [Validators.required]),
      hours: new FormControl(getTimeFromDate(new Date()), [Validators.required]),
      physician: new FormControl(
        null,
        [Validators.required],
        [PhysicianValidator.createValidator(this.employeeService)]
      ),
      annotations: new FormControl(null, DataTypeValidator.isString()),
      medication: new FormGroup({
        medicines: new FormArray(
          Array(medicinesNum)
            .fill(null)
            .map(() => {
              return new FormGroup({
                medicine: new FormControl(
                  null,
                  [Validators.required],
                  [MedicineValidator.createValidator(this.medicineService)]
                ),
                customAmount: new FormControl(null, [
                  Validators.required,
                  Validators.min(0),
                  DataTypeValidator.isNumber()
                ]),
                unit: new FormControl(null, [Validators.required, MedicineUnitValidator.unitNotExist()])
              });
            })
        ),
        carrier: new FormControl(null, [Validators.required]),
        count: new FormControl(),
        order: new FormControl()
      }),
      prepared: new FormControl()
    });

    return this.therapyJobForm;
  }
}
