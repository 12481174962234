import { TranslatePipe } from '@ngx-translate/core';
import { DurationFormatPipe, TimeUnit } from '../pipes/DurationFormat';

export const setTimeError = (plannedTime: number, durationPipe: DurationFormatPipe, translatePipe: TranslatePipe) => {
  if (!plannedTime) {
    return null;
  }

  const delayedTime = durationPipe.transform(Date.now() - plannedTime, TimeUnit.MILLISECONDS);

  if (delayedTime) {
    return `(${delayedTime} ${translatePipe.transform('Delay')})`;
  }

  return null;
};
