import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, lastValueFrom, map, of, switchMap, tap } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { Employee } from '../models/generated/employee';
import { TherapyJob, TherapyJobExtended } from '../models/TherapyJob';
import { buildTJ } from '../utils/buildTJ';
import { EmployeeService } from './employee.service';
import { EnvironmentService } from './environment.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class TherapyJobService {
  private therapyJobsSignal: WritableSignal<Array<TherapyJobExtended>> = signal([]);
  public therapyJobs: Signal<Array<TherapyJobExtended>> = this.therapyJobsSignal.asReadonly();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly env: EnvironmentService,
    private readonly toastService: ToastService,
    private readonly employeeService: EmployeeService
  ) {}

  public getList(): Promise<Array<TherapyJobExtended>> {
    return lastValueFrom(
      this.httpClient.post<Array<Partial<TherapyJob>>>(`${this.env.getBaseRestUrl()}/therapyjob/list`, null).pipe(
        switchMap((partialTherapyJobs: Array<Partial<TherapyJob>>) =>
          this.employeeService.getPhysicians().pipe(
            catchError((err) => {
              console.warn('Error requesting therapy jobs', err);

              this.showErrorMessage(this.translateService.instant('ErrorGeneric'));
              return of([]);
            }),
            map((employees: Array<Employee>) =>
              partialTherapyJobs.map((tj: Partial<TherapyJob>) => ({
                ...buildTJ(tj),
                physicianObject: employees.find((e) => e.id === tj.physician)
              }))
            )
          )
        )
      )
    );
  }

  public update(value: Partial<TherapyJobExtended>, id: string): Observable<void | { error: string }> {
    return this.httpClient.patch<void>(`${this.env.getBaseRestUrl()}/therapyjob/${id}`, value).pipe(
      catchError((err) => {
        console.warn('Error Updating Therapy Job', err);
        this.showErrorMessage(this.translateService.instant('ErrorUpdating'));

        return of({ error: 'Error Updating Therapy Job' });
      })
    );
  }

  public create(therapyJob: Partial<TherapyJob>): Observable<void> {
    return this.httpClient.post<void>(`${this.env.getBaseRestUrl()}/therapyjob`, therapyJob).pipe(
      catchError((err) => {
        console.warn('Error saving therapy jobs', err);
        this.showErrorMessage(this.translateService.instant('ErrorCreation'));

        return of();
      })
    );
  }

  public delete(therapyJobId: string): Observable<void | { error: string }> {
    return this.httpClient.delete<void>(`${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}`).pipe(
      tap(() => {
        this.showInfoMessage(this.translateService.instant('TherapyJobDeletionInfo', { id: therapyJobId }));
      }),
      catchError((err) => {
        console.warn('Error deleting the therapy job', err);
        this.showErrorMessage(this.translateService.instant('TherapyJobDeletionError'));

        return of({ error: 'Error Deleting Therapy Job' });
      })
    );
  }

  public finalize(therapyJobId: string): Observable<void | { error: string }> {
    return this.httpClient.put<void>(`${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}/finalize`, null).pipe(
      tap(() => {
        this.showSuccessMessage(this.translateService.instant('TherapyJobFinishJobSuccess', { id: therapyJobId }));
      }),
      catchError((err) => {
        console.warn('Error finalizing the therapy job', err);
        this.showErrorMessage(this.translateService.instant('TherapyJobFinishJobError'));

        return of({ error: 'Error finalizing the Therapy Job' });
      })
    );
  }

  public abort(therapyJobId: string): Observable<void | { error: string }> {
    return this.httpClient.put<void>(`${this.env.getBaseRestUrl()}/therapyjob/${therapyJobId}/abort`, null).pipe(
      catchError((err) => {
        console.warn('Error aborting the therapy job', err);
        this.showErrorMessage(this.translateService.instant('TherapyJobAbortError'));

        return of({ error: 'Error aborting the Therapy Job' });
      })
    );
  }

  private showErrorMessage(message) {
    this.toastService.show({
      message,
      type: NotificationLevel.ERROR,
      enableCloseButton: true,
      delay: 20_000
    });
  }

  private showInfoMessage(message) {
    this.toastService.show({
      message,
      type: NotificationLevel.INFO,
      enableCloseButton: true,
      delay: 10_000
    });
  }

  private showSuccessMessage(message) {
    this.toastService.show({
      message,
      type: NotificationLevel.SUCCESS,
      enableCloseButton: true,
      delay: 10_000
    });
  }
}
