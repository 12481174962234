@if(notificationsStore.loading()){
<div class="loader"></div>

}
<cw-tab [selected]="selectedTabId" [items]="tabs()" (tabChange)="onTabChange($event)">
  @for(tab of tabs(); track tab.slot){
  <div [slot]="tab.slot">
    <ng-container *ngTemplateOutlet="items"> </ng-container>
  </div>
  }
</cw-tab>

<ng-template #items>
  <div class="list">
    @if(filtered()?.length){
    <!-- Filtered -->
    @for(notification of filtered(); track notification.id){

    <gplus-alarm-card
      [notification]="notification"
      (click)="highlight(notification?.therapyJobId)"
      (hide)="toggleHide(notification)"
    >
      <cw-badge-status
        [badge]
        [type]="notification.level === level.ERROR ? 'primary' : 'secondary'"
        [status]="notification.level"
      >
        {{ notification?.therapyJob?.patient.careUnit.room }} -
        {{ notification?.therapyJob?.patient.careUnit.bed }}
      </cw-badge-status></gplus-alarm-card
    >

    } } @else {
    {{ 'AlarmCenterNoAlarms' | translate }}
    }
  </div>
</ng-template>
