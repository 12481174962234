@if(!loading && activeTab){

<div class="wrapper">
  <cw-list class="tabs" [clickable]="true">
    @for( tab of tabs; track tab.id){

    <cw-list-item [active]="activeTab.id === tab.id" [itemTitle]="tab.label" (click)="selectTab(tab)"> </cw-list-item>

    }
  </cw-list>

  <gplus-configuration-controls
    class="controls"
    [controlsCustomization]="controlsCustomization"
    (selectTab)="selectTab($event)"
    [onlyShowWithKey]="activeTab.id"
    [form]="form"
    [data]="uiControlObject"
  ></gplus-configuration-controls>
</div>
} @else {

<!--  -->
@if(loading){

<div class="loader"></div>
}

<!--  -->
} @if(form){

<div class="actions">
  <cw-button *ngIf="!isDefault" button-color="secondary" variant="ghost" (click)="restoreDefault()">
    {{ 'ConfigPageRestoreDefaultBtn' | translate }}
  </cw-button>
  <cw-button button-color="secondary" variant="outlined" [disabled]="form.pristine" (click)="resetForm()">
    {{ 'ConfigPageResetFormBtn' | translate }}
  </cw-button>
  <cw-button
    button-color="accent"
    variant="filled"
    [disabled]="!form.valid || form.pristine"
    type="submit"
    (click)="saveChanges()"
  >
    {{ 'ConfigPageSaveChangesBtn' | translate }}
  </cw-button>
</div>
}
