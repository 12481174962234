<gplus-filters *ngIf="configService.configuration()"></gplus-filters>

<!-- Connections status -->
@if(ws.alive() !== null){
<ng-container>
  <div class="system-status" [ngClass]="ws.alive() && network.online() ? 'UP' : 'DOWN'">
    <span>{{ (ws.alive() && network.online() ? 'Connected' : 'Disconnected') | translate }}</span>
  </div>
</ng-container>

}
<cw-button
  class="prn-button"
  [attr.data-vef]="'button-create-therapy'"
  routerLink="/create"
  button-color="accent"
  type="button"
  as="button"
  variant="filled"
>
  <cw-icon class="menu-trigger" icon="add" filled="true"></cw-icon>
  {{ 'AddJobButton' | translate }}
</cw-button>
