import { FormGroup } from '@angular/forms';
import { FlowRateUnit } from '../enums/FlowRateUnit';
import { NewTherapyJob } from '../models/generated/newTherapyJob';
import { MedicineUnit } from '../models/MedicineUnit';
import { Prescription } from '../models/prescription';
import { TherapyJobForm, TherapyJobFormRaw } from '../models/TherapyJobForm';
import { TherapyJobMedicine } from '../models/generated/therapyJobMedicine';

export const getPayloadFromForm: (form: FormGroup<TherapyJobForm>) => NewTherapyJob = (form) => {
  const rawValue: TherapyJobFormRaw = form.getRawValue();
  const time = rawValue.hours.split(':');
  const startTimePlanned = rawValue.startDate ? rawValue.startDate.setHours(Number(time[0]), Number(time[1])) : 0;

  const medication = rawValue.medication;

  return {
    physicianId: rawValue.physician.id,
    annotations: rawValue.annotations,
    medication: {
      ...medication,
      medicines: medication.medicines.map(
        (med: { medicine: TherapyJobMedicine; customAmount: number; unit: MedicineUnit }) => {
          return {
            id: med.medicine.id,
            code: med.medicine.code,
            amount: med.customAmount,
            unit: med.unit.label,
            display: med.medicine.display
          };
        }
      ),
      carrier:
        typeof medication.carrier === 'string'
          ? {
              id: null,
              wardId: null,
              description: medication.carrier as string
            }
          : medication.carrier
    },
    patientId: rawValue.patient.id,
    flowRate: {
      planned: rawValue.flowRate,
      unit: FlowRateUnit.MILLILITERS_PER_HOUR
    },
    totalVolume: rawValue.volume,
    startTime: {
      planned: startTimePlanned
    }
  };
};

export const getPayloadFromPrescription = (prescription: Prescription) => {
  return {
    prescription: prescription.id,
    physicianId: prescription.physician,
    medication: prescription.medication,
    patientId: prescription.patient.id,
    flowRate: {
      planned: prescription.flowRate || 0,
      unit: FlowRateUnit.MILLILITERS_PER_HOUR
    },
    totalVolume: prescription.volume
  };
};
