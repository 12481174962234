import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { Medicine } from '../models/generated/medicine';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class MedicineService {
  private medicines: Array<Medicine>;

  constructor(private readonly httpClient: HttpClient, private readonly env: EnvironmentService) {}

  public getMedicines(wardId: string): Observable<Array<Medicine>> {
    let params = new HttpParams().set('ward', wardId);

    return this.httpClient.get<Array<Medicine>>(`${this.env.getBaseRestUrl()}/api/medicine`, { params }).pipe(
      catchError((err) => {
        console.warn('Error requesting medicines', err);
        return of([]);
      }),
      map((medicines: Array<Medicine>) => {
        this.medicines = medicines.sort((a, b) => a.display.localeCompare(b.display));
        return this.medicines;
      })
    );
  }

  public medicineExists(value: string | Medicine): Observable<boolean> {
    if (!value) {
      return of(false);
    }

    const id = value === 'string' ? value : (value as Medicine).id;
    const med = this.medicines?.find((med) => med.id === id);

    return of(!!med);
  }
}
