import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, delay, filter, interval, map, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { EmployeeType } from '~/app/enums/EmployeeType';
import { PrescriptionWithExtras } from '~/app/models/Extras';
import { Prescription } from '~/app/models/prescription';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobForm } from '~/app/models/TherapyJobForm';
import { CarrierService } from '~/app/services/carrier.service';
import { EmployeeService } from '~/app/services/employee.service';
import { EventsService } from '~/app/services/events.service';
import { HeaderService } from '~/app/services/header.service';
import { MedicineService } from '~/app/services/medicine.service';
import { PatientService } from '~/app/services/patient.service';
import { PrescriptionService } from '~/app/services/prescription.service';
import { SessionService } from '~/app/services/session.service';
import { ComponentSSE } from '~/app/utils/ComponentSSE';
import { TherapyJobFormGroup } from '~/app/utils/TherapyJobFormGroup';
import { DetailPageNewComponent } from './detail-page-new/detail-page-new.component';
import { DetailPageViewComponent } from './detail-page-view/detail-page-view.component';

export enum DetailPageStatus {
  CREATION_MANUAL = 'creationManual',
  CREATION_PRN = 'creationPRN',
  EDITION = 'edition'
}

@Component({
  selector: 'gplus-detail-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, DetailPageNewComponent, DetailPageViewComponent],
  templateUrl: './detail-page.component.html',
  styleUrl: './detail-page.component.scss'
})
export class DetailPageComponent extends ComponentSSE {
  public detailPageStatus = DetailPageStatus;
  public pageStatus: DetailPageStatus;

  public sessionData: SessionData;

  public therapyJob: TherapyJob;
  public prescription: PrescriptionWithExtras;

  // Params
  private paramId: string;
  public paramPRN: string;

  public form: FormGroup<TherapyJobForm>;

  public ticker: number;
  public loading: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly headerService: HeaderService,
    protected override eventsService: EventsService,
    private readonly sessionService: SessionService,
    protected override translateService: TranslateService,

    // Data Services
    private readonly patientService: PatientService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService,
    private readonly carrierService: CarrierService,
    private readonly prescriptionService: PrescriptionService
  ) {
    super(eventsService, translateService);
    this.headerService.setHeader({ titleKey: this.route.snapshot?.data?.titleKey, showBackBtn: true });

    interval(5000)
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        this.ticker = res;
      });

    effect(() => {
      this.therapyJob = this.jobsStore.entityMap()?.[this.paramId];
    });

    const entitiesLoaded$ = toObservable(this.jobsStore.entities).pipe(
      filter(() => this.jobsStore.loading() === false)
    );

    entitiesLoaded$
      .pipe(
        take(1),
        switchMap(() => {
          this.loading = true;
          return this.route.paramMap.pipe(
            take(1),
            map((routeParams) => {
              this.paramId = routeParams.get('id');
              this.paramPRN = routeParams.get('prn');

              if (this.paramId !== null && this.paramPRN !== null) {
                console.warn('URL malformed, redirecting to home');
                this.router.navigate(['/home']);
                return { id: null, prn: null };
              }

              return {
                id: routeParams.get('id'),
                prn: routeParams.get('prn')
              };
            }),
            switchMap(({ id, prn }) => {
              if (prn !== null) {
                return this.prescriptionService.getOnDemandPrescription(prn).pipe(
                  take(1),
                  tap((prescription: Prescription) => {
                    if (!prescription) {
                      this.router.navigate(['/home']);
                      return;
                    }

                    this.prescription = prescription;

                    this.setDetailPageStatus(null);
                    this.initForm(prescription.medication.medicines.length);
                  })
                );
              }
              if (id !== null) {
                return of(this.jobsStore.entityMap()?.[this.paramId]).pipe(
                  take(1),
                  tap((tj) => {
                    if (!tj) {
                      this.router.navigate(['/home']);
                      return;
                    }
                    this.therapyJob = tj;

                    this.setDetailPageStatus(tj);
                    this.initForm(this.therapyJob.medication.medicines.length);
                  })
                );
              }

              this.setDetailPageStatus(null);
              this.initForm(1);

              return of(null);
            })
          );
        }),
        delay(300)
      )
      .subscribe(() => {
        this.loading = false;
      });

    combineLatest([
      this.medicineService.getMedicines(this.sessionService.currentWard),
      this.carrierService.getCarriers(this.sessionService.currentWard),
      this.patientService.getPatients(this.sessionService.currentWard),
      this.employeeService.getEmployees(EmployeeType.PHYSICIAN)
    ])
      .pipe(
        take(1),
        shareReplay(),
        map(([medicines, carriers, patients, physicians]) => ({
          medicines,
          carriers,
          patients,
          physicians
        }))
      )
      .subscribe((sessionData) => {
        this.sessionData = sessionData;
      });
  }

  private initForm(medicinesNum: number) {
    const instance = new TherapyJobFormGroup(this.patientService, this.medicineService, this.employeeService);
    this.form = instance.initForm(medicinesNum);
  }

  private setDetailPageStatus(therapyJob: TherapyJob) {
    if (!this.paramPRN && !this.paramId) {
      this.pageStatus = DetailPageStatus.CREATION_MANUAL;
    }
    if (this.paramPRN && !this.paramId) {
      this.pageStatus = DetailPageStatus.CREATION_PRN;
    }

    if (this.paramId && therapyJob) {
      this.pageStatus = DetailPageStatus.EDITION;
    }
  }
}
