import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component, computed, inject, signal, Signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { CwTab } from '@bbraun/cortex-angular';
import { TabItem } from '@bbraun/cortex-web';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationLevel } from '~/app/enums/NotificationLevel';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { FilterByPipe } from '~/app/pipes/FilterBy';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { HighlightService } from '~/app/services/highlight.service';
import { SidebarService } from '~/app/services/sidebar.service';
import { NotificationsStore } from '~/app/store/notification.store';
import { getIndex } from '~/app/utils/getCriticalNotificationType';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { AlarmCardComponent } from './alarm-card/alarm-card.component';

enum TabSlot {
  ACTIVE = 'active',
  HIDDEN = 'hidden'
}
@Component({
  selector: 'gplus-alarm-center',
  standalone: true,
  imports: [
    SnackbarComponent,
    TranslateModule,
    PersonNamePipe,
    DatePipe,
    CommonModule,
    FilterByPipe,
    CwTab,
    AlarmCardComponent
  ],
  templateUrl: './alarm-center.component.html',
  styleUrl: './alarm-center.component.scss'
})
export class AlarmCenterComponent {
  public notificationsStore = inject(NotificationsStore);
  public tabs: Signal<Array<TabItem>> = signal([]);
  public selectedTabId: WritableSignal<TabSlot> = signal(TabSlot.ACTIVE);
  public filtered: Signal<Array<TherapyJobNotification>>;
  public tabSlot = TabSlot;
  public level = NotificationLevel;

  constructor(
    private readonly highlightService: HighlightService,
    private readonly sidebarService: SidebarService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {
    this.filtered = computed(() =>
      this.notificationsStore
        .entities()
        .filter(
          (notification) =>
            notification.showAlarmCenter &&
            (this.selectedTabId() === TabSlot.ACTIVE ? !notification?.hidden : notification?.hidden)
        )
        .toSorted((a, b) => this.compare(a, b))
    );

    const translations = {
      active: this.translate.instant('AlarmCenterActiveTab'),
      hidden: this.translate.instant('AlarmCenterHiddenTab')
    };

    this.tabs = computed(() => {
      const numActive = this.notificationsStore
        .entities()
        .filter((notification) => notification.showAlarmCenter && !notification?.hidden).length;

      const numHidden = this.notificationsStore
        .entities()
        .filter((notification) => notification.showAlarmCenter && notification?.hidden).length;

      return [
        { title: `${translations[TabSlot.ACTIVE]} (${numActive})`, slot: TabSlot.ACTIVE },
        { title: `${translations[TabSlot.HIDDEN]} (${numHidden})`, slot: TabSlot.HIDDEN }
      ];
    });
  }

  public highlight(therapyJobId: string) {
    this.sidebarService.close();

    if (!this.location.path().endsWith('home')) {
      this.router.navigate(['/home']);
    }

    this.highlightService.highlightTJ(therapyJobId);
  }

  public onTabChange(event: CustomEvent) {
    this.selectedTabId.set(event.detail);
  }

  private compare(a: TherapyJobNotification, b: TherapyJobNotification) {
    const aLevel = getIndex(a.level);
    const bLevel = getIndex(b.level);

    if (aLevel !== bLevel) {
      return aLevel - bLevel;
    }

    return b.timestamp - a.timestamp;
  }

  public toggleHide(notification: TherapyJobNotification) {
    this.notificationsStore.update(notification.id, { hidden: !notification.hidden });
  }
}
