import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, highlight: string) {
    if (!highlight) {
      return value;
    }

    const regex = new RegExp(`(${highlight.trim()})`, 'gi');

    return value.replace(regex, `<span class="highlight">$1</span>`);
  }
}
