import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CxButton, CxIcon, CxMenu, CxMenuItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { DialogIds, DialogService } from '~/app/services/dialog.service';
import { StatusService } from '~/app/services/status.service';
import { FiltersComponent } from '../filters/filters.component';

@Component({
  selector: 'gplus-top-bar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    CxButton,
    CxIcon,
    CxMenu,
    CxMenuItem,
    FiltersComponent
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent {
  constructor(public readonly statusService: StatusService, private readonly dialogService: DialogService) {}

  public openSidebar(event: MouseEvent) {
    event.stopPropagation();
  }

  public openOnDemandModal() {
    this.dialogService.open(DialogIds.CREATE_ON_DEMAND);
  }
}
