@if(notification){
<div class="amplifier">
  <cw-icon class="amplifier-icon" [icon]="iconsMap[notification.level]" size="sm"></cw-icon>
</div>

<div class="wrapper">
  <div class="header">
    <div class="snack-title">{{ notification.translationKey | translate }}</div>

    <div class="hide-btn-wrapper">
      <cw-tooltip
        [content]="(!notification.hidden ? 'AlarmCenterHideBtnTooltip' : 'AlarmCenterShowBtnTooltip') | translate"
        placement="bottom"
      >
        <cw-button
          class="hide-btn"
          rounded="true"
          button-color="secondary"
          (click)="$event.stopPropagation(); hide.emit()"
        >
          <cw-icon
            class="menu-trigger icon"
            [icon]="!notification.hidden ? 'visibility_off' : 'visibility'"
            filled="false"
          ></cw-icon>
        </cw-button>
      </cw-tooltip>
    </div>
  </div>

  <div class="footer">
    <div class="snack-badge">
      <ng-content select="[badge]"></ng-content>
    </div>

    <div class="right">
      <div class="snack-text">{{ notification?.therapyJob?.patient | personName }}</div>
      <div *ngIf="notification?.timestamp" class="snack-timestamp">{{ notification?.timestamp | date : 'h:mm a' }}</div>
    </div>
  </div>
</div>
}
