import { EntityMap } from '@ngrx/signals/entities';
import { NotificationLevel } from '../enums/NotificationLevel';
import { TherapyJobNotification } from '../models/TherapyJobNotification';

// If one of the notification is an error we keep all the notification marked as error
export const getCriticalType = (tjNotificationMap: EntityMap<TherapyJobNotification>): NotificationLevel => {
  return Object.keys(tjNotificationMap).reduce<NotificationLevel>((acc, key) => {
    const notification = tjNotificationMap[key];
    if (acc === NotificationLevel.ERROR) {
      return acc;
    }

    return notification.level;
  }, null);
};

export const getCriticalIndex = (tjNotificationMap: EntityMap<TherapyJobNotification>): number => {
  return getIndex(getCriticalType(tjNotificationMap));
};

export const getIndex = (level: NotificationLevel): number => {
  switch (level) {
    case NotificationLevel.ERROR:
      return 1;
    case NotificationLevel.WARN:
      return 2;
    case NotificationLevel.INFO:
      return 3;
    case NotificationLevel.SUCCESS:
      return 4;

    default:
      return Number.MAX_SAFE_INTEGER;
  }
};
