import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { ReplaySubject, Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLogged: Subject<boolean> = new ReplaySubject(1);
  private destroyRef = inject(DestroyRef);

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService
  ) {}

  public configureAuth() {
    this.setLoginDisplay();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        this.setLoginDisplay();
      });
  }

  public loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  public logout() {
    this.authService.logout({
      idTokenHint: this.authService.instance.getAllAccounts()[0].idToken
    });
  }

  private setLoginDisplay() {
    const isLogged = this.authService.instance.getAllAccounts().length > 0;
    this.isLogged.next(isLogged);
  }
}
