import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormsModule } from '@angular/forms';
import { CwInput } from '@bbraun/cortex-angular';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { InputValueAccessor } from '~/app/directives/InputValueAccessor';

@Component({
  selector: 'gplus-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [CwInput, FormsModule, InputValueAccessor, CustomShadowCssDirective]
})
export class InputComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() type: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() attrVef: string;
  @Input() step: number;
  @Input() validations: Record<string, any> = {};

  public error: string;
  public inputTextValue: string;
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.control) {
      this.inputTextValue = this.control.value;

      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
        this.inputTextValue = this.control.value;

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }

  public change(value: string) {
    if (!this.control) {
      return;
    }
    this.control.setValue(value);
    this.control.markAsDirty();
  }
}
