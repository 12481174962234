import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CwBanner, CwButton, CwDivider, CwIcon } from '@bbraun/cortex-angular';
import { EntityMap } from '@ngrx/signals/entities';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AutocompleteComponent } from '~/app/components/autocomplete/autocomplete.component';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { medicineUnits } from '~/app/enums/MedicineUnits';
import { CarrierSolution } from '~/app/models/generated/carrierSolution';
import { Medicine } from '~/app/models/generated/medicine';
import { Patient } from '~/app/models/generated/patient';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobForm } from '~/app/models/TherapyJobForm';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { MedicineService } from '~/app/services/medicine.service';
import { MedicineUnitValidator } from '~/app/utils/MedicineUnitValidator';
import { MedicineValidator } from '~/app/utils/medicineValidator';
import { change } from '~/app/utils/valueChanges';
import { InputComponent } from '../../input/input.component';
import { TextareaComponent } from '../../textarea/textarea.component';
import { DetailPageHeaderComponent } from '../detail-page-header/detail-page-header.component';
import { DetailPageStatus } from '../detail-page.component';

@Component({
  selector: 'gplus-detail-page-body',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    CwButton,
    CwIcon,
    CwDivider,
    CwBanner,
    DetailPageHeaderComponent,
    AutocompleteComponent,
    InputComponent,
    TextareaComponent,

    // Directives
    CustomShadowCssDirective
  ],
  templateUrl: './detail-page-body.component.html',
  styleUrl: './detail-page-body.component.scss',
  providers: [PersonNamePipe]
})
export class DetailPageBodyComponent {
  public detailPageStatus = DetailPageStatus;
  @Input() pageStatus: DetailPageStatus;
  @Input() form: FormGroup<TherapyJobForm>;
  @Input() data: SessionData;
  @Input() therapyJob: TherapyJob;
  @Input() tjNotificationMap: EntityMap<TherapyJobNotification>;

  @Output() submitChange: EventEmitter<void> = new EventEmitter();
  @Output() resetChange: EventEmitter<void> = new EventEmitter();

  public filteredAllMedicines: Array<Observable<Array<Medicine>>> = [];
  public filteredCarrier: Observable<Array<CarrierSolution>>;
  public filteredPatient: Observable<Array<Patient>>;

  public units = medicineUnits;

  public submitLoader: boolean;
  public dataLoader: boolean;

  get medicines() {
    return this.form.get('medication.medicines') as FormArray;
  }

  constructor(private readonly medicineService: MedicineService, public readonly personName: PersonNamePipe) {}

  public addMedicine() {
    this.medicines.push(
      new FormGroup({
        medicine: new FormControl(null, [], [MedicineValidator.createValidator(this.medicineService)]),
        customAmount: new FormControl(null, [Validators.min(0)]),
        unit: new FormControl(null, [Validators.required, MedicineUnitValidator.unitNotExist()])
      })
    );

    this.filteredAllMedicines.push(
      change(this.medicines.at(this.medicines.length - 1).get('medicine'), this.data?.medicines, 'display')
    );
  }

  public removeMedicine(index: number) {
    this.medicines.removeAt(index);
    this.filteredAllMedicines.splice(index, 1);
  }
}
