import { TherapyJob } from '../models/TherapyJob';

export const buildTJ: (tj: Partial<TherapyJob>) => TherapyJob = (tj) => {
  return {
    ...tj,
    annotations: tj.annotations,
    createdBy: tj.createdBy,
    createdOn: tj.createdOn,
    endTime: tj.endTime === undefined ? null : tj.endTime,
    flowRate: tj.flowRate,
    id: tj.id,
    lastModifiedBy: tj.lastModifiedBy || null,
    medication: tj.medication,
    modifiedOn: tj.modifiedOn === undefined ? null : tj.modifiedOn,
    monitorDeviceId: tj.monitorDeviceId || null,
    pairingState: tj.pairingState || null,
    patient: tj.patient,
    physician: tj.physician,
    prepared: tj.prepared === undefined ? null : tj.prepared,
    startTime: tj.startTime || null,
    status: tj.status,
    totalVolume: tj.totalVolume === undefined ? null : tj.totalVolume,
    neverPaired: tj.neverPaired === undefined ? null : tj.neverPaired,
    dripChamberDetected: tj.dripChamberDetected === undefined ? null : tj.dripChamberDetected
  };
};
