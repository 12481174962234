import { NotificationType } from '~/app/enums/NotificationId';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';

export const notifyDetachedDripChamber = (store, tj: TherapyJobExtended) => {
  const noDetected = tj.dripChamberDetected === false;
  const inRunning = tj.status === TherapyJobStatus.RUNNING;
  const unPaired = tj.pairingState === PairingStatus.UN_PAIRED || tj.pairingState === null;
  const notified = !!store.entityMap()?.[`${NotificationType.DRIP_CHAMBER_DETACHED}-${tj.id}`];

  return noDetected && !unPaired && inRunning && !notified;
};

export const unNotifyDetachedDripChamber = (store, tj: TherapyJobExtended) => {
  const detected = tj.dripChamberDetected === true;
  const noRunning = tj.status !== TherapyJobStatus.RUNNING;
  const unPaired = tj.pairingState === PairingStatus.UN_PAIRED || tj.pairingState === null;
  const notified = !!store.entityMap()?.[`${NotificationType.DRIP_CHAMBER_DETACHED}-${tj.id}`];

  return (detected || noRunning || unPaired) && notified;
};
