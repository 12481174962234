import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { DetailPageNewComponent } from './components/detail-page/detail-page-new/detail-page-new.component';
import { DetailPageViewComponent } from './components/detail-page/detail-page-view/detail-page-view.component';
import { AdminGuard } from './guards/admin.guard';
import { CanDeactivateGuard } from './guards/canDeactive.guard';
import { SessionGuard } from './guards/session.guard';
import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [SessionGuard, MsalGuard]
  },
  {
    path: 'detail',
    component: DetailPageViewComponent,
    canActivate: [SessionGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'create',
    component: DetailPageNewComponent,
    canActivate: [SessionGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    canActivate: [SessionGuard, MsalGuard, AdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
