<!-- 
    WITH VOLUME     
-->
@if(therapyJob?.totalVolume){
<!-- status switch -->
@switch (therapyJob?.status) {
<!-- Running -->
@case (therapyJobStatus.RUNNING) {

<!-- Drip chamber detected & pair-->
@if(therapyJob?.dripChamberDetected && therapyJob?.pairingState === pairingStatus.PAIRED) {
<ng-container
  *ngTemplateOutlet="
    metrics;
    context: { metrics: container === 'detail' ? ['volumeInfused', 'flowRate', 'remainingTime'] : [] }
  "
></ng-container>
<ng-container *ngTemplateOutlet="bar"></ng-container>
} @else {
<!-- With issues -->
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['lastVolumeInfused'] }"></ng-container>
@if(container === 'detail'){

<ng-container
  *ngTemplateOutlet="
    bar;
    context: { disabled: therapyJob?.pairingState === pairingStatus.UN_PAIRED || therapyJob?.pairingState === null }
  "
></ng-container>

} } }
<!-- Completed -->
@case (therapyJobStatus.COMPLETED) {

<!-- in detail page -->
@if(container === 'detail'){
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['completeVolumeInfused', 'finishedAt'] }"></ng-container>
} }
<!-- Complete confirmed -->
@case (therapyJobStatus.COMPLETE_CONFIRMED) {
<!-- in detail page -->
@if(container === 'detail'){
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['completeVolumeInfused', 'finishedAt'] }"></ng-container>
} }
<!-- Aborted -->
@case (therapyJobStatus.ABORTED) {
<!-- in detail page -->
@if(container === 'detail'){
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['volumeInfused', 'abortedAt'] }"></ng-container>
<ng-container *ngTemplateOutlet="bar; context: { disabled: true }"></ng-container>
}
<!-- in cards -->
@else {
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['volumeInfused'] }"></ng-container>
} }
<!-- Abortion triggered -->
@case (therapyJobStatus.ABORTION_TRIGGERED) {

<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['volumeInfused'] }"></ng-container>
<!-- in detail page -->
@if(container === 'detail'){
<ng-container *ngTemplateOutlet="bar; context: { disabled: true }"></ng-container>
} }
<!-- default -->
@default {
<!-- Empty -->
} } }

<!-- 
    NO VOLUME 
-->
@else {
<!-- status switch -->
@switch (therapyJob?.status) {
<!-- Running -->
@case (therapyJobStatus.RUNNING) {
<!-- Drip chamber detected & pair-->
@if(therapyJob?.dripChamberDetected && therapyJob?.pairingState === pairingStatus.PAIRED) {
<ng-container
  *ngTemplateOutlet="
    metrics;
    context: { metrics: container === 'detail' ? ['volumeInfused', 'flowRate'] : ['volumeInfused'] }
  "
></ng-container>
} @else {
<!-- With issues -->
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['lastVolumeInfused'] }"></ng-container>
} }
<!-- Complete confirmed -->
@case (therapyJobStatus.COMPLETE_CONFIRMED) {

<!-- in detail page -->
@if(container === 'detail'){
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['completeVolumeInfused', 'finishedAt'] }"></ng-container>
} }
<!-- Aborted -->
@case (therapyJobStatus.ABORTED) {
<!-- in detail page -->
@if(container === 'detail'){
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['volumeInfused', 'abortedAt'] }"></ng-container>
}
<!-- in cards -->
@else {
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['volumeInfused'] }"></ng-container>
} }
<!-- Abortion triggered -->
@case (therapyJobStatus.ABORTION_TRIGGERED) {
<ng-container *ngTemplateOutlet="metrics; context: { metrics: ['volumeInfused'] }"></ng-container>

}
<!-- default -->
@default {
<!-- Empty -->
} } }

<ng-template #bar let-disabled="disabled">
  <gp-progress-bar
    class="progress-bar {{ level }}"
    [class.disabled]="disabled"
    [class.lg]="container === 'detail'"
    [class.sm]="container === 'card'"
    [value]="
      therapyJob?.latestEvent?.remainingTime
        ? (therapyJob?.latestEvent?.remainingTime | durationFormat : timeUnit.MINUTES)
        : null
    "
    [progress]="therapyJob?.latestEvent?.injectedVolume | progress : therapyJob?.totalVolume"
  ></gp-progress-bar>
</ng-template>

<ng-template #metrics let-metrics="metrics">
  <div class="metrics">
    @for(metric of metrics; track metric){

    <!-- Switch -->
    @switch (metric) {

    <!--  volumeInfused -->
    @case ('completeVolumeInfused') {

    <div class="metric">
      <cw-icon icon="water_drop" [filled]="false" size="sm"></cw-icon>

      <span>
        {{ 'VolumeInfused' | translate }}: {{ therapyJob?.latestEvent?.injectedVolume || '-' }}
        {{ 'UnitMl' | translate }}

        <ng-container *ngIf="therapyJob?.totalVolume">
          / {{ therapyJob?.totalVolume }} {{ 'UnitMl' | translate }}
        </ng-container>
      </span>
    </div>

    }

    <!--  volumeInfused -->
    @case ('volumeInfused') {

    <div class="metric">
      <cw-icon icon="humidity_mid" [filled]="false" size="sm"></cw-icon>

      <span>
        {{ 'VolumeInfused' | translate }}: {{ therapyJob?.latestEvent?.injectedVolume || '-' }}
        {{ 'UnitMl' | translate }}

        <ng-container *ngIf="therapyJob?.totalVolume">
          / {{ therapyJob?.totalVolume }} {{ 'UnitMl' | translate }}
        </ng-container>
      </span>
    </div>

    }
    <!-- Last volume infused -->
    @case ('lastVolumeInfused') {

    <div class="metric">
      <cw-icon icon="humidity_mid" [filled]="false" size="sm"></cw-icon>

      <span>
        {{ 'LastVolumeInfused' | translate }}: {{ therapyJob?.latestEvent?.injectedVolume || '-' }}
        {{ 'UnitMl' | translate }}
        <ng-container *ngIf="therapyJob?.totalVolume">
          / {{ therapyJob?.totalVolume }} {{ 'UnitMl' | translate }}
        </ng-container>
      </span>
    </div>

    }
    <!--  flowRate -->
    @case ('flowRate') {

    <div class="metric">
      <cw-icon icon="speed" [filled]="false" size="sm"></cw-icon>
      <span>
        {{ 'CurrentFlowRate' | translate }}: {{ therapyJob?.latestEvent?.currentFlowRate || '-' }}
        {{ 'UnitMlHr' | translate }}
      </span>
    </div>

    }
    <!--  remainingTime -->
    @case ('remainingTime') {
    <div class="metric">
      <cw-icon icon="hourglass_empty" [filled]="false" size="sm"></cw-icon>
      <span>
        {{ 'RemainingTime' | translate }}:
        {{ (therapyJob?.latestEvent?.remainingTime | durationFormat : timeUnit.MINUTES) || '-' }}
      </span>
    </div>

    }
    <!--  abortedAt -->
    @case ('abortedAt') {

    <div class="metric">
      <cw-icon class="icon" icon="close" size="sm"></cw-icon>
      <span>
        {{ 'LabelAbortedTime' | translate }}:
        {{
          (therapyJob?.endTime
            | localizedDate : ((therapyJob?.endTime | isToday) ? dateFormat.SHORT_TIME : dateFormat.MEDIUM)) || '-'
        }}
      </span>
    </div>
    }
    <!--  finishedAt -->
    @case ('finishedAt') {

    <div class="metric">
      <cw-icon class="icon" icon="sports_score" size="sm"></cw-icon>
      <span>
        {{ 'LabelEndTime' | translate }}:
        {{
          (therapyJob?.endTime
            | localizedDate : ((therapyJob?.endTime | isToday) ? dateFormat.SHORT_TIME : dateFormat.MEDIUM)) || '-'
        }}
      </span>
    </div>

    } } }
  </div>
</ng-template>
