import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { CwButton, CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { NotificationLevel } from '~/app/enums/NotificationLevel';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { ActiveDevice } from '~/app/models/ActiveDevice';
import { MonitorDevice } from '~/app/models/MonitorDevice';
import { ResponseError, ResponseErrorExtended } from '~/app/models/ResponseError';
import { SseEventMessageKey, SsePairing } from '~/app/models/sse-event';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { PrintLabelPipe } from '~/app/pipes/PrintLabel';
import { MonitorDeviceService } from '~/app/services/monitor-device.service';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { InlineBannerComponent } from '../inline-banner/inline-banner.component';

const AUTO_PAIR_ID = 'autoPair';
const MESSAGES_MAPPING = {
  [SseEventMessageKey.PAIRING_FAILED]: 'PairingFailed',
  [SseEventMessageKey.NO_DEVICE_AVAILABLE]: 'DeviceNotFound'
};
@Component({
  selector: 'gplus-manage-device',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    CwButton,
    CwIcon,
    DropdownComponent,
    PrintLabelPipe,
    InlineBannerComponent
  ],
  templateUrl: './manage-device.component.html',
  styleUrl: './manage-device.component.scss',
  providers: [PersonNamePipe]
})
export class ManageDeviceComponent implements OnInit, OnDestroy {
  @Input() therapyJob: TherapyJobExtended;
  @Input() level: NotificationLevel;

  // we keep listening events, if other user tries to pair a
  // relevant device we use it to update the list
  @Input()
  set event(value: SsePairing) {
    this.eventSub.next(value);
    this.processEvents(value);
  }

  private eventSub: Subject<SsePairing> = new Subject();
  private unsubscribeEventSub: Subject<void> = new Subject();
  private timer: ReturnType<typeof setTimeout>;

  public message: {
    type: NotificationLevel | 'loading';
    text: string;
  };

  public defaultOption: ActiveDevice;

  public dropdownOptions: Array<ActiveDevice>;
  public loadingActiveDevices: boolean;

  public showCancel: boolean;
  public processingCancel: boolean;
  public disableSelector: boolean;

  // public enum
  public pairingStatus = PairingStatus;
  public notificationLevel = NotificationLevel;

  private destroyRef = inject(DestroyRef);

  public form: FormGroup<{ monitorDevice: FormControl<ActiveDevice> }> = new FormGroup({
    monitorDevice: new FormControl(null)
  });

  constructor(
    private readonly monitorDeviceService: MonitorDeviceService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.defaultOption = {
      id: AUTO_PAIR_ID,
      label: this.translateService.instant('AutoPairMonitorDevice'),
      attrVef: 'device-selector-dropdown-autoPair-option'
    };

    this.initializeSelector();

    this.form
      .get('monitorDevice')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: ActiveDevice) => {
        this.connectMonitorDevice(value);
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  private processEvents(event: SsePairing) {
    if (!this.therapyJob || !event?.therapyJobId || event.therapyJobId !== this.therapyJob.id) {
      return;
    }

    const deviceId = event.deviceId;

    switch (event.pairingState) {
      case PairingStatus.UN_PAIRED:
        this.showMessage(NotificationLevel.ERROR, { key: 'PairingRejectedFromMonitorDevice', deviceId });
        this.cleanMessageAfter(3000);

        this.form.reset();
        this.showCancel = false;
        this.disableSelector = false;

        break;
      case PairingStatus.IN_PAIRING:
        this.showMessage(NotificationLevel.INFO, { key: 'WaitingForPairingConfirmation', deviceId });

        this.showCancel = true;
        this.disableSelector = true;

        break;

      case PairingStatus.PAIRED:
        this.showMessage(NotificationLevel.SUCCESS, { key: 'PairingCompleted', deviceId });
        this.cleanMessageAfter(3000);

        this.form.reset();
        this.showCancel = null;
        this.disableSelector = true;

        break;

      case null:
        if (event?.messageKey) {
          this.showMessage(NotificationLevel.ERROR, {
            key: MESSAGES_MAPPING[event.messageKey] || 'ErrorGeneric',
            deviceId
          });

          console.warn('The system did not find any device available to pair.', {
            message: event.messageKey,
            ...this.therapyJob.event
          });
        } else {
          this.message = null;
        }

        this.form.reset();
        this.showCancel = false;
        this.disableSelector = false;

        break;
    }
  }

  private initializeSelector() {
    this.dropdownOptions = [this.defaultOption];

    if (this.therapyJob?.pairingState === PairingStatus.IN_PAIRING) {
      this.showMessage(NotificationLevel.INFO, {
        key: 'WaitingForPairingConfirmation',
        deviceId: this.therapyJob.monitorDeviceId
      });

      this.showCancel = true;
      this.disableSelector = true;
      this.form.patchValue({ monitorDevice: this.defaultOption }, { emitEvent: false });
    }
  }

  private showMessage(type: NotificationLevel | 'loading', translation: { key: string; deviceId?: string }) {
    clearTimeout(this.timer);

    this.message = {
      type,
      text: this.translateService.instant(translation.key, { deviceId: translation.deviceId })
    };
  }

  private cleanMessageAfter(cleanAfter: number) {
    this.timer = setTimeout(() => {
      this.message = null;
    }, cleanAfter);
  }

  private autoPair() {
    this.disableSelector = true;

    this.showMessage('loading', { key: 'PairingInProgress' });

    this.monitorDeviceService
      .autoPair(this.therapyJob?.id)
      .pipe(take(1))
      .subscribe((res) => {
        if ((res as ResponseErrorExtended)?.messageKey) {
          this.showMessage(NotificationLevel.ERROR, { key: 'DeviceNotFound' });
          this.showCancel = false;
          this.disableSelector = false;
        } else {
          this.showCancel = true;
          this.disableSelector = true;
        }
      });
  }

  private connectToDevice(deviceId) {
    this.disableSelector = true;

    this.showMessage('loading', { key: 'PairingInProgress' });

    this.monitorDeviceService
      .pairToDevice(this.therapyJob?.id, deviceId)
      .pipe(take(1))
      .subscribe((res) => {
        if ((res as ResponseErrorExtended)?.messageKey) {
          this.showMessage(NotificationLevel.ERROR, { key: (res as ResponseErrorExtended)?.messageKey, deviceId });
        } else {
          this.showMessage(NotificationLevel.SUCCESS, { key: 'PairingCompleted', deviceId: deviceId });
          this.cleanMessageAfter(3000);
        }

        this.form.reset();
        this.showCancel = false;
        this.disableSelector = false;
      });
  }

  private loadActiveDevices() {
    this.unsubscribeEventSub.next();

    if (
      this.therapyJob?.pairingState === PairingStatus.PAIRED ||
      this.therapyJob?.status === TherapyJobStatus.COMPLETED
    ) {
      // we do not request active devices on completed/paired status
      console.warn('The therapy job is already paired or completed');
      return;
    }

    this.loadingActiveDevices = true;

    const actives$ = this.monitorDeviceService.getActive(this.therapyJob?.id).pipe(take(1));
    const events$ = this.eventSub.pipe(
      takeUntil(this.unsubscribeEventSub),
      // we filter in the events of pairing status for other therapy jobs,
      // so we can update the dropdown list
      filter((ev) => ev && (ev.pairingState === PairingStatus.PAIRED || ev.therapyJobId !== this.therapyJob.id))
    );

    actives$
      .pipe(
        tap((devices: Array<MonitorDevice> | ResponseError) => {
          if ((devices as ResponseErrorExtended).messageKey) {
          } else {
            this.dropdownOptions = [
              this.defaultOption,
              ...(devices as Array<MonitorDevice>).map<ActiveDevice>((d) => ({
                id: d.id,
                label: d.id,
                attrVef: 'device-selector-dropdown-activeDevice-option',
                group: this.translateService.instant('ActiveDevices'),
                isBatteryLow: d.isBatteryLow
              }))
            ];
          }

          this.loadingActiveDevices = false;
        }),
        filter((devices: Array<MonitorDevice> | ResponseError) => !(devices as ResponseError).messageKey),
        switchMap(() =>
          events$.pipe(
            tap((event: SsePairing) => {
              // we filter out the devices that are paired
              this.dropdownOptions = this.dropdownOptions.filter((d) => d.id !== event.deviceId);
            })
          )
        )
      )
      .subscribe();
  }

  public connectMonitorDevice(option: ActiveDevice) {
    if (!option) {
      return;
    }

    if (option.id === AUTO_PAIR_ID) {
      this.autoPair();
      return;
    }
    this.connectToDevice(option.id);
  }

  public toggleDropdown(value: boolean) {
    if (value) {
      this.message = null;
      this.loadActiveDevices();
    }
  }

  public cancelPairing() {
    this.processingCancel = true;
    this.message = {
      type: 'loading',
      text: this.translateService.instant('cancellingPairing')
    };

    this.monitorDeviceService
      .cancel(this.therapyJob?.id)
      .pipe(take(1))
      .subscribe(() => {
        this.processingCancel = false;

        this.form.get('monitorDevice').setValue(null);

        this.message = null;
        this.showCancel = false;
        this.disableSelector = false;
      });
  }
}
