import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { CwButton, CwIcon } from '@bbraun/cortex-angular';
import { TranslateService } from '@ngx-translate/core';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { FilterByPipe } from '~/app/pipes/FilterBy';
import { ConfigService } from '~/app/services/config.service';
import { OverlayService } from '~/app/services/overlay.service';
import { MenuComponent, MenuItem } from '../menu/menu.component';

@Component({
  selector: 'gplus-therapy-job-menu',
  standalone: true,
  imports: [CommonModule, FilterByPipe, CwButton, CwIcon],
  templateUrl: './therapy-job-menu.component.html',
  styleUrl: './therapy-job-menu.component.scss'
})
export class TherapyJobMenuComponent implements OnInit, OnChanges {
  @Input() container: 'card' | 'detail';
  @Input() therapyJob: TherapyJobExtended;
  @Input() dataVefId: string;

  @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() togglePrepared: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleAnnotations: EventEmitter<boolean> = new EventEmitter();
  @Output() onUnpair: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAbort: EventEmitter<string> = new EventEmitter<string>();

  public menuItems: Array<MenuItem>;

  constructor(
    private overlayService: OverlayService,
    private readonly translateService: TranslateService,
    private readonly configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.setMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.therapyJob) {
      this.updateMenuOnTherapyJobUpdate(changes.therapyJob);
    }
  }

  public toggleMenu(event) {
    event.stopPropagation();

    this.overlayService.toggle({
      id: `menu-${this.therapyJob.id}`,
      component: MenuComponent,
      inputs: { items: this.menuItems, triggerElm: event.target }
    });
  }

  private setMenu() {
    if (!this.therapyJob) {
      console.warn('TherapyJob is not set');
      return;
    }

    const deleteItem: Partial<MenuItem> = this.getPossibleToDelete();
    const unpairItem: Partial<MenuItem> = this.getPossibleToUnpair();
    const abortItem: Partial<MenuItem> = this.getPossibleToAbort();

    this.menuItems = [
      {
        id: 'prepared',
        vefId: 'card-menu-optionEditPrepared',
        label: this.translateService.instant(
          this.therapyJob.prepared ? 'ActionMarkAsNotPrepared' : 'ActionMarkAsPrepared'
        ),
        icon: 'bookmark',
        disabled: false,
        visible:
          this.container === 'card' &&
          this.therapyJob.status === TherapyJobStatus.PRESCRIBED &&
          this.configService.configuration()?.content?.general.allowPreparationMarking,
        onClick: () => {
          this.preparedClicked();
        }
      },
      {
        id: 'annotations',
        vefId: 'card-menu-optionAddAnnotation',
        label: this.translateService.instant('ActionAddNotes'),
        icon: 'add_notes',
        disabled: false,
        visible: this.container === 'card',
        onClick: () => {
          this.toggleAnnotations.emit(true);
        }
      },
      {
        id: 'delete',
        vefId: 'card-menu-optionDeleteTherapyJob',
        label: this.translateService.instant('ActionDeleteTherapyJob'),
        icon: 'delete',
        disabled: deleteItem?.disabled,
        visible: deleteItem?.visible,
        tooltip: deleteItem?.tooltip,
        onClick: () => {
          this.onDelete.emit(this.therapyJob.id);
        }
      },
      {
        id: 'abort',
        vefId: 'card-menu-optionAbortTherapyJob',
        label: this.translateService.instant('ActionAbortTherapyJob'),
        icon: 'cancel',
        disabled: abortItem?.disabled,
        visible: abortItem?.visible,
        tooltip: abortItem?.tooltip,
        onClick: () => {
          this.onAbort.emit(this.therapyJob.id);
        }
      },
      {
        id: 'unpair',
        vefId: 'card-menu-optionUnpairTherapyJob',
        label: this.translateService.instant('ActionUnpairTherapyJob'),
        icon: 'link_off',
        disabled: unpairItem?.disabled,
        visible: unpairItem?.visible,
        tooltip: unpairItem?.tooltip,
        onClick: () => {
          this.onUnpair.emit(this.therapyJob.id);
        }
      }
    ];
  }

  private preparedClicked() {
    if (!this.therapyJob) {
      console.warn('TherapyJob is not set');
      return;
    }
    const newPreparedValue = !this.therapyJob.prepared;

    this.menuItems.forEach((item) => {
      if (item.id === 'prepared') {
        item.label = newPreparedValue
          ? this.translateService.instant('ActionMarkAsNotPrepared')
          : this.translateService.instant('ActionMarkAsPrepared');
      }
    });

    this.togglePrepared.emit(newPreparedValue);
  }

  /* 
    In order to prevent re-render at any update of the therapy job
    we check granularly the changes and update the menu items accordingly
   */
  private updateMenuOnTherapyJobUpdate(changes: SimpleChange) {
    if (!this.therapyJob) {
      console.warn('TherapyJob is not set');
      return;
    }
    if (
      !changes.firstChange &&
      (changes.currentValue?.status !== changes.previousValue?.status ||
        changes.currentValue?.neverPaired !== changes.previousValue?.neverPaired ||
        changes.currentValue?.pairingState !== changes.previousValue?.pairingState ||
        changes.currentValue?.dripChamberDetected !== changes.previousValue?.dripChamberDetected)
    ) {
      this.setMenu();
    }
  }

  private getPossibleToDelete(): Partial<MenuItem> {
    if (!this.therapyJob) {
      console.warn('TherapyJob is not set');
      return {};
    }

    // when the createBy is set, we have a custom therapy job
    const isCustomTj = !!this.therapyJob.createdBy;

    // We allow to delete
    if (
      this.therapyJob.status === TherapyJobStatus.PRESCRIBED &&
      this.therapyJob.neverPaired &&
      isCustomTj &&
      this.therapyJob.pairingState !== PairingStatus.PAIRED &&
      this.therapyJob.pairingState !== PairingStatus.IN_PAIRING
    ) {
      return { visible: true, disabled: false, tooltip: null };
    }

    // We disable the delete item showing a tooltip
    if (
      this.therapyJob.status === TherapyJobStatus.PRESCRIBED &&
      this.therapyJob.neverPaired &&
      isCustomTj &&
      this.therapyJob.pairingState === PairingStatus.IN_PAIRING
    ) {
      // On pairing we want to disable the delete button and add a tooltip
      return { visible: true, disabled: true, tooltip: 'TherapyJobDeletionDisabledTooltip' };
    } else {
      return { visible: false, disabled: false, tooltip: null };
    }
  }

  private getPossibleToUnpair(): Partial<MenuItem> {
    if (!this.therapyJob) {
      console.warn('TherapyJob is not set');
      return {};
    }

    // Allow to unpair
    if (this.therapyJob.pairingState === PairingStatus.PAIRED && !this.therapyJob.dripChamberDetected) {
      return { visible: true, disabled: false, tooltip: null };
    }

    // Disable the unpair item showing a tooltip
    if (this.therapyJob.pairingState === PairingStatus.PAIRED && this.therapyJob.dripChamberDetected === true) {
      return { visible: true, disabled: true, tooltip: 'TherapyJobUnpairDisabledTooltip' };
    }

    // Hide the unpair item
    return { visible: false, disabled: false, tooltip: null };
  }

  private getPossibleToAbort(): Partial<MenuItem> {
    if (!this.therapyJob) {
      console.warn('TherapyJob is not set');
      return {};
    }

    const notCustom = !this.therapyJob.createdBy;
    const hasBeenPaired = !this.therapyJob.neverPaired;
    const notCompleted =
      this.therapyJob.status === TherapyJobStatus.PRESCRIBED || this.therapyJob.status === TherapyJobStatus.RUNNING;
    const notAborted = this.therapyJob.status !== TherapyJobStatus.ABORTED;
    const notInPairing = this.therapyJob.pairingState !== PairingStatus.IN_PAIRING;

    // Allow to Abort
    if (notInPairing && notCompleted && notAborted && (notCustom || hasBeenPaired)) {
      return { visible: true, disabled: false, tooltip: null };
    }

    // disable abort item with a tooltip
    if (!notInPairing && notCompleted && notAborted && (notCustom || hasBeenPaired)) {
      return { visible: true, disabled: true, tooltip: 'TherapyJobAbortDisabledTooltip' };
    }

    // Hide the abort item
    return { visible: false, disabled: false, tooltip: null };
  }
}
