<div
  class="gplus-table"
  [ngStyle]="{
    '--columns': columns?.length,
    '--rows': data?.length
  }"
>
  <div *ngFor="let col of columns" class="gplus-cell header">
    <ng-container *ngIf="col?.translationKey">
      {{ col.translationKey | translate }}
    </ng-container>
  </div>

  @if(noData){

  <div *ngFor="let col of columns; let i = index" class="gplus-cell value">
    <ng-container *ngIf="i === 0">
      <ng-template [ngTemplateOutlet]="noDataTemplate"> </ng-template>
    </ng-container>
  </div>

  }@else {

  <ng-container *ngFor="let obj of data">
    <ng-container *ngFor="let col of columns">
      <div class="gplus-cell value">
        <ng-template [ngTemplateOutlet]="cellTemplate" [ngTemplateOutletContext]="{ item: obj, id: col?.bindValue }">
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
  }
</div>

<!-- MOBILE -->
@if(convertToList){

<div class="gplus-cards">
  @if(noData){
  <ng-template [ngTemplateOutlet]="noDataTemplate"> </ng-template>
  }@else {
  <div class="gplus-card" *ngFor="let obj of data">
    <div class="gplus-card-row" *ngFor="let col of columns" [class.title]="col?.useAsHeader">
      <span class="label">
        <ng-container *ngIf="col?.translationKey"> {{ col.translationKey | translate }}: </ng-container>
      </span>

      <span class="value">
        <ng-template [ngTemplateOutlet]="cellTemplate" [ngTemplateOutletContext]="{ item: obj, id: col?.bindValue }">
        </ng-template>
      </span>
    </div>
  </div>
  }
</div>

}
