import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter an array of objects by a property.
 */
@Pipe({
  standalone: true,
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform(objects: Array<Record<string, any>>, property: { key: string; value: any }): Array<Record<string, any>> {
    if (!objects || !property) {
      return objects;
    }

    return objects.filter((object) => object?.[property?.key] !== property?.value);
  }
}
