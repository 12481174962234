import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { setEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { Filter } from '../models/Filter';
import { TherapyJobExtended } from '../models/TherapyJob';
import { TherapyJobService } from '../services/therapy-job.service';
import { matchTime } from '../utils/matchTime';

interface JobsState {
  loading: boolean;
  filter: Filter;
}

const initialListState: JobsState = {
  loading: null,
  filter: { queries: [] }
};

export const TherapyJobStore = signalStore(
  { providedIn: 'root' },

  withEntities<TherapyJobExtended>(),
  withDevtools('jobs'),
  withState(initialListState),
  withMethods((store, jobService = inject(TherapyJobService)) => ({
    async loadAll() {
      patchState(store, { loading: true });

      const list = await jobService.getList();

      patchState(store, setEntities(list));
      patchState(store, { loading: false });
    },
    patchJob(value: Partial<TherapyJobExtended>, jobId: string) {
      patchState(
        store,
        updateEntity({
          id: jobId,
          changes: () => ({ ...value })
        })
      );
    },
    updateFilter(filter: Filter) {
      patchState(store, { filter });
    }
  })),
  withComputed(({ filter, entities }) => ({
    filtered: computed(() => {
      let filtered = entities();

      filter.queries().forEach((f) => {
        switch (f.on) {
          case 'medicine':
            filtered = filtered.filter(
              (tj) => tj?.medication?.medicines?.findIndex((m) => f.bindValue.includes(m.display)) !== -1
            );
            break;

          case 'patient':
            filtered = filtered.filter((tj) => f.bindValue.includes(tj?.patient?.id));
            break;

          case 'plannedTime':
            filtered = filtered.filter((tj) => matchTime(f.value, new Date(tj.startTime.planned)));

            break;

          case 'prepared':
            filtered = filtered.filter(
              (tj) => !!tj?.prepared === !!f.bindValue && tj?.status === TherapyJobStatus.PRESCRIBED
            );
            break;

          case 'room':
            filtered = filtered.filter((tj) => f.bindValue.includes(tj?.patient?.careUnit?.room));
            break;

          case 'search':
            const words = f.bindValue.trim().split(' ');

            filtered = filtered.filter((tj) => {
              const fullName = `${tj?.patient?.firstName} ${tj?.patient?.lastName}`;
              const fullMedicine = tj?.medication?.medicines?.map((m) => m.display).join(' ');
              const isInTheName = words.every(
                (w) => fullName?.toLowerCase().includes(w) || fullMedicine?.toLowerCase().includes(w)
              );

              return isInTheName;
            });
            break;
        }
      });

      return filtered;
    })
  }))
);
