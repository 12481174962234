<cw-datepicker
  [mode]="mode"
  [attr.data-vef]="attrVef"
  [status]="error ? 'error' : null"
  [assistiveText]="error"
  [label]="label"
  [required]="required"
  [disabled]="disabled"
  [readonly]="readonly"
  [placeholder]="placeholder"
  [value]="value"
  (dateChange)="selected($event.detail)"
>
</cw-datepicker>
