import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { Medicine } from '../models/generated/medicine';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class MedicineService {
  private medicinesObs: Observable<Array<Medicine>>;

  constructor(private readonly httpClient: HttpClient, private readonly env: EnvironmentService) {}

  public getMedicines(): Observable<Array<Medicine>> {
    if (!this.medicinesObs) {
      this.medicinesObs = this.httpClient.get<Array<Medicine>>(`${this.env.getBaseRestUrl()}/medicine`).pipe(
        catchError((err) => {
          console.warn('Error requesting medicines', err);
          return of([]);
        }),
        map((medicines: Array<Medicine>) => [...medicines].sort((a, b) => a.display.localeCompare(b.display))),
        shareReplay()
      );
    }

    return this.medicinesObs;
  }

  public medicineExists(value: string | Medicine): Observable<boolean> {
    if (!value) {
      return of(false);
    }

    return this.getMedicines().pipe(
      map((medicines) => {
        const id = value === 'string' ? value : (value as Medicine).id;

        return !!medicines?.find((med) => med.id === id);
      })
    );
  }
}
