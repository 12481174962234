// Matches that date 2 is within the full date on date 1
export const matchTime = (date1: Date, date2: Date) => {
  const start = date1 ? date1.getTime() : null;
  const end = date1 ? date1.getTime() + 1000 * 60 * 60 * 24 : null;

  if (start && end) {
    return date2.getTime() > start && date2.getTime() < end;
  }

  if (end) {
    return date2.getTime() < end;
  }

  if (start) {
    return date2.getTime() > start;
  }

  return true;
};
