@if((menuItems | filterBy : { key: 'visible', value: false })?.length){
<!-- If is a card -->
@if(container === 'card'){
<cw-button
  slot="trigger"
  rounded="true"
  button-color="secondary"
  [attr.data-vef]="dataVefId"
  (click)="toggleMenu($event)"
  [attr.data-vef]="'card-menu-toggle'"
>
  <cw-icon class="menu-trigger icon" icon="more_vert" filled="true"></cw-icon>
</cw-button>
}

<!-- If is a detail -->
@if(container === 'detail'){
<cw-button
  class="more-menu"
  button-color="secondary"
  variant="outlined"
  [attr.data-vef]="dataVefId"
  (click)="toggleMenu($event)"
>
  <cw-icon icon="more_vert" size="sm"></cw-icon>
</cw-button>

} }
