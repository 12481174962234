import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';

@Pipe({
  standalone: true,
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform(value: Array<KeyValue<string, any>>, sortProperty: string): Array<KeyValue<string, any>> {
    if (!value || !sortProperty || !value?.[sortProperty]) {
      return value;
    }
    return value.sort((a, b) => {
      if (a.value[sortProperty] < b.value[sortProperty]) {
        return -1;
      } else if (a.value[sortProperty] > b.value[sortProperty]) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
