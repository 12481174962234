@if (therapyJob?.pairingState !== pairingStatus.PAIRED && therapyJob?.status !== therapyJobStatus.COMPLETED) {

<div class="wrapper">
  <gplus-dropdown
    [attrVef]="'device-selector'"
    [control]="form?.controls?.['monitorDevice']"
    [data]="data"
    [placeholder]="'selectMonitorDevice' | translate"
    (click)="$event.stopPropagation()"
    labelType="monitorDevice"
    [multiple]="false"
    [disabled]="disableSelector"
    (menuToggle)="toggleDropdown($event.detail)"
    [loading]="loadingActiveDevices"
  ></gplus-dropdown>

  <cw-button
    *ngIf="showCancel"
    button-color="secondary"
    variant="outlined"
    type="button"
    as="button"
    (click)="$event.stopPropagation(); cancelPairing()"
    [disabled]="processingCancel"
  >
    {{ 'TherapyJobCancelPairing' | translate }}
  </cw-button>
</div>

<div class="assistive">
  @switch (message?.type) { @case('loading'){

  <div class="loading">
    <div class="loader"></div>
    {{ message?.text }}
  </div>

  } @case('error'){

  <div class="error"><cw-icon icon="error" [filled]="true" size="xs"></cw-icon>{{ message?.text }}</div>

  } @case('warning'){

  <div class="warning"><cw-icon icon="warning" [filled]="true" size="xs"></cw-icon>{{ message?.text }}</div>

  } @case('info'){

  <div class="info"><cw-icon icon="error" [filled]="true" size="xs"></cw-icon>{{ message?.text }}</div>

  } @case('success'){

  <div class="success"><cw-icon icon="check_circle" [filled]="true" size="xs"></cw-icon>{{ message?.text }}</div>

  } }
</div>

}
