import { AfterViewInit, Component, DestroyRef, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { CwDatepicker } from '@bbraun/cortex-angular';
import { generateUniqueId } from '~/app/utils/generateUniqueId';

@Component({
  selector: 'gplus-datepicker',
  standalone: true,
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  imports: [CwDatepicker]
})
export class DatepickerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() attrVef: string;
  @Input() control: AbstractControl;
  @Input() disabled: boolean;
  @Input() instanceId: string = generateUniqueId();
  @Input() label: string;
  @Input() mode: 'single' | 'range' = 'single';
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() validations: Record<string, any> = {};

  public error: string;
  public value: Date;

  private destroyRef = inject(DestroyRef);
  private flatpickerContainerElm: HTMLElement;

  constructor(private readonly render: Renderer2) {}

  ngOnInit(): void {
    if (this.control) {
      this.value = this.control.value;

      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: Date) => {
        this.value = res;

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }

  ngAfterViewInit(): void {
    this.addDataVefToFlatpickerContainer();
  }

  ngOnDestroy(): void {
    this.destroyFlatpickerContainer();
  }

  private addDataVefToFlatpickerContainer(): void {
    setTimeout(() => {
      this.flatpickerContainerElm = document.querySelector(`.flatpickr-calendar:not([data-vef*="flatpicker-"])`);
      if (this.flatpickerContainerElm) {
        this.render?.setAttribute(this.flatpickerContainerElm, 'data-vef', `flatpicker-${this.instanceId}`);
      }
    }, 100);
  }

  private destroyFlatpickerContainer(): void {
    if (this.flatpickerContainerElm) {
      this.render.removeChild(document.querySelector('body'), this.flatpickerContainerElm);
    }
  }

  public selected(inputtedValue: CustomEvent<Array<Date>>) {
    if (!this.control) {
      console.warn('No control provided for datepicker');
      return;
    }
    this.control.setValue(this.mode === 'single' ? inputtedValue[0] : inputtedValue);
    this.control.markAsDirty();
  }
}
