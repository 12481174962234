import { Injectable, signal, WritableSignal } from '@angular/core';
import { AlarmCenterComponent } from '../components/alarm-center/alarm-center.component';

export enum SidebarIds {
  ALARM_CENTER = 'alarmCenter'
}

export interface Sidebar {
  open: boolean;
  id: string;
  config: {
    title: string;
    actionLabel: string;
    secondaryLabel: string;
  };
  component: any;
}

const sidebars: Array<Sidebar> = [
  {
    open: false,
    id: SidebarIds.ALARM_CENTER,
    config: {
      title: 'AlarmCenterTitle',
      actionLabel: null,
      secondaryLabel: 'AlarmCenterCloseBtn'
    },
    component: AlarmCenterComponent
  }
];

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarSignal: WritableSignal<Sidebar> = signal(null);
  public sidebar = this.sidebarSignal.asReadonly();

  public open(id: string): void {
    const sidebar = sidebars.find((d) => d.id === id);
    this.sidebarSignal.update(() => ({ ...sidebar, open: true }));
  }

  public close() {
    this.sidebarSignal.update((current) => ({ ...current, open: false }));
  }
}
