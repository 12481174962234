import { AbstractControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

export const change: (control: AbstractControl, array: Array<any>, valueProp?: string) => Observable<Array<any>> = (
  control,
  array,
  valueProp
) => {
  return control.valueChanges.pipe(
    startWith(''),
    map((value) => {
      if (!value || typeof value !== 'string') {
        return array;
      }

      if (valueProp) {
        return array.filter((o) => o[valueProp].toLowerCase().startsWith(value.toLowerCase()));
      }

      return array.filter((o: string) => o.toLowerCase().startsWith(value.toLowerCase()));
    })
  );
};
