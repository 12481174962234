import { PairingStatus } from '../enums/PairingStatus';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';

export enum SseEventType {
  JOB_FINISH = 'JOB_FINISH',
  JOB_START = 'JOB_START',
  JOB_UPDATE = 'JOB_UPDATE',
  PAIRING = 'PAIRING',
  JOB_STATE = 'JOB_STATE',
  JOB_DELETED = 'JOB_DELETED',
  NEW_THERAPY_JOB = 'NEW_THERAPY_JOB',
  DRIP_CHAMBER_DETECTION = 'DRIP_CHAMBER_DETECTION',
  JOB_FINALIZED = 'JOB_FINALIZED',
  ABORTION_TRIGGERED = 'ABORTION_TRIGGERED'
}

export enum SseEventMessageKey {
  PAIRING_FAILED = 'pairing_failed', // System is wrong
  NO_DEVICE_AVAILABLE = 'no_device_available' // keep trying with auto
}

export interface SseInfusion {
  therapyJobId: string;
  plannedFlowRate: number;
  currentFlowRate: number;
  injectedVolume: number;
  remainingVolume: number;
  remainingTime: number;
  totalVolume: number;
  ts: number;
  type?: SseEventType;
}

export interface SsePairing {
  therapyJobId: string;
  pairingState: PairingStatus;
  deviceId: string;
  therapyJobStatus?: TherapyJobStatus;
  messageKey: string;
  ts: number;
  type?: SseEventType;
}

// Only received for running therapy jobs
// and only for other metrics...
export interface SseState {
  therapyJobId: string;
  dripChamberTilted: boolean;
  sensorNotPlausible: boolean;
  sensorSaturated: boolean;
  sensorDefective: boolean;
  ts: number;
  type?: SseEventType;
  timestamp?: number;
}

export interface SseCreation {
  therapyJobIds: Array<string>;
  type?: SseEventType;
}

export interface SseDeletion {
  therapyJobId: string;
  type?: SseEventType;
}

export interface SseDripChamberDetected {
  detected: boolean;
  ts: number;
  therapyJobId: string;
  type?: SseEventType;
}

export interface SseFinalized {
  ts: number;
  therapyJobId: string;
  type?: SseEventType;
}

export interface SseAborted {
  ts: number;
  therapyJobId: string;
  type?: SseEventType;
}
