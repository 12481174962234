@if(formInstance?.form){
<!--  -->
@if(tjNotificationMap){
<div class="full-group notifications">
  <ng-container *ngFor="let item of tjNotificationMap | keyvalue">
    <gplus-inline-banner
      [status]="item.value.level"
      [label]="item.value.translationKey | translate"
    ></gplus-inline-banner>
  </ng-container>
</div>
}
<!-- Progress section -->
@if (therapyJob?.status && therapyJob?.status !== therapyStatus.PRESCRIBED ) {
<section class="full-group progress-bar-section">
  <h4>{{ 'FormTJCreationProgressTitle' | translate }}</h4>

  <!-- For now only on running we show the progress bar until we have the infused data from BE -->
  @if(therapyJob?.status !== therapyStatus.PRESCRIBED){
  <gplus-infusion container="detail" [level]="mostCriticalStatus" [therapyJob]="therapyJob"></gplus-infusion>
  }
</section>

<cw-divider class="full-group" axis="horizontal" type="default"></cw-divider>
}

<!-- Body Form -->
@if(_renderFields){

<section class="full-group page-body-section" [formGroup]="formInstance?.form">
  <h4 class="full-group page-body-section-title">
    {{ 'FormTJCreationDrugSection' | translate }}
  </h4>

  <ng-container
    *ngIf="formInstance?.form?.controls?.['medication']"
    [formGroup]="formInstance?.form.controls['medication']"
  >
    <ng-container formArrayName="medicines">
      @for (medicineObj of formInstance?.form?.controls?.['medication']?.controls?.['medicines']?.controls; track
      trackFn(medicineObj); let i = $index; let last = $last; let first = $first) {

      <ng-container [formGroup]="medicineObj">
        <div class="first-group">
          <gplus-autocomplete
            [control]="medicineObj.controls['medicine']"
            filteredBy="display"
            [data]="data?.medicines"
            [label]="'FormTJCreationMedicine' | translate"
            [required]="medicineObj.controls['medicine']?.status !== 'DISABLED'"
            [disabled]="formInstance.disabledForm()"
            [readonly]="medicineObj.controls['medicine']?.status === 'DISABLED'"
            [placeholder]="'FormTJCreationMedicinePlaceholder' | translate"
            [attrVef]="'form-create-manual-medicine'"
            [attrVefItem]="'form-create-manual-dropdown-item'"
            [validations]="{
              required: 'FormTJCreationMedicineIsRequired' | translate,
              unauthorizedMedicine: 'FormTJCreationPleaseSelectOneOfTheList' | translate
            }"
            labelType="medicine"
          ></gplus-autocomplete>
        </div>

        <div class="second-group">
          <gplus-input
            type="text"
            [control]="medicineObj.controls['customAmount']"
            [label]="'FormTJCreationDose' | translate"
            [required]="medicineObj.controls['customAmount']?.status !== 'DISABLED'"
            [disabled]="formInstance.disabledForm()"
            [readonly]="medicineObj.controls['customAmount']?.status === 'DISABLED'"
            [placeholder]="'FormTJCreationDosePlaceholder' | translate"
            [attrVef]="'form-create-manual-dose'"
            [validations]="{
        required: 'FormTJCreationDoseIsRequired' | translate,
        max: 'FormTJCreationMaxDosageLimit' | translate: {amount: medicineObj?.controls?.['medicine']?.value?.amount},
        min: 'FormTJCreationMinLimit' | translate,
        notNumber: 'FormTJCreationNotNumber' | translate
      }"
          ></gplus-input>
        </div>

        <div class="third-group">
          <gplus-autocomplete
            filteredBy="label"
            [control]="medicineObj.controls['unit']"
            [label]="'FormTJCreationUnit' | translate"
            [data]="units"
            [required]="medicineObj.controls['unit']?.status !== 'DISABLED'"
            [disabled]="formInstance.disabledForm()"
            [readonly]="medicineObj.controls['unit']?.status === 'DISABLED'"
            [placeholder]="'FormTJCreationUnitPlaceholder' | translate"
            [attrVef]="'form-create-manual-unit'"
            [attrVefItem]="'form-create-manual-dropdown-item'"
            [validations]="{
              required: 'FormTJCreationUnitIsRequired' | translate,
              unitNotExist: 'FormTJCreationPleaseSelectOneOfTheList' | translate
            }"
            labelType="unit"
          ></gplus-autocomplete>
        </div>
      </ng-container>
      }
    </ng-container>

    <cw-divider class="full-group" axis="horizontal" type="default"></cw-divider>

    <div class="first-group">
      <gplus-autocomplete
        filteredBy="description"
        attrVef="form-create-manual-carrier"
        [attrVefItem]="'form-create-manual-dropdown-item'"
        labelType="carrier"
        [control]="formInstance?.form.controls['medication'].controls['carrier']"
        [data]="data?.carriers"
        [label]="'FormTJCreationCarrier' | translate"
        [required]="formInstance?.form.controls['medication'].controls['carrier']?.status !== 'DISABLED'"
        [disabled]="formInstance.disabledForm()"
        [readonly]="formInstance?.form.controls['medication'].controls['carrier']?.status === 'DISABLED'"
        [placeholder]="'FormTJCreationCarrierPlaceholder' | translate"
        [validations]="{
            required: 'FormTJCreationCarrierIsRequired' | translate,
          }"
      ></gplus-autocomplete>
    </div>
  </ng-container>

  <div class="second-group">
    <gplus-input
      type="text"
      [control]="formInstance?.form.controls['volume']"
      [label]="'FormTJCreationVolume' | translate"
      [required]="formInstance?.form.controls['volume']?.status !== 'DISABLED'"
      [disabled]="formInstance.disabledForm()"
      [readonly]="formInstance?.form.controls['volume']?.status === 'DISABLED'"
      [placeholder]="'FormTJCreationVolumePlaceholder' | translate"
      [attrVef]="'form-create-manual-volume'"
      [validations]="{
        min: 'FormTJCreationMinLimit' | translate,
        notNumber: 'FormTJCreationNotNumber' | translate
      }"
    ></gplus-input>
  </div>

  <div class="third-group">
    <gplus-input
      type="text"
      [control]="formInstance?.form.controls['flowRate']"
      [label]="'FormTJCreationFlowRate' | translate"
      [required]="formInstance?.form.controls['flowRate']?.status !== 'DISABLED'"
      [disabled]="formInstance.disabledForm()"
      [readonly]="formInstance?.form.controls['flowRate']?.status === 'DISABLED'"
      [placeholder]="'FormTJCreationFlowRatePlaceholder' | translate"
      [attrVef]="'form-create-manual-flowrate'"
      [validations]="{
        min: 'FormTJCreationMinLimit' | translate,
        notNumber: 'FormTJCreationNotNumber' | translate,
      }"
    ></gplus-input>
  </div>
</section>

<section *ngIf="showPhysician" class="full-group page-body-section" [formGroup]="formInstance?.form">
  <gplus-autocomplete
    class="first-group"
    [filteredBy]="['lastName', 'firstName']"
    attrVef="form-create-manual-physician"
    [attrVefItem]="'form-create-manual-dropdown-item'"
    labelType="personName"
    [data]="data?.physicians"
    [control]="formInstance?.form.controls?.['physician']"
    [label]="'FormTJCreationPhysician' | translate"
    [disabled]="formInstance.disabledForm()"
    [readonly]="formInstance?.form.controls?.['physician']?.status === 'DISABLED'"
    [required]="true"
    [placeholder]="'FormTJCreationPhysicianPlaceholder' | translate"
    [validations]="{
      required: 'FormTJCreationPhysicianIsRequired' | translate,
      physicianNotExist: 'FormTJCreationPleaseSelectOneOfTheList' | translate
    }"
  ></gplus-autocomplete>

  <gplus-datepicker
    class="second-group"
    mode="single"
    attrVef="form-create-manual-planned-date"
    [instanceId]="'detail-page-form'"
    [control]="formInstance?.form.controls?.['startDate']"
    [label]="'FormTJCreationStartDate' | translate"
    [disabled]="formInstance.disabledForm()"
    [readonly]="formInstance?.form.controls['startDate'].status === 'DISABLED'"
    [required]="true"
    [placeholder]="'FormTJCreationStartDatePlaceholder' | translate"
    [validations]="{
        required: 'FormTJCreationDateIsRequired' | translate,
      }"
  ></gplus-datepicker>

  <gplus-input
    class="third-group"
    type="time"
    attrVef="form-create-manual-planned-time"
    [control]="formInstance?.form.controls['hours']"
    [label]="'FormTJCreationStartTime' | translate"
    [disabled]="formInstance.disabledForm()"
    [readonly]="formInstance?.form.controls['hours']?.status === 'DISABLED'"
    [required]="true"
    [placeholder]="'FormTJCreationStartTimePlaceholder' | translate"
    [validations]="{
      required: 'FormTJCreationStartTimeIsRequired' | translate
    }"
  ></gplus-input>
</section>

<section class="page-body-section full-group">
  <gplus-textarea
    class="full-group"
    [label]="'FormTJCreationAnnotations' | translate"
    [control]="formInstance?.form.controls['annotations']"
    [disabled]="formInstance.disabledForm()"
    [readonly]="formInstance?.form.controls['annotations']?.status === 'DISABLED'"
    [placeholder]="'FormTJCreationNotesPlaceholder' | translate"
    [attrVef]="'form-create-manual-annotations'"
    [validations]="{
      notString: 'FormTJCreationNotString' | translate,
      maxlength: 'FormTJCreationAnnotationsMaxCharacters' | translate : { max: annotationMaxLength }
    }"
    [assistiveText]="'FormTJCreationAnnotationsMaxCharacters' | translate : { max: annotationMaxLength }"
  ></gplus-textarea>
</section>

<div class="full-group">
  <ng-content> </ng-content>
</div>
}@else {

<div class="no-permission full-group">
  {{ 'OnDemandSectionNoPermissionManualCreation' | translate }}
</div>
} }
