import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { medicineUnits } from '../enums/MedicineUnits';

export class MedicineUnitValidator {
  static unitNotExist(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control?.value) {
        return null;
      }

      return medicineUnits.findIndex((u) => u.id === control.value.id) === -1 ? { unitNotExist: true } : null;
    };
  }
}
