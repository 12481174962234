import { NotificationType } from '~/app/enums/NotificationId';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';

export const notifyAbortTriggered = (store, tj: TherapyJobExtended) => {
  const abortedTriggeredStatus = tj.status === TherapyJobStatus.ABORTION_TRIGGERED;
  const notified = !!store.entityMap()?.[`${NotificationType.THERAPY_JOB_ABORT_TRIGGERED}-${tj.id}`];

  return abortedTriggeredStatus && !notified;
};

export const unNotifyAbortTriggered = (store, tj: TherapyJobExtended) => {
  const noAbortedTriggeredStatus = tj.status !== TherapyJobStatus.ABORTION_TRIGGERED;
  const notified = !!store.entityMap()?.[`${NotificationType.THERAPY_JOB_ABORT_TRIGGERED}-${tj.id}`];

  return noAbortedTriggeredStatus && notified;
};
