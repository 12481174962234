import { Observable } from 'rxjs';

export enum FilterControl {
  CHECKBOX = 'CHECKBOX',
  SELECTOR = 'SELECTOR',
  DATE = 'DATE',
  INPUT_TEXT = 'INPUT_TEXT',
  INPUT_NUMBER = 'INPUT_NUMBER'
}

export interface FilterConfig {
  id: string;

  // Need it for checkboxes
  label?: string;
  placeholder?: string;

  control: FilterControl;
  quickFilter?: boolean;

  // For selectors, the list of filtered items
  filteredOptions?: Observable<Array<any>>;
  group?: Array<{ controlName: string; placeholder: string }>;

  // Value to use to search with the auto complete
  typeAheadBindValue?: string;

  // Value that we can use to search on the
  // properties of the therapy jobs
  // e.g for physician, we want to use the ID to search
  bindValue?: string;
  show: boolean;
  multiple?: boolean;
}

export const filtersConfig: Array<FilterConfig> = [
  {
    id: 'prepared',
    label: 'FiltersPreparedLabel',
    control: FilterControl.CHECKBOX,
    quickFilter: false,
    show: true
  },
  {
    id: 'plannedTime',
    control: FilterControl.DATE,
    placeholder: 'FiltersPlannedTimeLabel',
    group: [
      {
        controlName: 'start',
        placeholder: 'FiltersStartTimeLabel'
      },
      {
        controlName: 'end',
        placeholder: 'FiltersEndTimeLabel'
      }
    ],
    quickFilter: false,
    show: true
  },
  {
    id: 'patient',
    control: FilterControl.SELECTOR,
    placeholder: 'FiltersPatientLabel',
    quickFilter: false,
    typeAheadBindValue: 'lastName',
    bindValue: 'id',

    show: true,
    multiple: true
  },
  {
    id: 'room',
    label: 'Room',
    control: FilterControl.SELECTOR,
    placeholder: 'FiltersRoomLabel',
    quickFilter: false,
    typeAheadBindValue: null,
    bindValue: null,
    show: true,
    multiple: true
  },
  {
    id: 'medicine',
    label: 'Medicine',
    control: FilterControl.SELECTOR,
    placeholder: 'FiltersMedicineLabel',
    quickFilter: false,
    typeAheadBindValue: 'display',
    bindValue: 'display',
    show: true,
    multiple: true
  },
  {
    id: 'physician',
    label: 'Physician Responsible',
    control: FilterControl.SELECTOR,
    placeholder: 'FiltersPhysicianLabel',
    quickFilter: false,
    typeAheadBindValue: 'lastName',
    bindValue: 'id',
    show: false
  },
  {
    id: 'carrier',
    label: 'Carrier',
    control: FilterControl.SELECTOR,
    placeholder: 'FiltersCarrierLabel',
    quickFilter: false,
    typeAheadBindValue: 'description',
    bindValue: 'description',
    show: false
  }
];
