import { NotificationType } from '~/app/enums/NotificationId';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';

export const notifyUnpairOnRunning = (store, tj: TherapyJobExtended) => {
  const unpaired = tj.pairingState === PairingStatus.UN_PAIRED;
  const isNull = tj.pairingState === null;
  const notified = !!store.entityMap()?.[`${NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD}-${tj.id}`];
  const running = tj.status === TherapyJobStatus.RUNNING;

  return running && (unpaired || isNull) && !notified;
};

export const unNotifyUnpairOnRunning = (store, tj: TherapyJobExtended) => {
  const unpaired = tj.pairingState === PairingStatus.UN_PAIRED;
  const isNull = tj.pairingState === null;
  const notified = !!store.entityMap()?.[`${NotificationType.THERAPY_JOB_UNPAIRED_FROM_MD}-${tj.id}`];

  return !unpaired && !isNull && notified;
};
