import { Component } from '@angular/core';

@Component({
  selector: 'gplus-detail-page-sidebar',
  standalone: true,
  imports: [],
  templateUrl: './detail-page-sidebar.component.html',
  styleUrl: './detail-page-sidebar.component.scss',
  providers: []
})
export class DetailPageSidebarComponent {}
