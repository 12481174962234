import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, switchMap, take } from 'rxjs';
import { EmployeeType } from '../enums/EmployeeType';
import { EmployeeService } from '../services/employee.service';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private readonly employeeService: EmployeeService,
    private readonly router: Router
  ) {}

  canActivate() {
    return this.userService.getMe().pipe(
      take(1),
      switchMap((me) => this.employeeService.getEmployeeTypes(me.id)),
      map((employeeTypes: Array<EmployeeType>) => {
        if (employeeTypes.some((type) => type === EmployeeType.ADMIN)) {
          return true;
        } else {
          this.router.navigate(['/home']);
          return false;
        }
      })
    );
  }
}
