import { animate, style, transition, trigger } from '@angular/animations';

export const enterLeave = trigger('enterLeave', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(0.8)' }),
    animate('300ms ease-in', style({ opacity: 1, transform: 'scale(1)' }))
  ])
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0 }), animate('400ms ease-in', style({ opacity: 1 }))])
]);

export const slideIn = trigger('slideIn', [
  transition(':enter', [
    style({ transform: 'translate(-100%, 0)' }),
    animate('300ms ease-in', style({ transform: 'translate(0%, 0)' }))
  ])
]);

export const slideOut = trigger('slideOut', [
  transition(':leave', [
    style({ transform: 'translate(0%, 0)' }),
    animate('300ms ease-in', style({ transform: 'translate(-100%, 0)' }))
  ])
]);
