import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CwBadgeStatus, CwButton, CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { enterLeave } from '~/app/animations/enterLeave';
import { NotificationType } from '~/app/enums/NotificationId';
import { NotificationLevel } from '~/app/enums/NotificationLevel';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { SsePairing } from '~/app/models/sse-event';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { HighlightPipe } from '~/app/pipes/Hightlight';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { ConfigService } from '~/app/services/config.service';
import { HighlightService, TJHighLightMap } from '~/app/services/highlight.service';
import { OverlayService } from '~/app/services/overlay.service';
import { ComponentAlert } from '~/app/utils/ComponentAlert';
import { DurationFormatPipe, TimeUnit } from '../../pipes/DurationFormat';
import { AnnotationsComponent } from '../annotations/annotations.component';
import { InfusionComponent } from '../infusion/infusion.component';
import { InlineBannerComponent } from '../inline-banner/inline-banner.component';
import { ManageDeviceComponent } from '../manage-device/manage-device.component';
import { TherapyJobMenuComponent } from '../therapy-job-menu/therapy-job-menu.component';
import { TherapyJobBodyComponent } from './therapy-job-body/therapy-job-body.component';

const HIGHLIGHT_DURATION = 3000;
const HIGHLIGHT_ITERATION = 3;
const HIGHLIGHT_DELAY = 100;

@Component({
  selector: 'gplus-therapy-job',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    TranslateModule,
    RouterModule,
    CwButton,
    CwIcon,
    CwBadgeStatus,

    // Pipes
    HighlightPipe,
    PersonNamePipe,

    // Components
    ManageDeviceComponent,
    AnnotationsComponent,
    InfusionComponent,
    TherapyJobBodyComponent,
    TherapyJobMenuComponent,
    InlineBannerComponent
  ],
  templateUrl: './therapy-job.component.html',
  styleUrl: './therapy-job.component.scss',
  animations: [enterLeave],
  providers: [TranslatePipe, DurationFormatPipe]
})
export class TherapyJobComponent extends ComponentAlert implements OnChanges, OnDestroy {
  @Input() highlightTerm: string;
  @Input() event: SsePairing;

  public displayName: string;
  public showAnnotations: boolean;
  // Make public enums
  public therapyJobStatus = TherapyJobStatus;
  public notificationType = NotificationType;
  public notificationLevel = NotificationLevel;
  public pairingStatus = PairingStatus;
  public timeUnit = TimeUnit;

  /*
    Animations
  */
  @HostBinding('@enterLeave') animate = true;

  @HostBinding('style.--highlight-duration') highlightDurationVar = `${HIGHLIGHT_DURATION}ms`;
  @HostBinding('style.--highlight-iteration') iterationDurationVar = HIGHLIGHT_ITERATION;
  @HostBinding('style.--highlight-delay') delayDurationVar = `${HIGHLIGHT_DELAY}ms`;
  @HostBinding('class.highlight-job') highlight: boolean;

  @Input() set tjHighlightMap(value: TJHighLightMap) {
    if (this.therapyJob.id && value[this.therapyJob.id]) {
      this.highlight = true;

      this.elm.nativeElement.scrollIntoView({ behavior: 'smooth' });

      // After the animation is complete we want to clean
      setTimeout(() => {
        this.highlight = false;
        this.highlightService.cleanHighlightTJ(this.therapyJob.id);
      }, HIGHLIGHT_DURATION * HIGHLIGHT_ITERATION + HIGHLIGHT_DELAY);
    }
  }

  @Output() onChange: EventEmitter<{ value: Partial<TherapyJobExtended>; id: string }> = new EventEmitter();

  @Output() onDelete: EventEmitter<string> = new EventEmitter();
  @Output() onUnpair: EventEmitter<string> = new EventEmitter();
  @Output() onFinalize: EventEmitter<TherapyJobExtended> = new EventEmitter();
  @Output() onAbort: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly highlightService: HighlightService,
    private readonly overlayService: OverlayService,
    private readonly elm: ElementRef,
    override readonly durationPipe: DurationFormatPipe,
    override readonly translatePipe: TranslatePipe,
    private readonly router: Router,
    public readonly configService: ConfigService
  ) {
    super(durationPipe, translatePipe);
  }

  ngOnDestroy(): void {
    if (this.therapyJob) {
      this.highlightService.cleanHighlightTJ(this.therapyJob.id);
    }
  }

  public togglePrepared(value: boolean) {
    this.onChange.emit({ id: this.therapyJob.id, value: { prepared: value } });
  }

  public onSubmitAnnotations(value: string) {
    this.onChange.emit({ id: this.therapyJob.id, value: { annotations: value } });
  }

  public toggleAnnotations(value: boolean) {
    this.showAnnotations = value;
  }

  public openDetail() {
    if (this.overlayService.overlay()?.open) {
      return;
    }
    this.router.navigate(['/detail', { id: this.therapyJob.id }]);
  }
}
