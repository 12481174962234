import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { EmployeeType } from '../enums/EmployeeType';
import { Employee } from '../models/generated/employee';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private employees: Array<Employee>;

  constructor(private readonly httpClient: HttpClient, private readonly env: EnvironmentService) {}

  public getEmployees(type?: EmployeeType): Observable<Array<Employee>> {
    return this.httpClient.get<Array<Employee>>(`${this.env.getBaseRestUrl()}/employee`).pipe(
      catchError((err) => {
        console.warn('Error requesting employees', err);
        return of([]);
      }),
      map((employees: Array<Employee>) => {
        this.employees = [...employees].sort((a, b) => a.lastName.localeCompare(b.lastName));
        if (!type) {
          return this.employees;
        }
        return this.employees.filter((e) => e.type === type);
      })
    );
  }

  public getEmployee(id: string): Observable<Employee> {
    if (!this.employees) {
      return this.getEmployees().pipe(map((employees) => employees.find((e) => e.id === id)));
    }
    return of(this.employees?.find((e) => e.id === id));
  }

  public employeeExists(value: string | Employee): Observable<boolean> {
    if (!value) {
      return of(false);
    }

    const id = value === 'string' ? value : (value as Employee).id;

    return of(!!this.employees?.find((employee) => employee.id === id));
  }
}
