import { NotificationType } from '~/app/enums/NotificationId';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJobExtended } from '~/app/models/TherapyJob';

export const notifyPendingConfirmation = (store, tj: TherapyJobExtended) => {
  const inPendingStatus = tj.status === TherapyJobStatus.COMPLETED;
  const notified = !!store.entityMap()?.[`${NotificationType.PENDING_CONFIRMATION}-${tj.id}`];

  return inPendingStatus && !notified;
};

export const unNotifyPendingConfirmation = (store, tj: TherapyJobExtended) => {
  const inPendingStatus = tj.status === TherapyJobStatus.COMPLETED;
  const notified = !!store.entityMap()?.[`${NotificationType.PENDING_CONFIRMATION}-${tj.id}`];

  return !inPendingStatus && notified;
};
