<cw-textarea
  [class.disabled]="disabled"
  [class.readonly]="readonly"
  [attr.data-vef]="attrVef"
  [ngModel]="inputTextValue"
  (ngModelChange)="change($event)"
  [placeholder]="placeholder"
  rows="3"
  [label]="label"
  [assistiveText]="error || assistiveText"
  [required]="required"
  [disabled]="disabled"
  [readOnly]="readonly"
  [status]="error ? 'error' : null"
></cw-textarea>
