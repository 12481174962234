@if(loader){

<div class="container-loader">
  <span class="loader absolute-centered"></span>
</div>
} @if(data && configService.configuration()?.content && formInstance?.form){

<form class="page" [class.loading]="loader" [formGroup]="formInstance?.form">
  <gplus-detail-page-header
    [therapyJob]="therapyJob"
    [formInstance]="formInstance"
    [creationMode]="true"
    [data]="data"
  ></gplus-detail-page-header>

  <div class="content">
    <gplus-detail-page-sidebar>
      <gplus-therapies-on-demand
        [formInstance]="formInstance"
        [data]="data"
        [resetChange]="resetChange"
        [configuration]="configService.configuration()?.content"
        (selected)="selectedTherapy($event)"
      ></gplus-therapies-on-demand>
    </gplus-detail-page-sidebar>

    <gplus-detail-page-body
      [data]="data"
      [formInstance]="formInstance"
      [showPhysician]="true"
      [renderFields]="permissionCreate"
    >
    </gplus-detail-page-body>
  </div>
  <gplus-detail-page-form-actions
    *ngIf="permissionCreate"
    class="actions"
    [disableSubmitTooltip]="(
      !formInstance?.form?.controls?.['patient']?.value ? 'FormTJCreationPatientNoSelectedTooltip' : 
      !formInstance?.form?.valid ? 'FormTJCreationMissingRequiredFields' : 
      '') | translate"
    [showSubmitButtonDisabled]="!this.formInstance.form.valid"
    [showResetButtonDisabled]="!this.formInstance.form.dirty"
    [formInstance]="formInstance"
    (submitChange)="submitChange()"
    (resetChange)="resetChange.emit()"
  ></gplus-detail-page-form-actions>
</form>

}
