import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, lastValueFrom, map, of } from 'rxjs';
import { TherapyJob, TherapyJobExtended } from '../models/TherapyJob';
import { buildTJ } from '../utils/buildTJ';
import { EnvironmentService } from './environment.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class TherapyJobService {
  private therapyJobsSignal: WritableSignal<Array<TherapyJobExtended>> = signal([]);
  public therapyJobs: Signal<Array<TherapyJobExtended>> = this.therapyJobsSignal.asReadonly();

  constructor(
    private readonly httpClient: HttpClient,
    protected readonly sessionService: SessionService,
    private readonly translateService: TranslateService,
    private readonly env: EnvironmentService
  ) {}

  public getList(): Promise<Array<TherapyJob>> {
    return lastValueFrom(
      this.httpClient.post<Array<Partial<TherapyJob>>>(`${this.env.getBaseRestUrl()}/therapyjob/list`, null).pipe(
        catchError((err) => {
          console.warn('Error requesting therapy jobs', err);
          return of([]);
        }),
        map((partialTherapyJobs: Array<Partial<TherapyJob>>) =>
          partialTherapyJobs.map((tj: Partial<TherapyJob>) => buildTJ(tj))
        )
      )
    );
  }

  public updateTherapyJob(value: Partial<TherapyJob>, id: string): Observable<void | { error: string }> {
    return this.httpClient.patch<void>(`${this.env.getBaseRestUrl()}/therapyjob/${id}`, value).pipe(
      catchError((err) => {
        console.warn('Error Updating Therapy Job', err);
        const snackbarConfig = {
          type: 'error',
          message: this.translateService.instant('ErrorUpdating'),
          enableCloseButton: true,
          delay: 20000
        };
        window.dispatchEvent(new CustomEvent('cw-snackbar-open', { detail: snackbarConfig }));
        return of({ error: 'Error Updating Therapy Job' });
      })
    );
  }

  public createTherapyJob(therapyJob: Partial<TherapyJob>): Observable<void> {
    return this.httpClient.post<void>(`${this.env.getBaseRestUrl()}/therapyjob`, therapyJob).pipe(
      catchError((err) => {
        console.warn('Error saving therapy jobs', err);
        const snackbarConfig = {
          type: 'error',
          message: this.translateService.instant('ErrorCreation'),
          enableCloseButton: true,
          delay: 20000
        };
        window.dispatchEvent(new CustomEvent('cw-snackbar-open', { detail: snackbarConfig }));
        return of();
      })
    );
  }

  public getTherapyJobByMonitorDevice(id: string): TherapyJob {
    return this.therapyJobs().find((tj) => tj.monitorDeviceId === id);
  }
}
