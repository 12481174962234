import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { Overlay, OverlayService } from '~/app/services/overlay.service';

@Component({
  selector: 'gplus-overlay',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './overlay.component.html',
  styleUrl: './overlay.component.scss'
})
export class OverlayComponent {
  @Input() overlay: Overlay;

  @HostListener('document:click', ['$event'])
  onClick() {
    if (this.overlayService.overlay()?.open) {
      this.overlayService.close();
    }
  }

  constructor(private readonly overlayService: OverlayService) {}
}
