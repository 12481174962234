import { FormGroup } from '@angular/forms';
import { FlowRateUnit } from '../enums/FlowRateUnit';
import { TherapyJobForm } from '../models/TherapyJobForm';
import { NewTherapyJob } from '../models/generated/newTherapyJob';
import { Prescription } from '../models/prescription';

export const getPayloadFromForm: (form: FormGroup<TherapyJobForm>) => NewTherapyJob = (form) => {
  const rawValue = form.getRawValue();
  const time = rawValue.hours.split(':');
  const startTimePlanned = rawValue.startDate ? rawValue.startDate.setHours(Number(time[0]), Number(time[1])) : 0;

  const medication = rawValue.medication;

  return {
    physicianId: rawValue.physician.id,
    annotations: rawValue.annotations,
    medication: {
      ...medication,
      medicines: medication.medicines.map((med) => {
        return {
          id: med.medicine.id,
          code: med.medicine.code,
          amount: med.customAmount,
          unit: med.unit,
          display: med.medicine.display
        };
      }),
      carrier:
        typeof medication.carrier === 'string'
          ? {
              id: null,
              wardId: null,
              description: medication.carrier as string
            }
          : medication.carrier
    },
    patientId: rawValue.patient.id,
    flowRate: {
      planned: rawValue.flowRate,
      unit: FlowRateUnit.MILLILITERS_PER_HOUR
    },
    totalVolume: rawValue.volume,
    startTime: {
      planned: startTimePlanned
    }
  };
};

export const getPayloadFromPrescription = (prescription: Prescription) => {
  return {
    prescription: prescription.id,
    physicianId: prescription.physician,
    medication: prescription.medication,
    patientId: prescription.patient.id,
    flowRate: {
      planned: prescription.flowRate || 0,
      unit: FlowRateUnit.MILLILITERS_PER_HOUR
    },
    totalVolume: prescription.volume
  };
};
