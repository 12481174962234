<div class="wrapper" (click)="$event.stopPropagation()">
  @if(notificationsStore.loading()){
  <div class="loader"></div>

  } @else {
  <!--  -->
  @if(notificationsStore.entities()?.length){
  <!--  -->
  @for( notification of notificationsStore.entities(); track notification.id){
  <gplus-snackbar
    class="snack"
    [ngClass]="notification.level"
    [type]="notification.level"
    [icon]="'warning'"
    [title]="notification.translationKey | translate"
    [text]="notification?.therapyJob?.patient | personName"
    [timestamp]="notification?.timestamp | date : 'h:mm a'"
    (click)="highlight(notification?.therapyJobId)"
  >
    <cw-badge-status [badge] [status]="notification.level">
      <cw-icon icon="location_on" size="xs"></cw-icon> {{ notification?.therapyJob?.patient.careUnit.room }} -
      {{ notification?.therapyJob?.patient.careUnit.bed }}
    </cw-badge-status>
  </gplus-snackbar>

  } }@else {
  {{ 'AlarmCenterNoAlarms' | translate }}
  } }
</div>
