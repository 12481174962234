import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { CwButton, CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { Sidebar, SidebarService } from '~/app/services/sidebar.service';

@Component({
  selector: 'gplus-sidebar-container',
  standalone: true,
  imports: [TranslateModule, CommonModule, CwButton, CwIcon],
  templateUrl: './sidebar-container.component.html',
  styleUrl: './sidebar-container.component.scss',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ transform: `translate(100%, 0)` }),
        animate('400ms ease-in-out', style({ transform: `translate(0, 0)` }))
      ]),
      transition(':leave', [
        style({ transform: `translate(0, 0)` }),
        animate('300ms', style({ transform: `translate(100%, 0)` }))
      ])
    ])
  ]
})
export class SidebarContainerComponent implements AfterViewInit {
  @Input() sidebar: Sidebar;

  constructor(private readonly sidebarService: SidebarService) {}

  ngAfterViewInit(): void {}

  public close() {
    this.sidebarService.close();
  }
}
