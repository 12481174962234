import { iif, lastValueFrom, of, switchMap, take, tap } from 'rxjs';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { TherapyJobExtended } from '../models/TherapyJob';
import { DialogIds } from '../services/dialog.service';

export class TherapyJobActions {
  public static delete(
    therapyJobId: string,
    services: { dialogService; jobsStore; notificationStore; therapyJobService; router? }
  ): Promise<void> {
    services.dialogService.open(DialogIds.DELETE_TJ);
    return lastValueFrom(
      services.dialogService.dialog().confirm.pipe(
        take(1),
        tap(() => {
          services.dialogService.close();
        }),
        switchMap((value: boolean) => {
          return iif(
            () => value,
            // If the user confirms
            services.therapyJobService.delete(therapyJobId).pipe(
              tap((res) => {
                if ((res as { error: string })?.error) {
                  // on error the therapyJobService displays a message, here we skip
                  return;
                }
                console.info('Therapy Job successfully deleted');
                services.jobsStore.deleteJob(therapyJobId);
                if (services?.router) {
                  services.router.navigate(['/home']);
                }
              })
            ),
            // if the user cancels
            of(null)
          );
        })
      )
    );
  }

  public static unpair(
    therapyJobId: string,
    services: { jobsStore; notificationStore; monitorDeviceService }
  ): Promise<void> {
    // On error the monitorDeviceService displays a message an returns an observable

    // For monitor device methods, we expect to have an event coming from the web socket
    // we do not update the store here, we wait for the event to do the update
    return lastValueFrom(services.monitorDeviceService.unpair(therapyJobId));
  }

  public static finalize(
    therapyJob: TherapyJobExtended,
    services: { dialogService; jobsStore; notificationStore; therapyJobService }
  ): Promise<void> {
    if (!therapyJob) {
      console.warn('TherapyJob is not set');
      return Promise.reject();
    }

    const finalizeObs = services.therapyJobService.finalize(therapyJob.id).pipe(
      tap((res) => {
        if ((res as { error: string })?.error) {
          return;
        }
        console.info('Therapy Job successfully finalized');
        // Patch the status!
        services.jobsStore.patchJob({ status: TherapyJobStatus.IN_HISTORY }, therapyJob.id);
        services.notificationStore.setAll();
      })
    );

    // If the therapy job has not been aborted, we finalize without any confirmation
    if (therapyJob.status !== TherapyJobStatus.ABORTED) {
      return lastValueFrom(finalizeObs);
    }

    services.dialogService.open(DialogIds.FINALIZE, therapyJob);
    return lastValueFrom(
      services.dialogService.dialog().confirm.pipe(
        take(1),
        tap(() => {
          services.dialogService.close();
        }),
        switchMap((value: boolean) => {
          return iif(
            () => value,
            // If the user confirms
            finalizeObs,
            // if the user cancels
            of(null)
          );
        })
      )
    );
  }

  public static abort(
    therapyJobId: string,
    services: { jobsStore; notificationStore; therapyJobService }
  ): Promise<void> {
    return lastValueFrom(
      services.therapyJobService.abort(therapyJobId).pipe(
        tap((res) => {
          if ((res as { error: string })?.error) {
            // On error the therapyJobService displays a message an returns an observable

            // If something went wrong we reload, in case the BE changed the status in the DB
            services.jobsStore.loadAll().then(() => services.notificationStore.setAll());
          } else {
            services.jobsStore.patchJob({ status: TherapyJobStatus.ABORTED }, therapyJobId);
            services.notificationStore.setAll();
            console.info('Therapy Job successfully aborted');
          }
        })
      )
    );
  }
}
