import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[customShadowCss]'
})
export class CustomShadowCssDirective implements OnInit, AfterViewInit {
  @Input()
  styles: string;

  @Input()
  deepShadow: {
    selector: 'string';
    styles: string;
  };

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(this.styles);
    this.el.nativeElement.shadowRoot.adoptedStyleSheets.push(sheet);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.deepShadow) {
        const elm = this.el.nativeElement.shadowRoot.querySelector(this.deepShadow.selector);
        if (elm) {
          const deepShadowSheet = new CSSStyleSheet();
          deepShadowSheet.replaceSync(this.deepShadow.styles);
          elm.shadowRoot.adoptedStyleSheets.push(deepShadowSheet);
        }
      }
    }, 100);
  }
}
