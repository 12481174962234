import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CwButton, CwTooltip } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gplus-detail-page-form-actions',
  standalone: true,
  imports: [CwButton, TranslateModule, CommonModule, CwTooltip],
  templateUrl: './detail-page-form-actions.component.html',
  styleUrl: './detail-page-form-actions.component.scss'
})
export class DetailPageFormActionsComponent {
  @Input() disableSubmitTooltip: string;
  @Input() showSubmitButtonDisabled: boolean;
  @Input() showResetButtonDisabled: boolean;

  @Output() submitChange: EventEmitter<void> = new EventEmitter();
  @Output() resetChange: EventEmitter<void> = new EventEmitter();
}
