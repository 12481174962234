import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum TimeUnit {
  MILLISECONDS = 'milliseconds',
  MINUTES = 'minutes'
}
@Pipe({
  standalone: true,
  name: 'durationFormat'
})
/**
 * Returns the duration provided in minutes formatted with the appropriate unit days/hrs/minutes
 *
 */
export class DurationFormatPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(duration: number, unit: TimeUnit): string {
    switch (unit) {
      case TimeUnit.MILLISECONDS:
        return this.transformFromMilliseconds(duration);

      case TimeUnit.MINUTES:
        return this.transformFromMinutes(duration);
    }
  }

  private transformFromMinutes(duration: number): string {
    const days = Math.floor(duration / 60 / 24);
    const hours = Math.floor(duration / 60);
    const minutes = Math.floor(duration);

    if (days > 0) {
      return `${days} ${this.translateService.instant('UnitDayShort')}`;
    } else if (hours > 0) {
      const remainingMins = minutes - hours * 60;
      if (remainingMins) {
        return `${hours} ${this.translateService.instant(
          'UnitHourShort'
        )} ${remainingMins} ${this.translateService.instant('UnitMinShort')}`;
      }

      return `${hours} ${this.translateService.instant('UnitHourShort')}`;
    } else if (minutes > 0) {
      return `${minutes} ${this.translateService.instant('UnitMinShort')}`;
    }

    return null;
  }

  private transformFromMilliseconds(duration: number): string {
    const days = Math.floor(duration / 1000 / 60 / 60 / 24);
    const hours = Math.floor(duration / 1000 / 60 / 60);
    const minutes = Math.floor(duration / 1000 / 60);

    if (days > 0) {
      return `${days} ${this.translateService.instant('UnitDayShort')}`;
    } else if (hours > 0) {
      return `${hours} ${this.translateService.instant('UnitHourShort')}`;
    } else if (minutes > 0) {
      return `${minutes} ${this.translateService.instant('UnitMinShort')}`;
    }

    return null;
  }
}
