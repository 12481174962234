import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'isToday'
})
export class IsTodayPipe implements PipeTransform {
  transform(date: number | string | Date): boolean {
    if (!date) {
      return false;
    }

    if (date instanceof Date) {
      return this.isToday(date);
    }

    return this.isToday(new Date(date));
  }

  private isToday(date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }
}
