import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CwAvatar, CwIcon, CwMenu, CwMenuItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { User } from '~/app/models/User';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { AuthService } from '~/app/services/auth.service';
import { UserService } from '~/app/services/user.service';

@Component({
  selector: 'gplus-user',
  standalone: true,
  imports: [CommonModule, CwAvatar, CwMenu, CwMenuItem, CwIcon, TranslateModule, PersonNamePipe],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent {
  public profile: User;
  public image;

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer
  ) {
    this.userService
      .getMe()
      .pipe(
        take(1),
        switchMap((me) => {
          this.profile = me;
          return this.getImage(this.profile.avatarUrl);
        })
      )
      .subscribe((image) => {
        this.image = image;
      });
  }

  private getImage(url) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
      catchError(() => {
        return of(null);
      }),
      map((response) => {
        if (!response) {
          return null;
        }
        const objectURL = URL.createObjectURL(response.body);
        const image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return image;
      })
    );
  }

  public logout() {
    this.authService.logout();
  }
}
