import { Directive, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessor } from './ValueAccessor';

@Directive({
  selector: 'cw-input',
  host: {
    '(valueChange)': 'handleChangeEvent($event.detail)'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputValueAccessor,
      multi: true
    }
  ],
  standalone: true
})
export class InputValueAccessor extends ValueAccessor {
  constructor(el: ElementRef) {
    super(el);
  }
}
