@if(multiple){
<!-- In CwDropdown the property "multiple" cannot be set programmatically -->
<cw-dropdown
  #dropdown
  customShadowCss
  [attr.data-vef]="attrVef"
  [styles]="shadowStyles"
  [deepShadow]="deepShadow"
  [value]="inputTextValue"
  [placeholder]="placeholder"
  [label]="label"
  [assistiveText]="error"
  [required]="required"
  [readonly]="readonly"
  [disabled]="disabled"
  (valueChanges)="selected($event.detail)"
  multiple="true"
>
  <ng-container *ngTemplateOutlet="options"></ng-container>
</cw-dropdown>
} @else {

<cw-dropdown
  #dropdown
  customShadowCss
  [attr.data-vef]="attrVef"
  [styles]="shadowStyles"
  [deepShadow]="deepShadow"
  [value]="inputTextValue"
  [placeholder]="placeholder"
  [label]="label"
  [assistiveText]="error"
  [required]="required"
  [readonly]="readonly"
  [disabled]="disabled"
  (valueChanges)="selected($event.detail)"
>
  <ng-container *ngTemplateOutlet="options"></ng-container>
</cw-dropdown>
}

<ng-template #options>
  @if (loading) {
  <cw-dropdown-item [disabled]="true" [value]="null" [label]="'Loading' | translate"> </cw-dropdown-item>
  } @else { @for(item of data; track item){
  <cw-dropdown-item
    [disabled]="item.disabled"
    [attr.data-vef]="item.attrVef"
    [value]="item"
    [label]="item | printLabel : labelType"
  >
  </cw-dropdown-item>

  } }
</ng-template>
