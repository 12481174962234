import { TherapyJobStatus } from '../enums/TherapyJobStatus';

export const DEFAULT_COLUMNS = [
  [TherapyJobStatus.PRESCRIBED],
  [TherapyJobStatus.RUNNING],
  [
    TherapyJobStatus.COMPLETED,
    TherapyJobStatus.COMPLETE_CONFIRMED,
    TherapyJobStatus.ABORTED,
    TherapyJobStatus.ABORTION_TRIGGERED
  ]
];
