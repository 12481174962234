import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { CwBadgeStatus } from '@bbraun/cortex-angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TherapyJobFormClass } from '~/app/classes/TherapyJobFormClass';
import { DateFormat } from '~/app/enums/DateFormat';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { SsePairing } from '~/app/models/sse-event';
import { TherapyJobExtended } from '~/app/models/TherapyJob';
import { DurationFormatPipe } from '~/app/pipes/DurationFormat';
import { IsTodayPipe } from '~/app/pipes/IsToday';
import { LocalizedDatePipe } from '~/app/pipes/LocalizedDate';
import { ConfigService } from '~/app/services/config.service';
import { setTimeError } from '~/app/utils/DelayTime';
import { ManageDeviceComponent } from '../../manage-device/manage-device.component';
import { ToggleComponent } from '../../toggle/toggle.component';

@Component({
  selector: 'gplus-detail-page-details',
  standalone: true,
  imports: [
    TranslateModule,
    LocalizedDatePipe,
    IsTodayPipe,
    ManageDeviceComponent,
    CommonModule,
    ToggleComponent,
    ManageDeviceComponent,
    CwBadgeStatus
  ],
  templateUrl: './detail-page-details.component.html',
  styleUrl: './detail-page-details.component.scss'
})
export class DetailPageDetailsComponent {
  @Input() therapyJob: TherapyJobExtended;
  @Input() formInstance: TherapyJobFormClass;
  @Input() event: SsePairing;
  @Input() ticker: number;

  public therapyJobStatus = TherapyJobStatus;
  public pairingStatus = PairingStatus;
  public timeError: string;
  public dateFormat = DateFormat;

  constructor(
    public readonly configService: ConfigService,
    private readonly translatePipe: TranslatePipe,
    private readonly durationPipe: DurationFormatPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticker) {
      this.setTimeError(this.therapyJob);
    }
  }

  private setTimeError(therapyJob: TherapyJobExtended): void {
    if (!therapyJob || therapyJob.status !== TherapyJobStatus.PRESCRIBED) {
      return;
    }
    this.timeError = setTimeError(therapyJob.startTime.planned, this.durationPipe, this.translatePipe);
  }
}
