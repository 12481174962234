import { Component, HostBinding } from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { CxIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { slideIn, slideOut } from '~/app/animations/enterLeave';

@Component({
  selector: 'gplus-mobile-drawer',
  standalone: true,
  imports: [SearchBarComponent, CxIcon, TranslateModule, CommonModule, RouterModule],
  templateUrl: './mobile-drawer.component.html',
  styleUrl: './mobile-drawer.component.scss',
  animations: [slideIn, slideOut]
})
export class MobileDrawerComponent {
  @HostBinding('@slideIn') slideIn = true;
  @HostBinding('@slideOut') slideOut = true;
}
