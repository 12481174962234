import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { CxNavigationRail, CxNavigationRailItem } from '@bbraun/cortex-angular';
import { CxHeartBeatModule } from '@bbraun/cortex/heart-beat';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, take } from 'rxjs';
import { HeaderComponent } from './components/header/header.component';
import { MobileDrawerComponent } from './components/mobile-drawer/mobile-drawer.component';
import { ModalContainerComponent } from './components/modals/modal-container/modal-container.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { SidebarContainerComponent } from './components/sidebar-container/sidebar-container.component';
import { AuthService } from './services/auth.service';
import { DialogService } from './services/dialog.service';
import { EventsService } from './services/events.service';
import { HeaderService } from './services/header.service';
import { OverlayService } from './services/overlay.service';
import { SidebarService } from './services/sidebar.service';
import { StatusService } from './services/status.service';
import { TherapyJobStore } from './store/job.store';
import { NotificationsStore } from './store/notification.store';

@Component({
  selector: 'gplus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    TranslateModule,
    CxNavigationRail,
    CxNavigationRailItem,
    ModalContainerComponent,
    CxHeartBeatModule,
    HeaderComponent,
    OverlayComponent,
    MobileDrawerComponent,
    SidebarContainerComponent
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public loginDisplay: boolean;

  public jobsStore = inject(TherapyJobStore);
  public notificationsStore = inject(NotificationsStore);

  @HostListener('window:resize')
  onResize() {
    this.headerService.setMobileMenuBtn(false);
  }

  @HostListener('window:beforeunload')
  beforeUnload() {
    console.info('Before destroy, closing SSE connection');
    this.eventsService.close();
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    console.info('Inactive TAB?', document.hidden, ' Closed connection?', this.eventsService.sseObs$.closed);
  }

  constructor(
    private readonly statusService: StatusService,
    public readonly dialogService: DialogService,
    private readonly authService: AuthService,
    public readonly overlayService: OverlayService,
    public readonly headerService: HeaderService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    public readonly sidebarService: SidebarService,
    private readonly eventsService: EventsService
  ) {
    this.translateService.setDefaultLang('en');

    const browserLanguage = this.translateService.getBrowserLang();
    if (browserLanguage && browserLanguage !== 'en') {
      this.translateService
        .use(browserLanguage)
        .pipe(take(1))
        .subscribe(() => {
          console.info('Setting language to:', browserLanguage);
        });
    }

    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.headerService.setMobileMenuBtn(false);
      }
    });
  }

  ngOnInit(): void {
    this.authService.configureAuth();
    this.authService.isLogged.pipe(debounceTime(300)).subscribe((isLogged) => {
      this.loginDisplay = isLogged;

      if (isLogged) {
        this.loadEntities();
      }
    });
  }

  ngAfterViewInit(): void {
    document.body.append(document.createElement('cx-snackbar'));

    this.iniDialog();
  }

  ngOnDestroy(): void {
    this.statusService.destroyPolling();
  }

  private loadEntities() {
    this.jobsStore.loadAll().then(() => {
      console.log('Therapy Jobs Loaded');
      // we update notifications
      this.notificationsStore.setAll();
    });
  }

  private iniDialog() {
    const dialog = document.querySelector('cx-dialog');

    if (!dialog) {
      return;
    }
    dialog.addEventListener('dialogClosed', () => {
      this.dialogService.close();
    });
  }

  public linkClicked() {
    this.headerService.setMobileMenuBtn(false);
  }
}
