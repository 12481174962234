import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { EntityMap } from '@ngrx/signals/entities';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { SsePairing } from '~/app/models/sse-event';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { FilterNotificationPipe } from '~/app/pipes/FilterNotifications';
import { TJHighLightMap } from '~/app/services/highlight.service';
import { OverlayService } from '~/app/services/overlay.service';
import { Column } from '../../models/ui/Column';
import { TherapyJobComponent } from '../therapy-job/therapy-job.component';

@Component({
  selector: 'gplus-board-column',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    TranslateModule,
    // Components
    TherapyJobComponent,
    FilterNotificationPipe
  ],
  templateUrl: './board-column.component.html',
  styleUrl: './board-column.component.scss'
})
export class BoardColumnComponent implements AfterViewInit, OnDestroy {
  @Input() column: Column;
  @Input() tJNotificationsMap: EntityMap<TherapyJobNotification>;
  @Input() tjHighlightMap: TJHighLightMap;
  @Input() ticker: number;
  @Input() highlightTerm: string;
  @Input() event: SsePairing;

  @ViewChild('wrapper') wrapper: ElementRef;
  private destroy: Subject<void> = new Subject();

  @Output() onChange: EventEmitter<{ value: Partial<TherapyJob>; id: string }> = new EventEmitter();

  constructor(private readonly overlayService: OverlayService) {}
  ngAfterViewInit(): void {
    fromEvent(this.wrapper.nativeElement, 'scroll')
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.overlayService.close();
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onTherapyJobChange(change: { value: Partial<TherapyJob>; id: string }) {
    this.onChange.emit(change);
  }

  public tJTrackBy(index, therapyJob) {
    return therapyJob?.id;
  }
}
