import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CwButton } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { ABORT_REASON_MAX_LENGTH } from '~/app/classes/TherapyJobFormClass';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { Dialog, DialogService } from '~/app/services/dialog.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { NotificationsStore } from '~/app/store/notification.store';
import { TextareaComponent } from '../textarea/textarea.component';

@Component({
  selector: 'gplus-finalize-modal',
  standalone: true,
  imports: [TranslateModule, CwButton, TextareaComponent, CommonModule, PersonNamePipe, ReactiveFormsModule],
  templateUrl: './finalize-modal.component.html',
  styleUrl: './finalize-modal.component.scss'
})
export class FinalizeModalComponent implements OnInit {
  @Input() dialog: Dialog;
  public jobsStore = inject(TherapyJobStore);

  public form: FormGroup;
  public annotationMaxLength = ABORT_REASON_MAX_LENGTH;

  constructor(
    private readonly dialogService: DialogService,
    private readonly therapyJobService: TherapyJobService,
    private readonly notificationStore: NotificationsStore,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      annotations: new FormControl(null, [Validators.required, Validators.maxLength(ABORT_REASON_MAX_LENGTH)])
    });
  }

  public closeDialog() {
    this.dialog.confirm.next(false);
    this.dialogService.close();
  }

  public submitChange() {
    if (this.form.dirty && this.form.valid) {
      const previousAnnotations = this.dialog.data.annotations;
      const newAnnotations = this.form.get('annotations').value;

      const annotationsMerged = `${previousAnnotations} - ${this.translateService.instant(
        'TherapyJobFinishAbortedLabelInput'
      )}: ${newAnnotations}`;

      this.jobsStore.patchJob({ annotations: annotationsMerged }, this.dialog.data.id);

      this.therapyJobService
        .update({ annotations: annotationsMerged }, this.dialog.data.id)
        .pipe(take(1))
        .subscribe((res) => {
          // we close not matter what
          if ((res as { error: string })?.error) {
            // something went wrong we reload
            this.jobsStore.loadAll().then(() => this.notificationStore.setAll());
            this.dialog.confirm.next(false);
          } else {
            console.info('Therapy Job successfully edited');
            this.dialog.confirm.next(true);
          }
        });
    } else {
      this.dialog.confirm.next(true);
    }
  }
}
